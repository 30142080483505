import type { Errors, Touched } from "./inputs/utils";
import { useDebounce, useHasFormErrors } from "../../../app/hooks";
import { motion } from "framer-motion";

interface Props {
	footerError?: string;
	errors: Errors;
	touched: Touched;
}

const FooterError = ({ footerError, errors, touched }: Props) => {
	const debouncedErrors = useDebounce(errors, 500);
	const debouncedTouched = useDebounce(touched, 500);
	const hasFormErrors = useHasFormErrors({
		errors: debouncedErrors,
		touched: debouncedTouched,
	});

	return (
		<motion.p
			className={`text-error fs-13 ${(footerError && footerError.length > 0) || hasFormErrors ? "pb-2" : null}`}
		>
			{footerError
				? footerError
				: hasFormErrors
					? "Looks like there are some validation errors in your form"
					: ""}
		</motion.p>
	);
};

export default FooterError;
