import { useDispatch } from "react-redux";
import { useAppSelector, useScrollToElement } from "../../../app/hooks";
import CommentBlock from "./CommentsBlock";
import useComments from "./useComments";
import { selectActiveThread, setActiveThread } from "../../../app/features/builder/builderSlice";

const CommentContent = ({
	threadId,
	groupLabel,
	isActive
}: { threadId: number; groupLabel: string, isActive: boolean }) => {
	const activeThread = useAppSelector(selectActiveThread)
	const dispatch = useDispatch()

	const {
		thread,
		isCommentActive,
		setIsCommentActive,
		handleCreateComment,
		handleCreateReply,
		handleDeleteComment
	} = useComments({ threadId });

	const { scrollToElementById } = useScrollToElement()

	if (!thread?.hasComments()) {
		return null
	}

	const handleThreadClick = () => {
		if (activeThread !== threadId) {
			dispatch(setActiveThread(threadId))
			scrollToElementById(threadId.toString())
		}
	}

	return (
		//TODO: clean css here when isActive is true and remove comments when we decide the final design for comments
		<button
			type="button"
			className="comments-action-container w-100"
			onClick={handleThreadClick}
		>
			{/* <div className="d-flex f-column">
				<CommentButton
					label="Add Comment"
					clickHandler={() =>
						!isCommentActive && setIsCommentActive(!isCommentActive)
					}
				/>
			</div> */}
			{/* <CommentTextEditorContainer
					isCommentActive={isCommentActive}
					cancelCallback={() => setIsCommentActive(!isCommentActive)}
					okCallback={handleCreateComment}
				/> */}
			<div className={`px-1 w-100 ${isActive ? "bg-light-gray" : "bg-gray"}`}>
				{thread?.comments?.map((comment) => {
					if (!comment.parent) {
						return (
							<CommentBlock
								comment={comment}
								key={comment.id}
								deleteHandler={handleDeleteComment}
								createHandler={handleCreateReply}
								id={`side-${threadId}`}
							/>
						);
					}
				})}
			</div>
		</button>
	);
};

export default CommentContent;
