import { useMemo } from "react";

const MapIframe = () => {
  const isProd = process.env.REACT_ENV === 'PROD';
  //const url = useMemo(() => true ? "https://data.apps.fao.org/ex-act/?lang=en" : "https://data.review.fao.org/ex-act/?lang=en", [isProd]);
  const url = "https://data.apps.fao.org/ex-act/?lang=en";

  return (
    <iframe
      src={url}
      style={{
        width: '700px',
        height: '700px',
        border: 'none',
      }}
      allowFullScreen
    />
  );
}

export default MapIframe;