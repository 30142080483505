import {
	filterObjectProps,
	convertEmptyStringsToNull
} from "../../../../pages/activityBuilder/modules/inputs/utils";
import { includedPropList } from "../../../../pages/activityBuilder/modules/landUseChanges/landUseChange";
import type {
	LandUseChangeResponse,
	ModuleFull,
} from "../../../../pages/activityBuilder/modules/landUseChanges/landUseChangeTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getLandUseChanges: builder.query<ModuleFull | null, number>({
			query: (activityId) => `land-use-changes/?activity=${activityId}`,
			transformResponse: (response: LandUseChangeResponse[]) => {
				if (response && response.length > 0) {
					return {
						module: filterObjectProps(
							response[0],
							includedPropList,
						) as LandUseChangeResponse,
					};
				}
				return null;
			},
			providesTags: (results, error, activityId) => [
				{ type: "LandUseChange", id: activityId },
			],
		}),
		updateLandUseChange: builder.mutation<
			LandUseChangeResponse,
			{
				data: ModuleFull;
				activityId: number;
			}
		>({
			query: ({ data, activityId }) => {
				const { id, ...body } = data.module;
				if (Object.keys(body).includes("status"))
					console.error("Attention! you're passing status to luc");

				const payload = {
					...body,
					activity: activityId,
				};

				convertEmptyStringsToNull(payload);
				return {
					url: `land-use-changes/${id}/`,
					method: "PUT",
					body: payload,
				};
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "LandUseChange", id: results?.activity?.id ?? 0 },
				{ type: "ModulesStatuses", id: results?.activity?.id ?? 0 },
				{ type: "ModuleHistory", id: results?.id ? `land-use-changes-${results?.id}` : undefined },
			],
		}),
	}),
});

export const { useGetLandUseChangesQuery, useUpdateLandUseChangeMutation } =
	moduleApiSlice;
