import type {
	Submodule,
	StartWithWithoutGeneric,
	DropdownInputGroup,
	StartWithWithoutTierTwo,
	ModuleSchema,
	T2Schema,
	Option,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import { validatePercentage, validatePositiveNumber } from "../moduleUtils";
import * as Yup from "yup";
import type {
	SmallFisheriesPayload,
	T2FishieriesScalePayload,
} from "./smallFisheriesTypes";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { translate } from "../../../../utils/helperFunctions";

export const includedPropList = [
	"fishery_type",
	"gear_type_start",
	"gear_type_w",
	"gear_type_wo",
	"total_catch_yr_start",
	"total_catch_yr_w",
	"total_catch_yr_wo",
	"refrigerant_pc_start",
	"refrigerant_pc_w",
	"refrigerant_pc_wo",
	"ice_preserved_catch_pc_start",
	"ice_preserved_catch_pc_w",
	"ice_preserved_catch_pc_wo",
	"fui_t2_start",
	"fui_t2_w",
	"fui_t2_wo",
	"activity",
	"id",
	"note",
];

export const includedT2PropList = [
	"energy_emission_factor_t2_start",
	"energy_emission_factor_t2_w",
	"energy_emission_factor_t2_wo",
	"tonnes_of_ice_t2_start",
	"tonnes_of_ice_t2_w",
	"tonnes_of_ice_t2_wo",
	"inshore_ice_production_kwh_per_tonne_t2_start",
	"inshore_ice_production_kwh_per_tonne_t2_w",
	"inshore_ice_production_kwh_per_tonne_t2_wo",
	"refrigerant_lost_per_tonne_t2_start",
	"refrigerant_lost_per_tonne_t2_w",
	"refrigerant_lost_per_tonne_t2_wo",
	"refrigerant_gwp_t2_start",
	"refrigerant_gwp_t2_w",
	"refrigerant_gwp_t2_wo",
];

export const validationSchema = Yup.object({
	module: Yup.object().shape({
		refrigerant_pc_start: validatePercentage,
		refrigerant_pc_w: validatePercentage,
		refrigerant_pc_wo: validatePercentage,
		ice_preserved_catch_pc_start: validatePercentage,
		ice_preserved_catch_pc_w: validatePercentage,
		ice_preserved_catch_pc_wo: validatePercentage,
		fishery_type: validatePositiveNumber.required('validations.fishery_type_required'),
		gear_type_start: validatePositiveNumber.required('validations.gear_type_required'),
		gear_type_w: validatePositiveNumber.required('validations.gear_type_required'),
		gear_type_wo: validatePositiveNumber.required('validations.gear_type_required'),
		total_catch_yr_start: validatePositiveNumber.required('validations.total_catch_required'),
		total_catch_yr_w: validatePositiveNumber.required('validations.total_catch_required'),
		total_catch_yr_wo: validatePositiveNumber.required('validations.total_catch_required')
	}),
});

export interface DropdownOptionLists {
	fishieriesTypes: Option[];
	fishGearCategories: Option[];
}

export const initialValues: SmallFisheriesPayload = {
	fishery_type: undefined,
	gear_type_start: undefined,
	gear_type_w: undefined,
	gear_type_wo: undefined,
	total_catch_yr_start: undefined,
	total_catch_yr_w: undefined,
	total_catch_yr_wo: undefined,
	refrigerant_pc_start: undefined,
	refrigerant_pc_w: undefined,
	refrigerant_pc_wo: undefined,
	ice_preserved_catch_pc_start: undefined,
	ice_preserved_catch_pc_w: undefined,
	ice_preserved_catch_pc_wo: undefined,
	fui_t2_start: null,
	fui_t2_w: null,
	fui_t2_wo: null,
	id: undefined,
	activity: undefined,
	note: null
};

const fishieriesTypes = () => getOptions("fishery");
const smallFishGearCategories = () => getOptions("smallFishery");

const getMandatorySubmodules: () => Submodule[] = () => {
	const smallTypeOfFisheries: DropdownInputGroup = {
		type: FieldType.SELECT,
		inputName: "module.fishery_type",
		label: "module.type_of_fishery_required",
		dropdownOptions: fishieriesTypes,
	};
	const smallGearCategory: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		label: "module.gear_category_required",
		inputName: "module.gear_type",
		startProps: { dropdownOptions: smallFishGearCategories },
		withProps: { dropdownOptions: smallFishGearCategories },
		withoutProps: { dropdownOptions: smallFishGearCategories },
	};
	const smallTotalCatch: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "module.total_catch_yr",
		label: "module.total_catch_required",
		unit: "[t/yr]",
	};
	const smallRefrigerantSystem: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "module.refrigerant_pc",
		label: "module.refrigerant_system_percentage",
		startProps: { inputType: "percentage" },
		withProps: { inputType: "percentage" },
		withoutProps: { inputType: "percentage" },
	};
	const smallIceProduced: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "module.ice_preserved_catch_pc",
		label: "module.total_catch_preserved_with_ice_percentage",
		startProps: { inputType: "percentage" },
		withProps: { inputType: "percentage" },
		withoutProps: { inputType: "percentage" },
	};
	const smallFuelUseIntencity: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		inputName: "module.fui_t2",
		label: "module.fuel_use_intensity",
		unit: "[l/t]",
	};

	return [
		{
			name: "module.small_scale_fisheries",
			inputGroups: [
				smallTypeOfFisheries,
				smallGearCategory,
				smallTotalCatch,
				smallRefrigerantSystem,
				smallIceProduced,
				smallFuelUseIntencity,
			],
		},
	];
};

/* T2 */
export const t2InitialValues: T2FishieriesScalePayload = {
	energy_emission_factor_t2_start: null,
	energy_emission_factor_t2_w: null,
	energy_emission_factor_t2_wo: null,
	tonnes_of_ice_t2_start: null,
	tonnes_of_ice_t2_w: null,
	tonnes_of_ice_t2_wo: null,
	inshore_ice_production_kwh_per_tonne_t2_start: null,
	inshore_ice_production_kwh_per_tonne_t2_w: null,
	inshore_ice_production_kwh_per_tonne_t2_wo: null,
	refrigerant_lost_per_tonne_t2_start: null,
	refrigerant_lost_per_tonne_t2_w: null,
	refrigerant_lost_per_tonne_t2_wo: null,
	refrigerant_gwp_t2_start: null,
	refrigerant_gwp_t2_w: null,
	refrigerant_gwp_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
	const smallScaleRows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.energy_emission_factor_t2",
			label: "module.ef_of_fuel_used",
			unit: "[tCO2-e/m3]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.refrigerant_lost_per_tonne_t2",
			label: "module.refrigerant_lost",
			unit: "[kg/t of catch]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.refrigerant_gwp_t2",
			label: "module.gwp_of_refrigerant",
			unit: "[GWP]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.tonnes_of_ice_t2",
			label: "module.quantity_of_ice",
			unit: "[t/t of catch]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.inshore_ice_production_kwh_per_tonne_t2",
			label: "module.electricity_used_for_ice_production",
			unit: "[kwh/t of Ice]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
	];

	return [
		{
			name: "module.small_scale_fisheries",
			inputGroups: [...smallScaleRows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: [],
		mandatorySubmodules: getMandatorySubmodules(),
		optionalSubmodules: [],
	};

	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
