import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import type { AuthState } from "./authTypes";
import type { User } from "../../../types/interfaces";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AuthState = {
	accessToken: null,
	refreshToken: null,
	user: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		setCredentials: (state, action: PayloadAction<Partial<AuthState>>) => {
			const { user, accessToken, refreshToken } = action.payload;
			if(user) state.user = user;
			if(accessToken) state.accessToken = accessToken;
			if(refreshToken) state.refreshToken = refreshToken;
		},
		logout: (state) => {
			state.user = null;
			state.accessToken = null;
			state.refreshToken = null;
		},
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload;
		},
	},
});

export const { setCredentials, logout, setUser } = authSlice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentToken = (state: RootState) => state.auth.accessToken;

export default authSlice.reducer;
