import { includedPropList, includedT2PropList } from "../../../../pages/activityBuilder/modules/settlements/settlements";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import type { BuildingPayload, BuildingResponse, ModuleFull, OtherCreatePayload, OtherDeletePayload, OtherPayload, OtherResponse, RoadCreatePayload, RoadDeletePayload, RoadPayload, RoadResponse, SettlementsDefaults, SettlementsPayload, SettlementsResponse, SettlementsT2 } from "../../../../pages/activityBuilder/modules/settlements/settlementsTypes";
import { apiSlice } from "../apiSlice";



const settlementsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSettlements: builder.query<ModuleFull, { activityId: number }>({
            query: ({ activityId }) => `settlements/?activity=${activityId}`,
            transformResponse: (response: SettlementsResponse[]): ModuleFull => {
                const module = filterObjectProps(response[0], includedPropList) as SettlementsPayload
                const t2 = filterObjectProps(response[0], includedT2PropList) as SettlementsT2
                return {
                    module,
                    tiertwo: t2,
                    roads: [],
                    buildings: [],
                    others: [],
                }

            },
            providesTags: (_result, _err, { activityId }) => [
                { type: 'Settlements', id: activityId }
            ]
        }),
        updateSettlements: builder.mutation<ModuleFull, { data: ModuleFull, activityId: number }>({
            query: ({ data, activityId }) => {
                const module = { ...data.module };
                const tiertwo = { ...data.tiertwo };
                const lucId = data.module.land_use_change?.id;

                const payload = { ...module, ...tiertwo } as unknown as SettlementsResponse
                const { id, ...rest } = payload
                const body = { ...rest, activity: activityId, land_use_change: lucId }
                convertEmptyStringsToNull(body)
                return {
                    url: `settlements/${id}/`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags: (_result, _err, { activityId, data }) => [
                { type: 'Settlements', id: activityId },
                { type: 'ModuleResults', id: data.module.id ? `settlements-${data.module.id}` : undefined },
                { type: 'ModuleResults', id: "land-use-changes" },
                { type: "ModuleHistory", id: data.module.id ? `settlements-${data.module.id}` : undefined },
                { type: "SettlementsDefaults", id: data.module.id ? data.module.id : undefined },
                { type: "ModulesStatuses", id: activityId }
            ]
        }),
        getSettlementsDefaults: builder.query<SettlementsDefaults, number>({
            query: (uniqueId) => `settlements/${uniqueId}/defaults/`,
            providesTags: (_result, _err, uniqueId) => [{ type: 'SettlementsDefaults', id: uniqueId }]
        }),
        getRoads: builder.query<RoadResponse[], { activityId: number }>({
            query: ({ activityId }) => `roads/?activity=${activityId}`,
            providesTags: (_result, _err, { activityId }) => [
                { type: 'Roads', id: activityId }
            ]
        }),
        createRoads: builder.mutation<RoadResponse, RoadCreatePayload>({
            query: ({ body }) => ({ url: "roads/", method: 'POST', body }),
            invalidatesTags: (_result, _err, data) => [
                { type: 'Roads', id: data.activityId },
                { type: 'ModuleResults', id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModuleHistory", id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
            ]
        }),
        updateRoads: builder.mutation<RoadResponse, RoadPayload>({
            query: ({ body }) => {
                const { id, ...rest } = body
                convertEmptyStringsToNull(rest)
                return { url: `roads/${id}/`, method: 'PUT', body: rest }
            },
            invalidatesTags: (_result, _err, data) => [
                { type: 'Roads', id: data.activityId },
                { type: 'ModuleResults', id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModuleHistory", id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModulesStatuses", id: data.activityId }
            ]
        }),
        deleteRoads: builder.mutation<RoadResponse, RoadDeletePayload>({
            query: ({ id }) => ({ url: `roads/${id}/`, method: 'DELETE' }),
            invalidatesTags: (_result, _err, data) => [
                { type: 'Roads', id: data.activityId },
                { type: 'ModuleResults', id: data.parent ? `settlements-${data.parent}` : undefined },
                { type: "ModuleHistory", id: data.parent ? `settlements-${data.parent}` : undefined },
            ]
        }),
        getBuildings: builder.query<BuildingResponse[], { activityId: number }>({
            query: ({ activityId }) => `buildings/?activity=${activityId}`,
            providesTags: (_result, _err, { activityId }) => [
                { type: 'Buildings', id: activityId }
            ]
        }),
        createBuildings: builder.mutation<BuildingResponse, RoadCreatePayload>({
            query: ({ body }) => ({ url: "buildings/", method: 'POST', body }),
            invalidatesTags: (_result, _err, data) => [
                { type: 'Buildings', id: data.activityId },
                { type: 'ModuleResults', id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModuleHistory", id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
            ]
        }),
        updateBuildings: builder.mutation<BuildingResponse, BuildingPayload>({
            query: ({ body }) => {
                const { id, ...rest } = body
                convertEmptyStringsToNull(rest)
                return { url: `buildings/${id}/`, method: 'PUT', body: rest }
            },
            invalidatesTags: (_result, _err, data) => [
                { type: 'Buildings', id: data.activityId },
                { type: 'ModuleResults', id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModuleHistory", id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModulesStatuses", id: data.activityId }
            ]
        }),
        deleteBuildings: builder.mutation<BuildingResponse, RoadDeletePayload>({
            query: ({ id }) => ({ url: `buildings/${id}/`, method: 'DELETE' }),
            invalidatesTags: (_result, _err, data) => [
                { type: 'Buildings', id: data.activityId },
                { type: 'ModuleResults', id: data.parent ? `settlements-${data.parent}` : undefined },
                { type: "ModuleHistory", id: data.parent ? `settlements-${data.parent}` : undefined },
            ]
        }),
        getOthers: builder.query<OtherResponse[], { activityId: number }>({
            query: ({ activityId }) => `other-infrastructures/?activity=${activityId}`,
            providesTags: (_result, _err, { activityId }) => [
                { type: 'Others', id: activityId }
            ]
        }),
        createOthers: builder.mutation<OtherResponse, OtherCreatePayload>({
            query: ({ body }) => ({ url: "other-infrastructures/", method: 'POST', body }),
            invalidatesTags: (_result, _err, data) => [
                { type: 'Others', id: data.activityId },
                { type: 'ModuleResults', id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModuleHistory", id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
            ]
        }),
        updateOthers: builder.mutation<OtherResponse, OtherPayload>({
            query: ({ body }) => {
                const { id, ...rest } = body
                convertEmptyStringsToNull(rest)
                return { url: `other-infrastructures/${id}/`, method: 'PUT', body: rest }
            },
            invalidatesTags: (_result, _err, data) => [
                { type: 'Others', id: data.activityId },
                { type: 'ModuleResults', id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
                { type: "ModuleHistory", id: data.body.parent ? `settlements-${data.body.parent}` : undefined },
            ]
        }),
        deleteOthers: builder.mutation<OtherResponse, OtherDeletePayload>({
            query: ({ id }) => ({ url: `other-infrastructures/${id}/`, method: 'DELETE' }),
            invalidatesTags: (_result, _err, data) => [
                { type: 'Others', id: data.activityId },
                { type: 'ModuleResults', id: data.parent ? `settlements-${data.parent}` : undefined },
                { type: "ModuleHistory", id: data.parent ? `settlements-${data.parent}` : undefined },
            ]
        })
    })
})

export const {
    useGetSettlementsQuery,
    useUpdateSettlementsMutation,
    useCreateRoadsMutation,
    useDeleteRoadsMutation,
    useGetRoadsQuery,
    useUpdateRoadsMutation,
    useCreateBuildingsMutation,
    useDeleteBuildingsMutation,
    useGetBuildingsQuery,
    useUpdateBuildingsMutation,
    useCreateOthersMutation,
    useDeleteOthersMutation,
    useGetOthersQuery,
    useUpdateOthersMutation,
    useGetSettlementsDefaultsQuery
} = settlementsApiSlice