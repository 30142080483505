import { ReactNode } from "react";
import { useCustomTranslation } from "../../app/hooks";
import { Note, TranslationKey } from "../../types/modulesInterfaces";
import FormSubmodule from "./FormSubmodule";


interface FormSubmoduleProps extends React.ComponentProps<typeof FormSubmodule> {
    submoduleName: TranslationKey | "";
    itemId?: number;
    itemIndex?: number;
  }

const withTranslation = <P extends FormSubmoduleProps>(
	FormSubmodule: React.ComponentType<P>
) => {
	return (props: Omit<P, "submoduleName"> & { submoduleName: TranslationKey | "" }) => {
    const translatedLabel = useCustomTranslation(props.submoduleName);
    if (props.itemId && props.itemIndex) {
      return <FormSubmodule {...(props as P)} submoduleName={`${props.itemIndex}. ${translatedLabel} id: ${props.itemId}`} />;
    }
		return <FormSubmodule {...(props as P)} submoduleName={translatedLabel} />;
	};
};

export const TranslatedFormSubmodule = withTranslation(FormSubmodule);