import TranslatableText from "../../components/translations/TranslatableText";

const DownloadExcels = () => {
  const files = [
    { name: 'EX-ACT v. 9.4.2', path: "https://storage.googleapis.com/fao-exact-review-fao-exact-frontend-static/EX-ACT_V9.4.2.xlsb", label: "EX-Ante Carbon balance Tool" },
    { name: 'EX-ACT VC v. 3.5', path: "https://storage.googleapis.com/fao-exact-review-fao-exact-frontend-static/EX-ACT%20VC_v3.5.xlsx", label: "EX-Ante Carbon balance Tool for Value Chains" },
    { name: 'B-INTACT v. 1.9', path: "https://storage.googleapis.com/fao-exact-review-fao-exact-frontend-static/B-INTACT_v.1.9.xlsx", label: "Biodiversity Integrated Assessment and Computation Tool" },
  ]

  return (
    <section className="px-2" >
      <div style={{ borderTop: "0.5px solid var(--gray)" }} className="py-2">
        <h3 className="welcome-user-header ff-medium-ext">
          <TranslatableText translationKey="main.downlad_excels" />
        </h3>
        <ul className="ps-2">
          {files.map((file, index) => (
            <li key={index} className="pt-1 fs-13">
              {file.label}: 
              <a href={file.path} download className="text-quat-de ps-1">
                {file.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default DownloadExcels;