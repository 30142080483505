import Spinner from "../../spinner/Spinner";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentCommentGroups, selectCurrentModuleT1Paylod, selectActiveThread, selectCurrentModuleThreads } from "../../../app/features/builder/builderSlice";
import CommentSection from "./CommentsSection";
import CommentContent from "./CommentsContent";
import { Comment } from "./CommentsBlock";
import { Fragment, ReactNode, useState } from "react";
import { CommentInputGroup, InputGroup } from "../../../types/modulesInterfaces";
import TranslatableText from "../../translations/TranslatableText";

export interface CommentsResponse {
  id: number;
  comments: Comment[];
}

export class Thread implements CommentsResponse {
  id: number;
  comments: Comment[];

  constructor(response: CommentsResponse) {
    this.id = response.id;
    this.comments = response.comments;
  }
  hasComments(): boolean {
    return this.comments.length > 0;
  }
}

const CommentsAction = () => {
  const commentGroups = useAppSelector(selectCurrentCommentGroups)
  const T1Data = useAppSelector(selectCurrentModuleT1Paylod)
  const activeThread = useAppSelector(selectActiveThread)
  const moduleThreads = useAppSelector(selectCurrentModuleThreads)
  if (moduleThreads) {
    return (
      <div>
        {
          moduleThreads.map((thread) => {
            return (
              <CommentContent key={thread} threadId={thread} groupLabel={''} isActive={activeThread === thread} />
            )
          })
        }
      </div>
    )
  }
  if (commentGroups && T1Data) {
    return (
      <>
        {
          //TODO: Remove implementation of comment groups when we get the endpoint for comments
          commentGroups.map((submodule, index) => {
            return (
              <Fragment key={`${submodule.name}-${index}`}>
                {
                  submodule.inputGroups?.map((item, index) => {
                    if (!item?.inputName) return null
                    const path = generateThreadFieldName(item);
                    let threadKey
                    let current: any = T1Data;
                    let id: number | null = null
                    if (path) {
                      for (const key of path) {
                        if (current === null || current === undefined) return null;
                        current = current[key];
                        if (current?.id) id = current?.id
                      }
                    }
                    threadKey = current;
                    if (!threadKey) return null;
                    return (
                      <CommentContent key={index} threadId={threadKey} groupLabel={item.label} isActive={activeThread === threadKey} />
                    )
                  })
                }
              </Fragment>
            )
          })
        }
      </>
    );
  }

  return (
    <section className="comments-action-container p-3">
      <TranslatableText translationKey="module.comments_not_available"/>
    </section>
  );
};

export const ClickableIcon = ({ children, clickHandler }: { children: ReactNode; clickHandler: () => void }) => {
  return (
    <div className="clickable" onClick={clickHandler}>
      {children}
    </div>
  );
};

function generateThreadFieldName(item: CommentInputGroup): string[] | null {
  const inputname = item.inputName
  if (!inputname || typeof inputname !== 'string') return null;
  const path = inputname.split('.');
  const threadKeyBase = path[path.length - 1];
  if (!threadKeyBase || typeof threadKeyBase !== 'string') return null;
  path[path.length - 1] = threadKeyBase + '_thread';
  return path;
}
export default CommentsAction;
