import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";

import {
	getSchemas,
	validationSchema,
	initialValues,
	t2InitialValues,
} from "./smallFisheries";
import type { ModuleFull } from "./smallFisheriesTypes";
import {
	useAppSelector,
	useSyncCommentGroupsWithStore,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetSmallFisheriesDefaultsQuery,
	useGetSmallFisheriesQuery,
	useUpdateSmallFisheriesMutation,
} from "../../../../app/features/api/modules/smallFisheryApiSlice";
import useModuleHook from "../useModuleHook";
import { BEModules } from "../../../../utils/moduleList";
import useErrorMessage from "../../../../utils/useErrorMessage";

const SmallFisheriesModule = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: smallFishData,
		isLoading,
		error,
		isError,
	} = useGetSmallFisheriesQuery(activeActivityId ?? 0, {
		skip: !activeActivityId,
		refetchOnMountOrArgChange: true,
	});

	const { data: defaults } = useGetSmallFisheriesDefaultsQuery(
		smallFishData?.module.id ?? 0,
		{ skip: !smallFishData?.module.id },
	);

	const [
		updateSmallFishery,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateSmallFisheriesMutation();

	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);

	//formik initial values
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (smallFishData)
			setInitValues({
				module: { ...smallFishData.module, ...defaults },
				tiertwo: { ...smallFishData.tiertwo, ...defaults },
			});
	}, [smallFishData, defaults]);

	useSyncCommentGroupsWithStore<ModuleFull>({
		T1Data: { module: smallFishData?.module ?? initValues.module },
		moduleSchema,
		isT1DataLoading: isLoading,
	});

	const { notes } = useSyncNotesWithStore({
		notes: smallFishData?.module.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module };
		const payload = {
			data: { ...values, module },
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updateSmallFishery(payload).unwrap());
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="Small Fisheries"
					moduleSchema={moduleSchema}
					t2Schema={t2Schema}
					beModuleType={BEModules.SmallFisheries}
					isLoading={isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="Small Fisheries"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default SmallFisheriesModule;
