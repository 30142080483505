import { useState, FC, useEffect } from "react";
import { FieldProps, FormikProps, getIn, useFormikContext } from "formik";

import toggleOff from "../../assets/icons/switch_false.svg";
import toggleOn from "../../assets/icons/switch_true.svg";
import Button from "../button/Button";
import { useDebounce } from "../../app/hooks";
import TranslatableText from "../translations/TranslatableText";

interface CustomToggleProps {
	label: string;
	id: string;
	value: boolean;
	classes?: string;
	handleClick?: () => void;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined,
	) => void;
	autosubmit?: boolean;
	disabled?: boolean;
}

const CustomToggle: FC<CustomToggleProps & FieldProps> = ({
	label,
	id,
	field,
	setFieldValue,
	autosubmit,
	disabled,
	form: { touched },
}) => {
	const wasTouched = getIn(touched, field.name);

	const { submitForm, setTouched } = useFormikContext();
	const [isOn, setIsOn] = useState<boolean>(field.value);
	const debouncedIsOn = useDebounce<boolean>(isOn, 500);

	useEffect(() => {
		if (autosubmit && wasTouched && submitForm) submitForm();
	}, [debouncedIsOn]);

	useEffect(() => {
		setIsOn(field.value);
	}, [field.value]);

	const handleClick = (e: React.MouseEvent) => {
		if (disabled) return;
		const current = typeof isOn === "boolean" ? isOn : false;
		setIsOn(() => !current);
		setFieldValue(id, !current);
		setTouched({ [field.name]: true });
		// field.onChange(e);
	};

	return (
		<section className="h-100 d-flex align-items-center">
			{label.length > 1 ? <span className="toggle-label">{label}</span> : null}
			<TranslatableText
				className={`fs-12 ${!isOn && "ff-bold"} px-1 ${disabled && "op-5"}`}
				translationKey="main.no"
			/>	
			<Button classes="pos-relative" onClick={handleClick} disabled={disabled}>
				<input
					type="checkbox"
					checked={field.value ?? false}
					className="hidden-plus h-100 w-100"
					aria-hidden="true"
					{...field}
				/>
				<img
					src={field.value === true ? toggleOn : toggleOff}
					alt="toggle"
					className={disabled ? "op-5" : ""}
				/>
			</Button>
			<TranslatableText className={`fs-12 ${isOn && "ff-bold"} ps-1 ${disabled && "op-5"}`} translationKey="main.yes"/>
		</section>
	);
};

export default CustomToggle;
