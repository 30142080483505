import { includedPropList, includedT2PropList } from "../../../../pages/activityBuilder/modules/inputs/inputs";
import type {
	InputType,
	InputEntryResponse,
	MacroInputType,
	InputEntryUpdatePayload,
	InputEntryCreatePayload,
	InputEntryDeletePayload,
	InputEntryData,
	InputsResponse,
	InputsUpdatePayload,
	InputsPayload,
	InputsT2Payload,
	InputEntryDefaults,
} from "../../../../pages/activityBuilder/modules/inputs/inputsTypes";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

/* const compare = (a: InputEntryResponse, b: InputEntryResponse) => {
	if (!a.input_type?.macro_input_type.name || !b.input_type?.macro_input_type.name) return 0;
	if (a.input_type?.macro_input_type.name < b.input_type?.macro_input_type.name) return -1;
	if (a.input_type?.macro_input_type.name > b.input_type?.macro_input_type.name) return 1;
	return 0;
} */

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getInputs: builder.query<{ parent: InputsPayload, tierTwo: InputsT2Payload } | null, number>({
			query: (activity) => `inputs/?activity=${activity}`,
			transformResponse: (response: InputsResponse[]) => {
				if (response && response.length > 0) {
					const parent = filterObjectProps(response[0], includedPropList) as InputsPayload
					const t2 = filterObjectProps(response[0], includedT2PropList) as InputsT2Payload
					return {
						parent,
						tierTwo: t2,
					}
				}
				return null
			},
			providesTags: (_results, _error, activityId) => [{ type: 'Inputs', id: activityId }]
		}),
		updateInputs: builder.mutation<InputsResponse, InputsUpdatePayload>({
			query: ({ body }) => {
				const { id, ...rest } = body
				convertEmptyStringsToNull(rest)
				return { url: `inputs/${id}/`, method: 'PUT', body: rest }
			},
			invalidatesTags: (_results, _error, { activityId, body }) => [{ type: 'Inputs', id: activityId }, { type: "ModulesStatuses", id: activityId }]
		}),
		getInputTypes: builder.query<InputType[], number | null>({
			query: (macroInputType) =>
				macroInputType
					? `input-types/?macro_input_type=${macroInputType}`
					: "input-types/",
		}),
		getMacroInputTypes: builder.query<MacroInputType[], void>({
			query: () => "macro-input-types/",
		}),
		getInputEntries: builder.query<InputEntryData[], number>({
			query: (activity) => `input-entries/?activity=${activity}`,
			// transformResponse: (response: InputEntryResponse[]) => response.sort(compare),
			providesTags: (_result, _error, activity) => [
				{
					type: "InputEntries",
					id: activity,
				},
			],
		}),
		updateInputEntry: builder.mutation<
			InputEntryResponse,
			InputEntryUpdatePayload
		>({
			query: ({ body }) => {
				const { id, ...rest } = body;
				// const payload = { ...rest, activity: activityId }

				convertEmptyStringsToNull(rest)
				return {
					url: `input-entries/${id}/`,
					method: "PUT",
					body: rest,
				};
			},
			invalidatesTags: (_results, _error, { activityId, body }) => [
				{ type: "InputEntries", id: activityId },
				{ type: "ModuleResults", id: body.parent ? `inputs-${body.parent}` : undefined },
				{ type: "ModuleHistory", id: body.parent ? `inputs-${body.parent}` : undefined },
				{ type: "ModulesStatuses", id: activityId },
				{ type: "InputEntriesDefaults", id: body?.id ? body?.id : undefined },
			],
		}),
		createInputEntry: builder.mutation<
			InputEntryResponse,
			InputEntryCreatePayload
		>({
			query: ({ body }) => ({
				url: "input-entries/",
				method: "POST",
				body,
			}),
			invalidatesTags: (_results, _error, { activityId, body }) => [
				{ type: "InputEntries", id: activityId },
				{ type: "ModuleResults", id: body.parent ? `inputs-${body.parent}` : undefined },
				{ type: "ModuleHistory", id: body.parent ? `inputs-${body.parent}` : undefined },
			],
		}),
		deleteInputEntry: builder.mutation<void, InputEntryDeletePayload>({
			query: ({ id }) => ({
				url: `input-entries/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: (_results, _error, { activityId, parent, id }) => [
				{ type: "InputEntries", id: activityId },
				{ type: "ModuleResults", id: parent ? `inputs-${parent}` : undefined },
				{ type: "ModuleHistory", id: parent ? `inputs-${parent}` : undefined },
				{ type: "InputEntriesDefaults", id },
			],
		}),
		getInputEntriesDefaults: builder.query<InputEntryDefaults, number>({
			query: (uniqueId) => `input-entries/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: "InputEntriesDefaults", id: uniqueId }],
		}),
	}),
});

export const {
	useGetInputsQuery,
	useUpdateInputsMutation,
	useGetInputEntriesQuery,
	useGetMacroInputTypesQuery,
	useGetInputTypesQuery,
	useCreateInputEntryMutation,
	useUpdateInputEntryMutation,
	useDeleteInputEntryMutation,
	useGetInputEntriesDefaultsQuery
} = moduleApiSlice;
