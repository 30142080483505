import * as Yup from "yup";
import type { BEModules } from "../../../utils/moduleList";
import type { LandUseChangeResponse } from "./landUseChanges/landUseChangeTypes";
import { AnyObject } from "yup/lib/types";
import { BelongsToLuc, DropdownOptions, FetchOptionsKit, InputGroup, Option, StartWithWithoutGeneric, TranslationKey } from "../../../types/modulesInterfaces";
import { isSWW } from "../../../utils/helperFunctions";
import { Activity, LandUseType } from "../../../types/interfaces";
import { ForestContextType } from "../../../contexts/ForestContext";

export const validatePercentage = Yup.number()
	.max(100, "validations.percentage_max_min")
	.min(0, "validations.percentage_max_min")
	.nullable();

export const validateStrictPercentage = validatePercentage.moreThan(0, "validations.percentage_strict");

export const validatePositiveNumber = Yup.number().nullable().min(0, "validations.number_positive")

export const standardLucValidation = (message: TranslationKey, scenario: 'start' | 'w' | 'wo', module: BEModules, baseValidation?: Yup.NumberSchema<number | null | undefined, AnyObject, number | null | undefined>) => {
	const moduleTypeScenario = `module_type_${scenario}` as keyof LandUseChangeResponse
	const validation = baseValidation ?? validatePositiveNumber
	return validation.when('land_use_change', {
		is: (land_use_change: LandUseChangeResponse | undefined) => !land_use_change || land_use_change?.[moduleTypeScenario] === module,
		// biome-ignore lint/suspicious/noThenProperty: <explanation>
		then: validation.required(message),
	})
}
export const handleDisableSww = ({
	input,
	isReadOnly,
	belongsToLuc,
	index
}: {
	input: InputGroup | StartWithWithoutGeneric,
	isReadOnly: boolean,
	belongsToLuc?: BelongsToLuc,
	index?: number,
}
) => {
	if (!isSWW(input.type)) return input;
	const swwInput = input as StartWithWithoutGeneric;
	return {
		...swwInput,
		index: index ?? swwInput.index,
		startProps: {
			...swwInput.startProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_start) ||
				swwInput.startProps?.disabled ||
				isReadOnly,
		},
		withProps: {
			...swwInput.withProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_w) ||
				swwInput.withProps?.disabled ||
				isReadOnly,
		},
		withoutProps: {
			...swwInput.withoutProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_wo) ||
				swwInput.withoutProps?.disabled ||
				isReadOnly,
		},
	} as StartWithWithoutGeneric;
};

export function getSWWInputGroup({
	swwInput,
	isReadOnly,
	belongsToLuc,
	index
}: {
	swwInput: StartWithWithoutGeneric,
	isReadOnly: boolean,
	belongsToLuc?: BelongsToLuc,
	index?: number,
}
): StartWithWithoutGeneric {
	return {
		...swwInput,
		index: index ?? swwInput.index,
		startProps: {
			...swwInput.startProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_start) ||
				swwInput.startProps?.disabled ||
				isReadOnly,
		},
		withProps: {
			...swwInput.withProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_w) ||
				swwInput.withProps?.disabled ||
				isReadOnly,
		},
		withoutProps: {
			...swwInput.withoutProps,
			disabled:
				(belongsToLuc && !belongsToLuc.is_wo) ||
				swwInput.withoutProps?.disabled ||
				isReadOnly,
		},
	}
}

export const filterLucTypesByClimateMoisture = (
	activity: Activity | null | undefined,
	lucTypes: Option[] | undefined,
): Option[] => {
	if (!activity || !lucTypes) return lucTypes ?? [];
	return lucTypes.filter((option) => {
		const lucType = option as unknown as LandUseType
		const applicableToClimate = lucType.climates.some((climate) => climate === activity?.climate_t2?.id)
		const applicableToMoisture = lucType.moistures.some((moisture) => moisture === activity?.moisture_t2?.id)
		return applicableToClimate && applicableToMoisture
	})
}

export const fetchLandUseTypes = async (landUseTypeKitHandler: () => Promise<FetchOptionsKit>, activity: Activity | null | undefined): Promise<FetchOptionsKit> => {
	const allLandUseTypesKit = await landUseTypeKitHandler()
	const allLandUseTypes = allLandUseTypesKit.data
	if (!activity) return allLandUseTypesKit
	const filtered = filterLucTypesByClimateMoisture(activity, allLandUseTypes);
	allLandUseTypesKit.data = filtered
	return allLandUseTypesKit
}