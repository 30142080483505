import type {
	HectaresInputGroup,
	InputGroup,
	T2Schema,
	ModuleSchema,
	StartWithWithoutTierTwo,
	Submodule,
} from "../../../../types/modulesInterfaces";
import type { SetAsidePayload, SetAsideT2Payload } from "./setAsideTypes";
import { FieldType } from "../../../../types/modulesInterfaces";
import * as Yup from "yup";

export const includedPropList = [
	"id",
	"status",
	"area",
	"ha_start",
	"ha_w",
	"ha_wo",
	"land_use_type_start",
	"land_use_type_w",
	"land_use_type_wo",
	"land_use_type_thread",
	"ha_thread",
	"note",
	"land_use_change"
];

export const includedT2PropList = [
	"biomass_t2_start",
	"biomass_t2_w",
	"biomass_t2_wo",
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo"
];

export const validationSchema = Yup.object({});
export const initialValues: SetAsidePayload = {
	id: null,
	status: null,
	area: null,
	ha_start: null,
	ha_w: null,
	ha_wo: null,
	land_use_type_start: null,
	land_use_type_w: null,
	land_use_type_wo: null,
	ha_thread: null,
	note: null,
};


const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "module.area",
		disabled: true,
	};

	return [hectaresInputGroup];
};

/* T2 */
export const t2InitialValues: SetAsideT2Payload = {
	biomass_t2_start: null,
	biomass_t2_w: null,
	biomass_t2_wo: null,
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,
	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null
};

const getT2Submodules: () => Submodule[] = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.soc_t2",
			label: "module.soil_carbon",
			unit: "[tC/ha]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.flu_t2",
			label: "module.land_use_factor",
			unit: "[#]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.fmg_t2",
			label: "module.management_factor",
			unit: "[#]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.fi_t2",
			label: "module.input_factor",
			unit: "[#]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.biomass_t2",
			label: "module.biomass",
			unit: "[tC/ha]",
			links: [],
			startProps: { inputType: "number" },
			withoutProps: { inputType: "number" },
			withProps: { inputType: "number" },
		},
	];

	return [
		{
			name: "",
			inputGroups: [...rows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: [],
		optionalSubmodules: [],
	};

	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
