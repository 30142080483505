import { BiSync, BiHelpCircle, BiDetail } from "react-icons/bi";
import {
	MdEditNote,
	MdHelpOutline,
	MdOutlineChat,
	MdOutlineMap,
} from "react-icons/md";
import { ModulesCube, MyBolt } from "./customIcons";
import { useLocation } from "react-router-dom";
import { Actions } from "../types/interfaces";
import type { IconType } from "react-icons";
import type { ReactElement } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import {
	setActionList,
	setActiveAction,
	setIsFetchingData,
} from "../app/features/builder/builderSlice";

import ModulesAction from "../components/actions/modules/ModulesAction";
import ChangesAction from "../components/actions/changes/ChangesAction";
import HelpAction from "../components/actions/HelpAction";
import LiveUpdatesAction from "../components/actions/liveUpdate/LiveUpdatesAction";
import NotesAction from "../components/actions/notes/NotesAction";
import CommentsAction from "../components/actions/comments/CommentsAction";
import MapAction from "../components/actions/map/MapAction";
import DocumentationAction from "../components/actions/DocumentationAction";
import DefinitionsAction from "../components/actions/definitions/DefinitionsAction";
import { TranslationKey } from "../types/modulesInterfaces";

export type ActionsInfoType = {
	[key in Actions]: {
		label: TranslationKey;
		icon: IconType;
		color: string;
	};
};

export type ActionsComponentType = {
	[key in Actions]: ReactElement | null;
};

export const ActionsInfo: ActionsInfoType = {
	[Actions.LiveUpdates]: {
		label: "main.live_updates",
		icon: BiSync,
		color: "#750c0c",
	},
	[Actions.Notes]: {
		label: "main.notes",
		icon: MdEditNote,
		color: "#F9BD24",
	},
	[Actions.Comments]: {
		label: "main.comments",
		icon: MdOutlineChat,
		color: "var(--tertiary-deep)",
	},
	[Actions.Help]: {
		label: "main.tutorials",
		icon: MdHelpOutline,
		color: "#4E93C6",
	},
	[Actions.Changes]: {
		label: "main.activity",
		icon: MyBolt,
		color: "var(--quaternary-deep)",
	},
	[Actions.ProgressiveModules]: {
		label: "main.modules",
		icon: ModulesCube,
		color: "var(--common-dark)",
	},
	[Actions.Map]: {
		label: "main.map",
		icon: MdOutlineMap,
		color: "#750c0c",
	},
	[Actions.MapActivity]: {
		label: "main.map",
		icon: MdOutlineMap,
		color: "#750c0c",
	},
	[Actions.Documentation]: {
		label: "main.documentation",
		icon: BiHelpCircle,
		color: "#4E93C6",
	},
	[Actions.Definitions]: {
		label: "main.definitions",
		icon: BiDetail,
		color: "#50A2A7",
	},
};

export const ActionsComponent: ActionsComponentType = {
	[Actions.LiveUpdates]: <LiveUpdatesAction />,
	[Actions.Notes]: <NotesAction />,
	[Actions.Comments]: <CommentsAction />,
	[Actions.Help]: <HelpAction />,
	[Actions.Changes]: <ChangesAction />,
	[Actions.ProgressiveModules]: <ModulesAction />,
	[Actions.Map]: <MapAction isActivity={false} />,
	[Actions.MapActivity]: <MapAction isActivity={true} />,
	[Actions.Documentation]: <DocumentationAction />,
	[Actions.Definitions]: <DefinitionsAction />,
};

const useTabsHook = () => {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();

	useEffect(() => {
		dispatch(setIsFetchingData(false));
		const pathList = pathname.split("/");
		const root = pathList[1];

		if (root === "project") {
			if (["activities", "description"].includes(pathList[3])) {
				dispatch(setActionList([Actions.Map, Actions.Documentation]));
				dispatch(setActiveAction(Actions.Map));
			} else if (pathList[3] === "results") {
				dispatch(setActionList([Actions.Map]));
				dispatch(setActiveAction(Actions.Map));
			} else if (pathList[3] === "new-activity") {
				dispatch(
					setActionList([
						Actions.ProgressiveModules,
						Actions.MapActivity,
						Actions.Documentation,
						Actions.Definitions
					]),
				);
				dispatch(setActiveAction(Actions.ProgressiveModules));
			}
		}

		if (root === "builder") {
			dispatch(
				setActionList([
					Actions.LiveUpdates,
					Actions.Comments,
					Actions.Notes,
					Actions.Help,
					Actions.Changes,
					Actions.Definitions
				]),
			);
			dispatch(setActiveAction(Actions.LiveUpdates));
		}
	}, [pathname, dispatch]);

	return { isHeaderSmall: pathname === "/" || pathname.startsWith("/auth") };
};

export default useTabsHook;
