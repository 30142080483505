import { FieldType } from "../../../types/modulesInterfaces";
import { landUseOptions } from "../modules/landUseChanges/landUseChange";
import { useGetLandUseTypesQuery } from "../../../app/features/dropdownOptions/dropdownOptionsApiSlice";
import type { StartWithWithoutGeneric } from "../../../types/modulesInterfaces";
import type { LandUseChangeResponse } from "./landUseChanges/landUseChangeTypes";
import { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import { filterLucTypesByClimateMoisture } from "./moduleUtils";
import { Activity } from "../../../types/interfaces";


const useLandUseTypeInput = ({ landUseChange }: { landUseChange?: LandUseChangeResponse | null }) => {
 const { data: landUseTypes } = useGetLandUseTypesQuery({});
 const { activity } = useAppSelector(selectCurrentProject);

 const landUseTypeInput: StartWithWithoutGeneric = useMemo(
  () => ({
   type: FieldType.SWW_SELECT,
   inputName: "module.land_use_type",
   label: "module.type_of_land_use",
   startProps: { dropdownOptions: filterLucTypesByClimateMoisture(activity,landUseTypes), disabled: true },
   withProps: { dropdownOptions: filterLucTypesByClimateMoisture(activity,landUseTypes), disabled: true },
   withoutProps: { dropdownOptions: filterLucTypesByClimateMoisture(activity,landUseTypes), disabled: true },
  }),
  [landUseTypes],
 );

 const landUseChangeInput: StartWithWithoutGeneric = useMemo(
  () => ({
   type: FieldType.SWW_SELECT,
   inputName: "module.land_use_change.module_type",
   label: "module.type_of_land_use",
   startProps: { dropdownOptions: landUseOptions, disabled: true },
   withProps: { dropdownOptions: landUseOptions, disabled: true },
   withoutProps: { dropdownOptions: landUseOptions, disabled: true },
  }),
  [],
 );

 const input = useMemo(
  () =>
   landUseChange
    ? landUseChangeInput
    : landUseTypeInput,
  [landUseChange, landUseTypeInput, landUseChangeInput],
 );

 return input
}

export default useLandUseTypeInput