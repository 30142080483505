import * as Yup from "yup";
import { validatePositiveNumber } from "../moduleUtils";
import type { InputsPayload, InputsT2Payload, NewEntryForm } from "./inputsTypes";


export const includedPropList = [
	"id",
	"note",
	"activity",
];
export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
]
export const validationSchema = Yup.object({
	entries: Yup.array().of(
		Yup.object().shape({
			input_type: validatePositiveNumber.required('validations.input_type_required'),
			value_start: validatePositiveNumber.required('validations.amount_required'),
			value_w: validatePositiveNumber.required('validations.amount_required'),
			value_wo: validatePositiveNumber.required('validations.amount_required'),
		}),
	),
});

export const validationCreateSchema = Yup.object({
	new_entry: Yup.object().shape({
		// macro_input_type: validatePositiveNumber.required("validations.is required"),
		input_type: validatePositiveNumber.required('validations.input_type_required'),
		value_start: validatePositiveNumber.required('validations.amount_required'),
		value_w: validatePositiveNumber.required('validations.amount_required'),
		value_wo: validatePositiveNumber.required('validations.amount_required'),
	}),
});


export const initNewEntryFormValues: NewEntryForm = {
	macro_input_type: null,
	input_type: null,
	value_start: null,
	value_w: null,
	value_wo: null,
};

export const initialValues: InputsPayload = {
	id: null,
	note: null
}
export const T2InitialValues: InputsT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null
}  
