import store from "../../store";
import { dropdownOptionsApiSlice } from "../dropdownOptions/dropdownOptionsApiSlice";
import type { FetchOptionsKit, Option } from "../../../types/modulesInterfaces";
import type { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import type { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import type {
	BaseQueryApi,
	FetchArgs,
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryDefinition,
} from "@reduxjs/toolkit/dist/query";
import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

type PropType = ThunkAction<
	QueryActionCreatorResult<
		QueryDefinition<
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			any,
			(
				args: string | FetchArgs,
				api: BaseQueryApi,
				// biome-ignore lint/complexity/noBannedTypes: <explanation>
				extraOptions: {},
			) => Promise<
				QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
			>,
			never,
			Option[],
			"api"
		>
	>,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	any,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	any,
	AnyAction
>;
type OptionName =
	| "climate"
	| "soil"
	| "fishery"
	| "fish"
	| "smallFishery"
	| "largeFishery"
	| "crop"
	| "tillage"
	| "organicInput"
	| "residue"
	| "landUseType"
	| "grassland"
	| "moisture"
	| "projectStatus"
	| "country"
	| "GWPSource"
	| "fundingAgency"
	| "executingAgency"
	| "livestockCategory"
	| "livestockProd"
	| "livestockMngmTypes"
	| "waterMngmTypesBC"
	| "waterMngmTypesAC"
	| "organicAmendmentTypes"
	| "forestTypes"
	| "disturbanceTypes"
	| "forestConditionTypes"
	| "trophicTypes"
	| "waterbodiesTypes"
	| "macroFuelTypes"
	| "fuelTypes"
	| "peatTypes"
	| "fireTypes"
	| "roadTypes"
	| "buildingTypes"
	| "salinity"
	| "emissionFactorSources"
	| "irrigationSystemTypes"
	| "settlementTypes"
	| "changeRates";

export const getOptions = async (optionName: OptionName, filterId?: number) => {
	let thunkOption: PropType | undefined = undefined
	switch (optionName) {
		case "climate":
			thunkOption = dropdownOptionsApiSlice.endpoints.getClimateTypes.initiate();
			break;
		case "moisture":
			thunkOption = dropdownOptionsApiSlice.endpoints.getMoistureTypes.initiate();
			break;
		case "soil":
			thunkOption = dropdownOptionsApiSlice.endpoints.getSoilTypes.initiate()
			break;
		case "fishery":
			thunkOption = dropdownOptionsApiSlice.endpoints.getTypesOfFishery.initiate()
			break;
		case "fish":
			thunkOption = dropdownOptionsApiSlice.endpoints.getFishCategories.initiate()
			break;
		case "smallFishery":
			thunkOption = dropdownOptionsApiSlice.endpoints.getSmallFishGearCategory.initiate()
			break;
		case "largeFishery":
			thunkOption = dropdownOptionsApiSlice.endpoints.getLargeFishGearCategories.initiate()
			break;
		case "crop":
			thunkOption = dropdownOptionsApiSlice.endpoints.getCropTypes.initiate() /* TODO: REMOVE */
			break;
		case "tillage":
			thunkOption = dropdownOptionsApiSlice.endpoints.getTillageTypes.initiate()
			break;
		case "organicInput":
			thunkOption = dropdownOptionsApiSlice.endpoints.getOrganicInputTypes.initiate()
			break;
		case "residue":
			thunkOption = dropdownOptionsApiSlice.endpoints.getResidueTypes.initiate()
			break;
		case "grassland":
			thunkOption = dropdownOptionsApiSlice.endpoints.getGrasslandTypes.initiate()
			break;
		case "projectStatus":
			thunkOption = dropdownOptionsApiSlice.endpoints.getProjectStatuses.initiate()
			break;
		case "country":
			thunkOption = dropdownOptionsApiSlice.endpoints.getCountries.initiate()
			break;
		case "GWPSource":
			thunkOption = dropdownOptionsApiSlice.endpoints.getGWPSources.initiate()
			break;
		case "executingAgency":
			thunkOption = dropdownOptionsApiSlice.endpoints.getExecutingAgencies.initiate()
			break;
		case "fundingAgency":
			thunkOption = dropdownOptionsApiSlice.endpoints.getFundingAgencies.initiate()
			break;
		case "livestockCategory":
			thunkOption = dropdownOptionsApiSlice.endpoints.getLivestockCategories.initiate()
			break;
		case "livestockProd":
			thunkOption = dropdownOptionsApiSlice.endpoints.getLivestockProductionTypes.initiate()
			break;
		case "livestockMngmTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getManureManagementTypes.initiate()
			break;
		case "waterMngmTypesBC":
			thunkOption = dropdownOptionsApiSlice.endpoints.getWaterManagementTypesBC.initiate()
			break;
		case "waterMngmTypesAC":
			thunkOption = dropdownOptionsApiSlice.endpoints.getWaterManagementTypesAC.initiate()
			break;
		case "organicAmendmentTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getOrganicAmendmentTypes.initiate()
			break;
		case "disturbanceTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getDisturbanceTypes.initiate()
			break;
		case "forestTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getForestTypes.initiate()
			break;
		case "fuelTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getFuelTypes.initiate(filterId)
			break;
		case "macroFuelTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getMacroFuelTypes.initiate()
			break;
		case "forestConditionTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getForestConditionTypes.initiate()
			break;
		case "trophicTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getTrophicTypes.initiate()
			break;
		case "waterbodiesTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getWaterbodyTypes.initiate()
			break;
		case "roadTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getRoadTypes.initiate()
			break;
		case "buildingTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getBuildingTypes.initiate()
			break;
		case "irrigationSystemTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getIrrigationSystemTypes.initiate()
			break;
		case "peatTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getPeatTypes.initiate()
			break;
		case "fireTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getFireTypes.initiate()
			break;
		case "salinity":
			thunkOption = dropdownOptionsApiSlice.endpoints.getSalinityTypes.initiate()
			break;
		case "emissionFactorSources":
			thunkOption = dropdownOptionsApiSlice.endpoints.getEmissionFactorSources.initiate()
			break;
		case "settlementTypes":
			thunkOption = dropdownOptionsApiSlice.endpoints.getSettlementTypes.initiate()
			break;
		case "changeRates":
			thunkOption = dropdownOptionsApiSlice.endpoints.getChangeRates.initiate()
			break;
		case "landUseType": {
			if (filterId) {
				const project = store.getState().project.project
				/* TODO: IMPORT THEM FROM ACTIVITY */
				const climate = project?.climate?.id
				const moisture = project?.moisture?.id
				thunkOption = dropdownOptionsApiSlice.endpoints.getLandUseTypes.initiate({ moduleType: filterId, climate, moisture })
			}
			else thunkOption = dropdownOptionsApiSlice.endpoints.getLandUseTypes.initiate({})
		}
	}
	if (!thunkOption) return { data: [] as Option[], isLoading: false, error: {} }
	const options = await fetchOptions(thunkOption);
	return options
}

const fetchOptions = async (
	thunkAction: PropType,
): Promise<FetchOptionsKit> => {
	try {
		const result = store.dispatch(thunkAction);
		const { data, error, isLoading } = await result;
		if (error)
			console.error("an error occurred while fetching climate types", error);

		return { data: data ? data : ([] as Option[]), error, isLoading };
	} catch (error) {
		console.log(error);
	}
	return { data: [] as Option[], isLoading: false, error: {} };
};
