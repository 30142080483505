import * as Yup from "yup";
import {
	Formik,
	Form,
	FormikProps,
	setNestedObjectValues,
	FormikTouched,
} from "formik";
import {
	FormStates,
	SetupAssistantForm,
} from "../setupAssistant/setupAssistant.types";
import { MachineProps } from "./setupAssistantMachine";
import SetupAssistantStep from "./SetupAssistantStep";
import { useMemo } from "react";
import SetupAssistantButton from "../../../../components/button/SetupAssistantButton";
import { validatePositiveNumber } from "../../../activityBuilder/modules/moduleUtils";
import { AllPossibleSetupAssistantForm } from "./setupAssistant.types";

const SetupAssistantWizard = ({
	handleNext,
	machineProps,
}: {
	handleNext: (values: SetupAssistantForm) => void;
	machineProps: MachineProps;
}) => {
	const {
		schema,
		currentState,
		values,
		canBack,
		canSkip,
		skip,
		goBack,
		isFinal,
	} = machineProps;

	const withoutLandUseValidationSchema = useMemo(
		() =>
			Yup.object({
				areaWithoutProject: validatePositiveNumber.required(),
				withoutProjectLandUse: validatePositiveNumber
					.when("areaWithoutProject", {
						is: (areaWithoutProject: number) => areaWithoutProject === 1,
						then: validatePositiveNumber.required(),
					})
					.test({
						name: "not-equal",
						message: "validations.luc_not_equal",
						test: function (value) {
							const areaWithoutProject = this.parent.areaWithoutProject;
							return (
								areaWithoutProject === 0 || value !== values.previousLandUse
							);
						},
					}),
			}),
		[values.previousLandUse],
	);

	return (
		<>
			{schema.map((submodule, i) => (
				<Formik
					initialValues={{...submodule?.initialValues, ...values}}
					validationSchema={
						submodule.step === FormStates.areaWithoutProject
							? withoutLandUseValidationSchema
							: submodule?.validationSchema
					}
					onSubmit={handleNext}
					enableReinitialize
					key={submodule.step}
				>
					{({
						validateForm,
						setTouched,
						values: stepValues,
					}: FormikProps<Partial<AllPossibleSetupAssistantForm>>) => (
						<Form>
							<SetupAssistantStep
								step={submodule}
								isComplete={currentState !== submodule.step}
								index={i}
							>
								{currentState === submodule.step ? (
									<div
										className="py-2 ps-1 d-flex align-items-center"
										style={{ gap: 8 }}
									>
										{canBack ? (
											<SetupAssistantButton
												handleClick={() => goBack(submodule?.initialValues)}
												type="back"
											/>
										) : null}
										{canSkip ? (
											<SetupAssistantButton
												type="skip"
												handleClick={async () => {
													const errors = await validateForm();
													if (Object.keys(errors).length === 0) {
														skip(stepValues as SetupAssistantForm);
													} else {
														setTouched(
															setNestedObjectValues<FormikTouched<unknown>>(
																errors,
																true,
															),
														);
													}
												}}
											/>
										) : null}
										{!isFinal ? <SetupAssistantButton type="next" /> : null}
									</div>
								) : null}
							</SetupAssistantStep>
						</Form>
					)}
				</Formik>
			))}
		</>
	);
};

export default SetupAssistantWizard;
