import { FieldType, type BooleanInputGroupTierTwo, type Submodule } from "../../../../types/modulesInterfaces";
import type { ElectricityData, FuelData, ModuleFull } from "./energyTypes";
import { FieldArray, Form, useFormikContext } from "formik";
import { motion } from "framer-motion";
import { TierTwoBlock } from "../GenericTierTwo";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import {
	useGetElectricityDefaultsQuery,
	useGetFuelDefaultsQuery,
} from "../../../../app/features/api/modules/energyApiSlice";
import { useEffect, useState } from "react";
import { usePermissions } from "../../../../app/hooks";
import { handleDisableSww } from "../moduleUtils";
import { preventFormSubmit } from "../inputs/utils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";
import CustomEFTierTwoGroup from "./CustomEFTierTwoGroup";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};

const submoduleName = "module.fuel_optional";


interface EnergyTierTwoProps {
	tabsOpen: boolean;
	electricitySchema: Submodule;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}

const EnergyTierTwo = ({
	electricitySchema,
	tabsOpen,
	setInitValues,
}: EnergyTierTwoProps) => {
	const { values } = useFormikContext<ModuleFull>();
	const { isReadOnly } = usePermissions();

	if (tabsOpen) return null;
	return (
		<motion.div initial={false} variants={variants}>
			<Form onKeyDown={preventFormSubmit} className="py-2">
				{/* electricity */}
				<FieldArray name="electricities">
					{() => (
						<>
							{values.electricities.length > 0
								? values.electricities.map((electricity, index) => (
									<ElectricityT2
										electricity={electricity}
										index={index}
										setInitValues={setInitValues}
										electricitySchema={electricitySchema}
										key={`${electricitySchema.name}-${electricity.id}`}
										disabled={isReadOnly}
									/>
								))
								: null}
						</>
					)}
				</FieldArray>

				{/* fuels */}
				<FieldArray name="fuels">
					{() => (
						<>
							{values.fuels.length > 0
								? values.fuels.map((fuel, index) => {

									return (
										<FuelT2
											fuel={fuel}
											index={index}
											key={`${submoduleName}-${fuel.id}`}
											setInitValues={setInitValues}
											disabled={isReadOnly}
										/>
									);
								})
								: null}
						</>
					)}
				</FieldArray>
			</Form>
		</motion.div>
	);
};

const ElectricityT2 = ({
	electricity,
	electricitySchema,
	index,
	setInitValues,
	disabled
}: {
	electricity: ElectricityData;
	index: number;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	electricitySchema: Submodule;
	disabled?: boolean
}) => {
	const { data: defaults } = useGetElectricityDefaultsQuery(
		electricity?.id ?? 0,
		{ skip: !electricity.id },
	);

	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				electricities: prev.electricities.map((e) =>
					e.id === electricity.id ? { ...e, ...defaults } : e,
				),
			}));
	}, [defaults, electricity, setInitValues]);

	return (
		<TranslatedFormSubmodule
			submoduleName={electricitySchema.name}
			itemId={electricity.id as number}
			itemIndex={index + 1}
		>
			<TierTwoBlock>
				{electricitySchema.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index });
					return (
						<GenericInputGroup
							key={`${input.label}-${i}`}
							inputGroup={{ ...curatedInput, index, disabled }}
						/>
					)
				})}
			</TierTwoBlock>
		</TranslatedFormSubmodule>
	);
};

const FuelT2 = ({
	fuel,
	index,
	setInitValues,
	disabled
}: {
	fuel: FuelData;
	index: number;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	disabled?: boolean
}) => {
	const [isFuelTypeSolid, setIsFuelTypeSolid] = useState<boolean>(false);
	const { data: defaults } = useGetFuelDefaultsQuery(fuel?.id ?? 0, {
		skip: !fuel.id,
	});
	const { setFieldValue } = useFormikContext()
	useEffect(() => {
		setIsFuelTypeSolid(fuel.fuel_macro_type !== 1);
		if (fuel.fuel_macro_type === 1)
			setFieldValue(`fuels.${index}.accounts_for_co2_emissions`, false);
	}, [fuel])

	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				fuels: prev.fuels.map((f) =>
					f.id === fuel.id ? { ...f, ...defaults } : f,
				),
			}));
	}, [defaults, fuel, setInitValues]);

	const accountsForCo2Emissions: BooleanInputGroupTierTwo = {
		type: FieldType.BOOLEAN_T2,
		inputName: (index: number) => `fuels.${index}.accounts_for_co2_emissions`,
		label: "module.accounts_for_co2_emissions",
		links: [],
	};
	const curatedInput = handleDisableSww({ input: accountsForCo2Emissions, isReadOnly: disabled ?? false, index });

	return (
		<TranslatedFormSubmodule
			key={`${submoduleName}-${index}`}
			submoduleName={submoduleName}
			itemId={fuel.id ?? 0}
			itemIndex={index + 1}
		>
			<TierTwoBlock>
				<CustomEFTierTwoGroup
					entry={fuel}
					index={index}
					defaults={defaults}
				/>

				{isFuelTypeSolid ?
					<GenericInputGroup
						key={`${curatedInput.inputName}`}
						inputGroup={{ ...curatedInput, index, disabled }}
					/>
					: null}

			</TierTwoBlock>
		</TranslatedFormSubmodule>
	);
};

export default EnergyTierTwo;
