import React, { useCallback, useMemo } from 'react'
import type {
	Submodule,
	StartWithWithoutGeneric,
	HectaresInputGroup,
	OptionalSubmodule,
	StartWithWithoutTierTwo,
	InputGroup,
	ModuleSchema,
	Comment,
	T2Schema,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import type {
	MinorSeason,
	MinorSeasonT2,
} from "./annualCroplandTypes";
import { getOptions } from '../../../../app/features/dropdownOptions/fetchOptions';
import { BEModules } from '../../../../utils/moduleList';
import { useAppSelector } from '../../../../app/hooks';
import { selectCurrentProject } from '../../../../app/features/project/projectSlice';
import { fetchLandUseTypes } from '../moduleUtils';

const tillageTypes = () => getOptions("tillage");
const organicInputTypes = () => getOptions("organicInput");
const residueTypes = () => getOptions("residue");
const landUseTypeKitHandler = () => getOptions("landUseType", BEModules.AnnualCropland);

const useAnnualCroplandSchema = () => {
    const { activity } = useAppSelector(selectCurrentProject);

    const landUseTypes =useCallback(async () => {
        return await fetchLandUseTypes(landUseTypeKitHandler, activity)
    }, [activity])
    

    const getInitInputGroups: () => InputGroup[] = useCallback(() => {
        const hectaresInputGroup: HectaresInputGroup = {
            type: FieldType.HECTARES,
            label: "module.hectares",
            unit: "[ha]",
            inputName: "module.area",
            disabled: true,
        };
        return [hectaresInputGroup];
    }, []);
    const getMandatorySubmodules: () => Submodule[] = useCallback(() => {
        const mainSeasonCrop: StartWithWithoutGeneric = {
            type: FieldType.SWW_SELECT,
            label: "module.main_season_crop",
            inputName: "module.land_use_type",
            startProps: { dropdownOptions: landUseTypes },
            withProps: { dropdownOptions: landUseTypes },
            withoutProps: { dropdownOptions: landUseTypes },
        };
        const tillageManagement: StartWithWithoutGeneric = {
            type: FieldType.SWW_SELECT,
            label: "module.tillage_management",
            inputName: "module.tillage_management_type",
            startProps: { dropdownOptions: tillageTypes },
            withProps: { dropdownOptions: tillageTypes },
            withoutProps: { dropdownOptions: tillageTypes },
        };
        const inputOfOrganicMaterial: StartWithWithoutGeneric = {
            type: FieldType.SWW_SELECT,
            label: "module.input_organic_material",
            inputName: "module.organic_input_type",
            startProps: { dropdownOptions: organicInputTypes },
            withProps: { dropdownOptions: organicInputTypes },
            withoutProps: { dropdownOptions: organicInputTypes },
        };
        const residueManagement: StartWithWithoutGeneric = {
            type: FieldType.SWW_SELECT,
            label: "module.residue_management",
            inputName: "module.residue_management_type",
            startProps: { dropdownOptions: residueTypes },
            withProps: { dropdownOptions: residueTypes },
            withoutProps: { dropdownOptions: residueTypes },
        };
        const yieldInputGroup: StartWithWithoutGeneric = {
            type: FieldType.SWW,
            label: "module.yield",
            unit: "[t/ha/yr]",
            inputName: "module.crop_yield_t2",
        };
    
        const mainSeasonComment: Comment = {
            active: false,
            onClick: () => {
                mainSeasonComment.active = !mainSeasonComment.active;
            },
        };
    
        return [
            {
                name: "module.main_season",
                inputGroups: [
                    mainSeasonCrop,
                    tillageManagement,
                    inputOfOrganicMaterial,
                    residueManagement,
                    yieldInputGroup,
                ],
                comment: mainSeasonComment,
            },
        ];
    }, [landUseTypes]);
    const getOptionalSubmodules: () => OptionalSubmodule<MinorSeason>[] = useCallback(() => {
        const minorSeasonCrop: StartWithWithoutGeneric = {
            type: FieldType.SWW_SELECT,
            label: "module.minor_season_crop",
            inputName: "module.minorSeason.minor_land_use_type",
            startProps: { dropdownOptions: landUseTypes },
            withProps: { dropdownOptions: landUseTypes },
            withoutProps: { dropdownOptions: landUseTypes },
            help: { type: "info", onClick: () => { } },
        };
        const residueManagement: StartWithWithoutGeneric = {
            type: FieldType.SWW_SELECT,
            label: "module.residue_management",
            inputName: "module.minorSeason.minor_residue_management_type",
            startProps: { dropdownOptions: residueTypes },
            withProps: { dropdownOptions: residueTypes },
            withoutProps: { dropdownOptions: residueTypes },
        };
        const yieldManagement: StartWithWithoutGeneric = {
            type: FieldType.SWW,
            label: "module.yield",
            unit: "[t/ha/yr]",
            inputName: "module.minorSeason.minor_yield",
        };
    
        return [
            {
                name: "module.minor_season_optional",
                inputName: "module.minorSeason",
                inputGroups: [minorSeasonCrop, residueManagement, yieldManagement],
                linkedTierTwoInputs: ["tiertwo.minorSeasons"], //posso rimuovere?
            },
        ];
    }, [landUseTypes]);
    const getT2Submodules: () => Submodule[] = useCallback(() => {
        const rows: StartWithWithoutTierTwo[] = [
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.soc_t2",
                label: "module.soil_carbon",
                unit: "[tC/ha]",
                links: [],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.flu_t2",
                label: "module.land_use_factor",
                unit: "[#]",
                links: [],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.fmg_t2",
                label: "module.tillage_factor",
                unit: "[#]",
                links: ["Tillage Management"],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.fi_t2",
                label: "module.input_factor",
                unit: "[#]",
                links: ["Input of Organic Material"],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
            },
            {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.biomass_t2",
                label: "module.biomass",
                unit: "[tC/ha]",
                links: ["Main Season Crop", "Yield"],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
            },
            {
                type: FieldType.SWW_T2,
                inputName: 'tiertwo.residue_availability_t2',
                label: "module.residue_availability",
                unit: "[tdm/ha]",
                links: [],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
            }
        ];
    
        return [
            {
                name: "module.main_season",
                inputGroups: [...rows],
            },
        ];
    }, []);
    
    const getT2OptionalSubmodules: () => OptionalSubmodule<MinorSeasonT2>[] = useCallback(() => {
            const minorBiomassAvailable: StartWithWithoutTierTwo = {
                type: FieldType.SWW_T2,
                inputName: "tiertwo.minorSeason.minor_biomass_factor_t2",
                label: "module.residue_biomass_availability",
                links: ["Minor Season Crop", "Yield"],
                startProps: { inputType: "number" },
                withoutProps: { inputType: "number" },
                withProps: { inputType: "number" },
                unit: "[unit]",
            };

            return [
                {
                    name: "module.minor_season",
                    inputName: "tiertwo.minorSeason",
                    inputGroups: [minorBiomassAvailable],
                },
            ];
    },[]);

    const getSchemas = () => {
        const moduleSchema: ModuleSchema = {
            initInputGroups: getInitInputGroups(),
            mandatorySubmodules: getMandatorySubmodules(),
            optionalSubmodules: getOptionalSubmodules(),
        };
        const t2Schema: T2Schema = {
            submodules: getT2Submodules(),
            optionalSubmodules: getT2OptionalSubmodules(),
        };
    
        return { moduleSchema, t2Schema };
    }

    const { moduleSchema, t2Schema } = useMemo(() => getSchemas(),[]);

    return { moduleSchema, t2Schema };
}

export default useAnnualCroplandSchema