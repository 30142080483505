import { useCallback, useState } from "react";
import { BiCheckCircle, BiEditAlt, BiTrash } from "react-icons/bi";
import { CommentIcon, ReplyArrowIcon } from "../../../utils/customIcons";
import KebabMenu, { Line } from "../../kebabMenu/KebabMenu";
import CommentTextEditorContainer from "./CommentTextEditorContainer";
import ReactQuill from "react-quill";
import { useAppSelector, usePermissions } from "../../../app/hooks";
import { selectCurrentProject } from "../../../app/features/project/projectSlice";
import { Role } from "../../../types/interfaces";

export interface Comment {
	id: number;
	author: User;
	content: string;
	replies?: Reply[];
	parent: number;
}
interface User {
	id: number;
	username: string;
}
interface Reply {
	author: User;
	content: string;
	replies: Reply[];
}
interface ContentBlockProps {
	comment: Comment;
	deleteHandler: (id: number) => void;
	createHandler: ({
		content,
		parent,
	}: { content: string; parent: number }) => void;
	id: string;
}

const CommentBlock: React.FC<ContentBlockProps> = ({
	comment,
	deleteHandler,
	createHandler,
	id
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isReplyActive, setIsReplyActive] = useState<boolean>(false);
	const { canMutateComment } = usePermissions();

	const handleCreateReply = useCallback(
		({ content }: { content: string }) => {
			createHandler({ content, parent: comment.id });
			setIsReplyActive(!isReplyActive)
		},
		[createHandler, comment.id, isReplyActive],
	);
	
	let lines: Line[] = [
		{
			id: 0,
			label: "main.reply",
			icon: BiEditAlt,
			clickHandler: () => setIsReplyActive(!isReplyActive),
		},
		/* {
			id: 1,
			label: "Resolve",
			icon: BiCheckCircle,
			clickHandler: () => console.log("resolve clicked"),
		}, */

	];
	if (canMutateComment(2)) {
		lines.push({
			id: 2,
			label: "main.delete",
			icon: BiTrash,
			clickHandler: () => deleteHandler(comment.id),
		})
	}
	return (
		<article
			className="comment-block"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			id={id}
		>
			<div className="d-flex align-items-start justify-content-between">
				<div
					className="d-flex align-items-start"
					style={{ paddingLeft: "3px" }}
				>
					<CommentIcon
						fill={isHovered ? "yes" : ""}
						style={{ paddingTop: "0.2rem" }}
					/>
					<div className="d-flex f-column align-items-start fs-13 ps-2">
						<div className="d-flex f-column align-items-start text-darker-gray">
							<strong className="ff-bold">Comment #{comment.id}</strong>
							<span>by: <strong className="ff-bold">{comment.author.username}</strong></span>
						</div>
						<ReactQuill
							modules={{ toolbar: false }}
							theme="snow"
							value={comment.content}
							readOnly={true}
						/>
					</div>
				</div>

				<KebabMenu
					lines={lines}
					activeColor="var(--tertiary-deep)"
					buttonClasses={isHovered ? "" : "hidden"}
					position="left"
				/>

			</div>


			{comment.replies
				? comment.replies.map((reply, index) => {
					return (
						<div className="d-flex align-items-start ms-2 pt-1" style={{ borderTop: '1px solid var(--gray)' }} key={`${reply.author}-${index}`}>
							<ReplyArrowIcon />
							<div className="d-flex f-column justify-content-start pt-1">
								<span className="text-darker-gray">
									Reply: <strong className="ff-bold fs-13">{reply.author.username}</strong>
								</span>
								<ReactQuill
									modules={{ toolbar: false }}
									theme="snow"
									value={reply.content}
									readOnly={true}
								/>
							</div>
						</div>
					);
				})
				: null}
			<CommentTextEditorContainer
				isCommentActive={isReplyActive}
				cancelCallback={() => setIsReplyActive(!isReplyActive)}
				okCallback={handleCreateReply}
			/>
		</article>
	);
};
export default CommentBlock;
