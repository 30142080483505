import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/forestManagement/forestManagement";
import type {
	ForestDisturbanceCreatePayload,
	ForestDisturbanceData,
	ForestDisturbanceDefaults,
	ForestDisturbanceDeletePayload,
	ForestDisturbancePayload,
	ForestDisturbanceResponse,
	ForestManagementDefaults,
	ForestManagementPayload,
	ForestManagementResponse,
	ForestManagementT2,
	ModuleFull,
} from "../../../../pages/activityBuilder/modules/forestManagement/forestManagementTypes";
import {
	convertIncomingPercentage,
	convertOutgoingPercentage,
	filterObjectProps,
	convertEmptyStringsToNull
} from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getForestManagement: builder.query<ModuleFull | null, number>({
			query: (activityId) => `forest-managements/?activity=${activityId}`,
			transformResponse: (response: ForestManagementResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						response[0],
						includedPropList,
					) as ForestManagementPayload;
					const t2 = filterObjectProps(
						response[0],
						includedT2PropList,
					) as ForestManagementT2;

					convertIncomingPercentage(module, [
						"rotation_percentage_biomass_for_energy",
						"logging_percentage_agb_logged",
						"logging_percentage_biomass_for_energy",
						"average_yearly_degradation_percentage",
					]);

					return { parent: module, tiertwo: t2, disturbances: [] };
				}
				return null;
			},
			providesTags: (_results, _error, id) => [
				{ type: "ForestManagement", id },
			],
		}),
		updateForestManagement: builder.mutation<
			ForestManagementResponse,
			ModuleFull
		>({
			query({ parent, tiertwo, activityId }) {
				const lucId = parent.land_use_change?.id;

				convertOutgoingPercentage(parent, [
					"rotation_percentage_biomass_for_energy",
					"logging_percentage_agb_logged",
					"logging_percentage_biomass_for_energy",
					"average_yearly_degradation_percentage",
				]);

				const payload = {
					...parent,
					...tiertwo,
				} as unknown as ForestManagementResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, land_use_change: lucId, activity: activityId };
				convertEmptyStringsToNull(body);
				return { url: `forest-managements/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "ForestManagement", id: results?.activity.id },
				{ type: "ModuleResults", id: results?.id ? `forest-managements-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `forest-managements-${results?.id}` : undefined },
				{ type: "ForestManagementDefaults", id: results?.id ? results?.id : undefined },
				{ type: "ModulesStatuses", id: results?.activity.id }
			],
		}),
		getForestManagementDefaults: builder.query<ForestManagementDefaults, number>({
			query: (uniqueId) => `forest-managements/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: 'ForestManagementDefaults', id: uniqueId }],
		}),
		getForestDisturbances: builder.query<ForestDisturbanceResponse[], number>({
			query: (activityId) => `forest-disturbances/?activity=${activityId}`,
			transformResponse: (response: ForestDisturbanceData[]) => {
				if (response && response.length > 0) {
					for (const res of response)
						convertIncomingPercentage(res, ["percentage_biomass_destruction"])

					return response;
				}
				return [];
			},
			providesTags: (_results, _error, id) => [
				{ type: "ForestDisturbance", id },
			],
		}),
		updateForestDisturbance: builder.mutation<
			ForestDisturbanceResponse,
			ForestDisturbancePayload
		>({
			query: ({ body }) => {
				const { id, ...rest } = body;
				const forestDisturbance = { ...rest };

				convertOutgoingPercentage(forestDisturbance, [
					"percentage_biomass_destruction",
				]);

				convertEmptyStringsToNull(forestDisturbance);
				return {
					url: `forest-disturbances/${id}/`,
					method: "PUT",
					body: forestDisturbance,
				};
			},
			invalidatesTags: (_results, _error, data) => [
				{ type: "ForestDisturbance", id: data.activityId },
				{ type: "ModuleResults", id: data.body.forest_management ? `forest-managements-${data.body.forest_management}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: data.body.forest_management ? `forest-managements-${data.body.forest_management}` : undefined },
				{ type: "ForestDisturbanceDefaults", id: data.body.id ? data.body.forest_management : undefined },
				{ type: "ModulesStatuses", id: data.activityId }
			],
		}),
		createForestDisturbance: builder.mutation<
			ForestDisturbanceResponse,
			ForestDisturbanceCreatePayload
		>({
			query: ({ body }) => ({
				url: "forest-disturbances/",
				method: "POST",
				body,
			}),
			invalidatesTags: (_results, _error, data) => [
				{ type: "ForestDisturbance", id: data.activityId },
				{ type: "ModuleResults", id: data.body.parent ? `forest-managements-${data.body.parent}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: data.body.parent ? `forest-managements-${data.body.parent}` : undefined },

			],
		}),
		deleteForestDisturbance: builder.mutation<
			ForestDisturbanceResponse,
			ForestDisturbanceDeletePayload
		>({
			query: ({ id }) => ({
				url: `forest-disturbances/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: (_results, _error, data) => [
				{ type: "ForestDisturbance", id: data.activityId },
				{ type: "ModuleResults", id: data.parent ? `forest-managements-${data.parent}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: data.parent ? `forest-managements-${data.parent}` : undefined },
			],
		}),
		getForestDisturbanceDefaults: builder.query<ForestDisturbanceDefaults, number>({
			query: (uniqueId) => `forest-disturbances/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: 'ForestDisturbanceDefaults', id: uniqueId }],
		}),
	}),
});

export const {
	useGetForestManagementQuery,
	useUpdateForestManagementMutation,
	useGetForestDisturbancesQuery,
	useUpdateForestDisturbanceMutation,
	useCreateForestDisturbanceMutation,
	useDeleteForestDisturbanceMutation,
	useGetForestManagementDefaultsQuery,
	useGetForestDisturbanceDefaultsQuery,
} = moduleApiSlice;
