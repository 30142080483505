import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";

import {
	initialValues,
	validationSchema,
	getSchemas,
	t2InitialValues,
} from "./organicSoil";
import {
	useAppSelector,
	useSyncCommentGroupsWithStore,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/moduleList";
import {
	useGetOrganicSoilDefaultsQuery,
	useGetOrganicSoilQuery,
	useUpdateOrganicSoilMutation,
} from "../../../../app/features/api/modules/organicSoilApiSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import type { ModuleFull } from "./organicSoilTypes";

const OrganicSoil = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: organicSoilData,
		isLoading,
		isError,
		error,
	} = useGetOrganicSoilQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateOrgSoil,
		{ error: updateError, isError: isUpdateError, isLoading: isUpdateLoading },
	] = useUpdateOrganicSoilMutation();
	const { data: defaults, error: defaultsError, isError: isDefaultsError } = useGetOrganicSoilDefaultsQuery(
		organicSoilData?.module.id ?? 0,
		{ skip: !organicSoilData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });


	const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (organicSoilData)
			setInitValues({
				module: organicSoilData.module,
				tiertwo: { ...organicSoilData.tiertwo, ...defaults },
			});
	}, [organicSoilData, defaults]);

	useSyncCommentGroupsWithStore<ModuleFull>({
		T1Data: { module: organicSoilData?.module ?? initValues.module },
		moduleSchema,
		isT1DataLoading: isLoading,
	});

	const { notes } = useSyncNotesWithStore({
		notes: organicSoilData?.module.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const module = { ...values.module, notes };
		const payload = {
			data: { ...values, module },
			activityId: activeActivityId ?? 0,
		};

		try {
			values.module.id && (await updateOrgSoil(payload).unwrap());
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<GenericModule
					title="Organic Soil"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					beModuleType={BEModules.OrganicSoil}
					isLoading={isUpdateLoading}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{t2Schema.submodules.length > 0 ? (
					<GenericTierTwo
						title="Organic Soil"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg ?? null}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default OrganicSoil;
