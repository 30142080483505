import type {
    StartWithWithoutGeneric,
    HectaresInputGroup,
    OptionalSubmodule,
    InputGroup,
    DropdownInputGroup,
    T2Schema,
    ModuleSchema,
    StartWithWithoutTierTwo,
    Submodule,
    TranslationKey,
} from "../../../../types/modulesInterfaces";
import { Settlements, type Building, type BuildingT2, type OtherT2, type Road, type RoadT2, type SettlementsPayload, type SettlementsT2Payload } from "./settlementsTypes";
import { FieldType } from "../../../../types/modulesInterfaces";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import * as Yup from "yup";
import { BEModules } from "../../../../utils/moduleList";
import { standardLucValidation } from "../moduleUtils";

export const includedPropList = [
    "id",
    "module_type",
    "activity",
    "land_use_change?",
    "status",

    "note",
    "start_year",
    "area",
    "ha_start",
    "ha_w",
    "ha_wo",

    "land_use_type_start",
    "land_use_type_w",
    "land_use_type_wo",
    "settlement_type_start",
    "settlement_type_w",
    "settlement_type_wo",
    "land_use_type_thread",
    "ha_thread",
]
export const includedT2PropList = [
    "biomass_t2_start",
    "biomass_t2_w",
    "biomass_t2_wo",
    "soc_t2_start",
    "soc_t2_w",
    "soc_t2_wo",
    "fi_t2_start",
    "fi_t2_w",
    "fi_t2_wo",
    "fmg_t2_start",
    "fmg_t2_w",
    "fmg_t2_wo",
    "soil_carbon_t2_start",
    "soil_carbon_t2_w",
    "soil_carbon_t2_wo",
    "flu_t2_start",
    "flu_t2_w",
    "flu_t2_wo",
]

const buildingTypes = () => getOptions("buildingTypes")
const roadTypes = () => getOptions("roadTypes")
const settlementTypes = () => getOptions("settlementTypes")

export const validationSchema = Yup.object({
	module: Yup.object().shape({
		settlement_type_start: standardLucValidation('validations.settlement_system_required', 'start', BEModules.Settlements),
		settlement_type_w: standardLucValidation('validations.settlement_system_required', 'w', BEModules.Settlements),
		settlement_type_wo: standardLucValidation('validations.settlement_system_required', 'wo', BEModules.Settlements),
	}),
	roads: Yup.array().of(
		Yup.object().shape({
			length_km_start: standardLucValidation('validations.road_length_required', 'start', BEModules.Settlements),
			length_km_w: standardLucValidation('validations.road_length_required', 'w', BEModules.Settlements),
			length_km_wo: standardLucValidation('validations.road_length_required', 'wo', BEModules.Settlements),
			road_type: standardLucValidation('validations.road_type_required', 'start', BEModules.Settlements),
			width_m_start: standardLucValidation('validations.road_width_required', 'start', BEModules.Settlements),
			width_m_w: standardLucValidation('validations.road_width_required', 'w', BEModules.Settlements),
			width_m_wo: standardLucValidation('validations.road_width_required', 'wo', BEModules.Settlements),
		})
	),
	buildings: Yup.array().of(
		Yup.object().shape({
			area_m2_start: standardLucValidation('validations.building_area_required', 'start', BEModules.Settlements),
			area_m2_w: standardLucValidation('validations.building_area_required', 'w', BEModules.Settlements),
			area_m2_wo: standardLucValidation('validations.building_area_required', 'wo', BEModules.Settlements),
			building_type: standardLucValidation('validations.building_type_required', 'start', BEModules.Settlements),
		})
	),
	others: Yup.array().of(
		Yup.object().shape({
			area_m2_start: standardLucValidation('validations.infrastructure_area_required', 'start', BEModules.Settlements),
			area_m2_w: standardLucValidation('validations.infrastructure_area_required', 'w', BEModules.Settlements),
			area_m2_wo: standardLucValidation('validations.infrastructure_area_required', 'wo', BEModules.Settlements),
		})
	),
});


const getInitInputGroups: () => InputGroup[] = () => {
    const hectaresInputGroup: HectaresInputGroup = {
        type: FieldType.HECTARES,
        label: "module.hectares",
        unit: "[ha]",
        inputName: "module.area",
        disabled: true,
    };

    const settlementType: StartWithWithoutGeneric = {
        type: FieldType.SWW_SELECT,
        label: "module.settlement_system_required",
        inputName: "module.settlement_type",
        startProps: { dropdownOptions: settlementTypes },
        withProps: { dropdownOptions: settlementTypes },
        withoutProps: { dropdownOptions: settlementTypes },
    };

    return [hectaresInputGroup, settlementType];
};

const getOptionalSubmodules: () => OptionalSubmodule<Building | Road>[] = () => {
    const buildingType: DropdownInputGroup = {
        type: FieldType.SELECT,
        inputName: (index: number) => `buildings.${index}.building_type`,
        label: "module.type_of_building",
        dropdownOptions: buildingTypes,
    };
    const buildingArea: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.building_area",
        unit: "[m2]",
        inputName: (index: number) => `buildings.${index}.area_m2`,
        startProps: { inputType: "number" },
        withProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
    };

    const roadType: DropdownInputGroup = {
        type: FieldType.SELECT,
        inputName: (index: number) => `roads.${index}.road_type`,
        label: "module.type_of_road",
        dropdownOptions: roadTypes,
    };
    const roadLength: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.road_length",
        unit: "[km]",
        inputName: (index: number) => `roads.${index}.length_km`,
        startProps: { inputType: "number" },
        withProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
    };
    const roadWidth: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.road_width",
        unit: "[m]",
        inputName: (index: number) => `roads.${index}.width_m`,
        startProps: { inputType: "number" },
        withProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
    };

    const otherArea: StartWithWithoutGeneric = {
        type: FieldType.SWW,
        label: "module.other_area",
        unit: "[m2]",
        inputName: (index: number) => `others.${index}.area_m2`,
        startProps: { inputType: "number" },
        withProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
    };

    return [
        {
            name: Settlements.Buildings,
            inputName: "module.buildings",
            inputGroups: [buildingType, buildingArea],
            repeatable: true,
            linkedTierTwoInputs: ["tiertwo.buildings"],
            route: "buildings",
            cacheTag: "BuildingsDefaults",
        },
        {
            name: Settlements.Roads,
            inputName: "module.roads",
            inputGroups: [roadType, roadLength, roadWidth],
            repeatable: true,
            linkedTierTwoInputs: ["tiertwo.roads"],
            route: "roads",
            cacheTag: "RoadsDefaults",
        },
        {
            name: Settlements.Others,
            inputName: "module.others",
            inputGroups: [otherArea],
            repeatable: true,
            route: "others",
            cacheTag: "OthersDefaults",
        },
    ];
};


export const initialValues: SettlementsPayload = {
    id: null,
    status: null,
    land_use_change: null,

    note: null,
    area: null,
    ha_start: null,
    ha_w: null,
    ha_wo: null,
    settlement_type_start: null,
    settlement_type_w: null,
    settlement_type_wo: null,
    land_use_type_start: null,
    land_use_type_w: null,
    land_use_type_wo: null,
    land_use_type_thread: null,
    ha_thread: null,
}

/* T2 */
export const t2InitialValues: SettlementsT2Payload = {
    soc_t2_start: null,
    soc_t2_w: null,
    soc_t2_wo: null,
    fi_t2_start: null,
    fi_t2_w: null,
    fi_t2_wo: null,
    fmg_t2_start: null,
    fmg_t2_w: null,
    fmg_t2_wo: null,
    soil_carbon_t2_start: null,
    soil_carbon_t2_w: null,
    soil_carbon_t2_wo: null,
    flu_t2_start: null,
    flu_t2_w: null,
    flu_t2_wo: null,
    biomass_t2_start: null,
    biomass_t2_w: null,
    biomass_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
    const rows: StartWithWithoutTierTwo[] = [
        {
            type: FieldType.SWW_T2,
            inputName: "tiertwo.soc_t2",
            label: "module.soil_carbon",
            unit: "[tC/ha]",
            links: [],
            startProps: { inputType: "number" },
            withoutProps: { inputType: "number" },
            withProps: { inputType: "number" },
        },
        {
            type: FieldType.SWW_T2,
            inputName: "tiertwo.flu_t2",
            label: "module.land_use_factor",
            unit: "[#]",
            links: [],
            startProps: { inputType: "number" },
            withoutProps: { inputType: "number" },
            withProps: { inputType: "number" },
        },
        {
            type: FieldType.SWW_T2,
            inputName: "tiertwo.fmg_t2",
            label: "module.management_factor",
            unit: "[#]",
            links: [],
            startProps: { inputType: "number" },
            withoutProps: { inputType: "number" },
            withProps: { inputType: "number" },
        },
        {
            type: FieldType.SWW_T2,
            inputName: "tiertwo.fi_t2",
            label: "module.input_factor",
            unit: "[#]",
            links: [],
            startProps: { inputType: "number" },
            withoutProps: { inputType: "number" },
            withProps: { inputType: "number" },
        },
        {
            type: FieldType.SWW_T2,
            inputName: "tiertwo.biomass_t2",
            label: "module.biomass",
            unit: "[tC/ha]",
            links: [],
            startProps: { inputType: "number" },
            withoutProps: { inputType: "number" },
            withProps: { inputType: "number" },
        },
    ];

    return [
        {
            name: "",
            inputGroups: [...rows],
        },
    ];
};

const getT2OptionalSubmodules: () => OptionalSubmodule<BuildingT2 | RoadT2 | OtherT2>[] = () => {
    const efBuilding: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        inputName: (index: number) => `buildings.${index}.ef_t2`,
        label: "module.ef_building",
        unit: "[tCO2/m2]",
        links: [],
        startProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
        withProps: { inputType: "number" },
    };
    const efRoad: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        inputName: (index: number) => `roads.${index}.ef_t2`,
        label: "module.ef_road",
        unit: "[tCO2/m2]",
        links: [],
        startProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
        withProps: { inputType: "number" },
    };
    const efOther: StartWithWithoutTierTwo = {
        type: FieldType.SWW_T2,
        inputName: (index: number) => `others.${index}.ef_t2`,
        label: "module.ef_others_user_defined",
        unit: "[tCO2/m2]",
        links: [],
        startProps: { inputType: "number" },
        withoutProps: { inputType: "number" },
        withProps: { inputType: "number" },
    };

    return [
        {
            name: "module.building",
            inputName: "buildings",
            inputGroups: [efBuilding],
            route: "buildings",
            cacheTag: "BuildingsDefaults",
        },
        {
            name: "module.road",
            inputName: "roads",
            inputGroups: [efRoad],
            route: "roads",
            cacheTag: "RoadsDefaults",
        },
        {
            name: "module.other",
            inputName: "others",
            inputGroups: [efOther],
            route: "others",
            cacheTag: "OthersDefaults",
        },
    ];
};


export const getSchemas = () => {
    const moduleSchema: ModuleSchema = {
        initInputGroups: getInitInputGroups(),
        mandatorySubmodules: [],
        optionalSubmodules: getOptionalSubmodules(),
    };

    const t2Schema: T2Schema = {
        submodules: getT2Submodules(),
        optionalSubmodules: getT2OptionalSubmodules(),
    };

    return { moduleSchema, t2Schema };
};
