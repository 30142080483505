import { AnyObject } from "yup/lib/types"
import { BEModules } from "../../../../../utils/moduleList"
import * as Yup from "yup";
import type {
    ModuleFull,
} from "../forestManagementTypes";
import { messages } from "./messages";
import { standardLucValidation, validatePercentage, validatePositiveNumber, validateStrictPercentage } from "../../moduleUtils";
import { TranslationKey } from "../../../../../types/modulesInterfaces";

type Scenario = 'start' | 'w' | 'wo'
abstract class Validation {
    isLuc = true
    isLucStart = true
    isLucW = true
    isLucWO = true
    moduleFull: ModuleFull | null = null
    scenario: Scenario
    constructor(contex: Yup.TestContext<AnyObject>, scenario: Scenario) {
        this.initialize(contex);
        this.scenario = scenario
    }

    protected initialize(contex: Yup.TestContext<AnyObject>) {
        const isLuc = contex.parent.land_use_change;
        const unsafeContext = contex as any;
        const moduleFull: ModuleFull = unsafeContext.from[1]?.value;
        this.isLuc = isLuc;
        this.moduleFull = moduleFull ?? null;
        this.isLucStart = isLuc?.module_type_start === BEModules.ForestManagement
        this.isLucW = isLuc?.module_type_w === BEModules.ForestManagement
        this.isLucWO = isLuc?.module_type_wo === BEModules.ForestManagement
    }
    getContextValue(contex: Yup.TestContext<AnyObject>) {
        return {
            isLuc: this.isLuc,
            isLucStart: this.isLucStart,
            moduleFull: this.moduleFull ?? null,
        }
    }
    validateLuc() {
        switch (this.scenario) {
            case 'start':
                return !this.isLuc || !this.isLucStart
            case 'w':
                return !this.isLuc || !this.isLucW
            case 'wo':
                return !this.isLuc || !this.isLucWO
        }
    }
    validate(value: number | undefined | null) {
        if (value === null || value === undefined) return true
        if (this.validateSpecificConditios(this.scenario)) return this.validateLuc()
        return true
    }

    validateSpecific() {
        return this.validateSpecificConditios(this.scenario)
    }

    noLoggings() {
        if (!this.moduleFull) return true
        const parent = this.moduleFull.parent
        return !parent[`logging_recurrence_yrs_${this.scenario}`] && !parent[`logging_percentage_agb_logged_${this.scenario}`] && !parent[`logging_percentage_biomass_for_energy_${this.scenario}`]
    }
    noRotations(): boolean {
        if (!this.moduleFull) return true
        const parent = this.moduleFull.parent
        return !parent[`rotation_length_yrs_${this.scenario}`] && !parent[`rotation_percentage_biomass_for_energy_${this.scenario}`]
    }
    noDegradations(): boolean {
        if (!this.moduleFull) return true
        const parent = this.moduleFull.parent
        return !parent[`average_yearly_degradation_percentage_${this.scenario}`]
    }
    noDisturbances(): boolean {
        if (!this.moduleFull) return true
        const recurrenceDisturbance = this.moduleFull.disturbances.find((dist) => dist[`recurrence_yrs_${this.scenario}`])
        const biomassDisturbance = this.moduleFull.disturbances.find((dist) => dist[`percentage_biomass_destruction_${this.scenario}`])
        return !recurrenceDisturbance?.[`recurrence_yrs_${this.scenario}`] && !biomassDisturbance?.[`percentage_biomass_destruction_${this.scenario}`]
    }
    abstract validateSpecificConditios(scenario: Scenario): boolean
}

class DisturbanceValidation extends Validation {
    validateSpecificConditios(scenario: Scenario): boolean {
        return this.noRotations() && this.noDegradations()
    }
}

class RotationValidation extends Validation {
    validateSpecificConditios(): boolean {
        return this.noLoggings() && this.noDegradations() && this.noDisturbances()
    }
}

class LoggingValidation extends Validation {
    validateSpecificConditios(): boolean {
        return this.noRotations() && this.noDegradations()
    }
}

class DegradationValidation extends Validation {
    validateSpecificConditios(): boolean {
        return this.noLoggings() && this.noDisturbances() && this.noRotations()
    }
}
type GenericValidation = typeof DisturbanceValidation | typeof RotationValidation | typeof LoggingValidation | typeof DegradationValidation

function createValidation(
    scenario: Scenario,
    field: keyof typeof messages,
    isPercentage: boolean,
    GenericValidation: GenericValidation,
    isStrictPercentage = false
) {
    const validation = isStrictPercentage ? validateStrictPercentage : isPercentage ? validatePercentage  : validatePositiveNumber

    return standardLucValidation(messages[field].requiredMessage as unknown as TranslationKey, scenario, BEModules.ForestManagement, validation)
        .test(`${field}_${scenario}`, messages[field].conditionsMessage, function (value) {
            if (!value) return true
            const validator = new GenericValidation(this, scenario)
            return validator.validateSpecific()
        })
}



export function createdDisturbanceValidation(scenario: Scenario, field: keyof typeof messages, isPercentage = false) {
    return createValidation(scenario, field, isPercentage, DisturbanceValidation, isPercentage ? true : false)
}
export function createRotationValidation(scenario: Scenario, field: keyof typeof messages, isPercentage = false) {
    return createValidation(scenario, field, isPercentage, RotationValidation)
}

export function createLoggingValidation(scenario: Scenario, field: keyof typeof messages, isPercentage = false) {
    return createValidation(scenario, field, isPercentage, LoggingValidation)
}

export function createDegradationValidation(scenario: Scenario, field: keyof typeof messages, isPercentage = false) {
    return createValidation(scenario, field, isPercentage, DegradationValidation)
}
