import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useState, useMemo } from "react";

import {
	validationSchema,
	getSchemas,
	energiesInitialValues,
	initialValues,
} from "./energy";
import {
	useAppSelector,
	useSyncCommentGroupsWithStore,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import type {
	ElectricityPayload,
	FuelPayload,
	ModuleFull,
} from "./energyTypes";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetElectricityQuery,
	useGetEnergiesQuery,
	useGetFuelQuery,
	useUpdateElectricityMutation,
	useUpdateEnergiesMutation,
	useUpdateFuelMutation,
} from "../../../../app/features/api/modules/energyApiSlice";
import EnergyTierOne from "./EnergyTierOne";
import EnergyTierTwo from "./EnergyTierTwo";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import { BEModules } from "../../../../utils/moduleList";

const Energy = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: energiesData,
		isLoading: isEnergiesLoading,
		isError: isEnergiesError,
		error: energiesError,
	} = useGetEnergiesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateEnergies,
		{
			isLoading: isLoadingEnergiesUpdate,
			isError: isEnergiesUpdateError,
			error: updateEnergiesError,
		},
	] = useUpdateEnergiesMutation();
	const {
		data: electricityData,
		isLoading: isElectricityLoading,
		isError: isElectricityError,
		error: electricityError,
	} = useGetElectricityQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const {
		data: fuelData,
		isLoading: isFuelLoading,
		isError: isFuelLoadingError,
		error: fuelError,
	} = useGetFuelQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	const [
		updateElectricity,
		{
			isLoading: isLoadingElectricityUpdate,
			isError: isElectricityUpdateError,
			error: updateElectricityError,
		},
	] = useUpdateElectricityMutation();
	const [
		updateFuel,
		{
			isLoading: isLoadingFuelUpdate,
			isError: isFuelUpdateError,
			error: updateFuelError,
		},
	] = useUpdateFuelMutation();

	const {
		electricitySchema,
		fuelSchema,
		electricityT2Schema,
	} = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>(initialValues);

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [
			isElectricityLoading,
			isLoadingElectricityUpdate,
			isFuelLoading,
			isLoadingFuelUpdate,
		],
		isMutateError: isElectricityUpdateError || isFuelUpdateError,
		mutateError: updateElectricityError ?? updateFuelError,
	});
	const { errorMsg: blockingElectricityError } = useErrorMessage({
		isError: isElectricityError,
		error: electricityError,
	});
	const { errorMsg: blockingFuelError } = useErrorMessage({
		isError: isFuelLoadingError,
		error: fuelError,
	});

	useEffect(() => {
		if (!energiesData) return;
		setInitValues((current) => ({
			...current,
			parent: energiesData.module,
			tiertwo: energiesData.tiertwo,
		}));
	}, [energiesData]);

	useSyncCommentGroupsWithStore<ModuleFull>({
		T1Data: {
			electricities: electricityData ?? [],
			fuels: fuelData ?? [],
			parent: energiesData?.module ?? energiesInitialValues,
		},
		moduleSchema: null,
		isT1DataLoading: isElectricityLoading || isFuelLoading,
		variableSubmodules: [
			{ data: electricityData, schema: electricitySchema },
			{ data: fuelData, schema: fuelSchema },
		],
	});
	const { notes } = useSyncNotesWithStore({
		notes: energiesData?.module?.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		//update notes
		const parent = { ...values.parent };
		const payload = {
			body: { ...values.tiertwo, ...parent },
			activityId: activeActivityId ?? 0,
		};

		try {
			const promises = [];
			if (values.parent.id) promises.push(updateEnergies(payload));

			// Update electricities
			for (const electricity of values.electricities) {
				const electricityPayload: ElectricityPayload = {
					activityId: activeActivityId ?? 0,
					body: electricity,
				};

				if (electricity.id)
					promises.push(updateElectricity(electricityPayload));
			}

			// Update fuels
			for (const fuel of values.fuels) {
				const fuelPayload: FuelPayload = {
					activityId: activeActivityId ?? 0,
					body: fuel,
				};

				if (fuel.id) promises.push(updateFuel(fuelPayload));
			}

			await Promise.all(promises);
			console.log("finished all mutations");
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<EnergyTierOne
					title="Energy"
					electricitySchema={electricitySchema}
					fuelSchema={fuelSchema}
					isElectricityLoading={
						isElectricityLoading || isLoadingElectricityUpdate
					}
					isFuelLoading={isFuelLoading || isLoadingFuelUpdate}
					blockingError={blockingElectricityError ?? blockingFuelError}
					footerError={errorMsg}
					beModuleType={BEModules.Energy}
					isError={isElectricityUpdateError || isFuelUpdateError}
					setInitValues={setInitValues}
					note={initValues.parent.note?.content ?? null}
				/>
				<EnergyTierTwo
					tabsOpen={tabsOpen}
					electricitySchema={electricityT2Schema}
					setInitValues={setInitValues}
				/>
			</>
		</Formik>
	);
};

export default Energy;
