import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD-BXKvctJrjJ8QT-AbpX5094vMqKGwqYY",
  authDomain: "fao-exact.firebaseapp.com",
  projectId: "fao-exact",
  storageBucket: "fao-exact.appspot.com",
  messagingSenderId: "913617318560",
  appId: "1:913617318560:web:ec9600a6d101af6d7a4fc5",
  measurementId: "G-GWJP2ERZJC"
};

// Initialize Firebase
export const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app); // we'll use this to log events: logEvent(analytics, 'event_name');
}