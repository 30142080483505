import { ReactNode, useState, createContext, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface ContextType {
	isOpen: boolean;
	toggleOpen: (value?: boolean) => void;
}

export const AccordionContext = createContext<ContextType>({
	isOpen: false,
	toggleOpen: () => {},
});

const variants = {
	open: {
		opacity: 1,
		height: "auto",
		transition: {
			opacity: {
				duration: 0.25,
			},
		},
		overflow: "visible",
	},
	collapsed: {
		opacity: 0,
		height: 0,
		transition: {
			opacity: {
				duration: 0.25,
			},
		},
		overflow: "hidden",
	},
};

const Accordion = ({
	header,
	children,
	initiallyOpen,
}: {
	header: ReactNode;
	children: ReactNode;
	initiallyOpen?: boolean;
}) => {
	const [isOpen, setIsOpen] = useState(
		initiallyOpen !== undefined ? initiallyOpen : true,
	);
	const handleToggle = useCallback((value?: boolean) => {
		value !== undefined ? setIsOpen(value) : setIsOpen((prev) => !prev);
	}, []);
	return (
		<AccordionContext.Provider value={{ isOpen, toggleOpen: handleToggle }}>
			<div>
				<div>{header}</div>
				<AnimatePresence initial={false}>
					<motion.section
						initial="collapsed"
						animate={isOpen ? "open" : "collapsed"}
						exit="collapsed"
						variants={variants}
						transition={{
							ease: [0.04, 0.62, 0.23, 0.98],
						}}
					>
						{children}
					</motion.section>
				</AnimatePresence>
			</div>
		</AccordionContext.Provider>
	);
};

export default Accordion;
