import type { FieldProps } from "formik";
import { getIn } from "formik";
import type { IconType } from "react-icons";
import { useState, useEffect, createElement, useContext } from "react";
import type { FC, FocusEvent, ChangeEvent } from "react";
import { FormFieldContext } from "./formRow/FormRow";
import { TranslationKey } from "../../types/modulesInterfaces";
import OutsideClickSub from "../outsideClickSub/OutsideClickSub";
import TranslatableText from "../translations/TranslatableText";

interface CustomInputProps {
	label: TranslationKey;
	type: "text" | "password" | "number" | "percentage";
	icon?: IconType;
	classes?: string;
	textend?: boolean;
	disabled?: boolean;
	handleIconClick?: () => void;
	placeholder?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput: FC<CustomInputProps & FieldProps> = ({
	label,
	field,
	form: { touched, errors },
	icon,
	type,
	classes,
	textend,
	disabled,
	handleIconClick,
	placeholder,
	onChange,
	...props
}) => {
	const error = getIn(errors, field.name);
	const wasTouched = getIn(touched, field.name);

	const { state, setState } = useContext(FormFieldContext);
	const [focused, setFocused] = useState<boolean>(false);

	useEffect(() => {
		const newState = focused
			? "focused"
			: wasTouched && error
				? "error"
				: field.value
					? "idleValue"
					: "idle";
		if (state !== newState) {
			setState(newState);
		}
	}, [error, wasTouched, focused, field.value, setState, state]);

	const handleFocus = () => setFocused(true);
	const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
		field.onBlur(e);
		setFocused(false);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		field.onChange(e);
		onChange?.(e);
	};

	const idleLable: boolean =
		state !== "focused" && !(field.value || field.value === 0);
	const textendCss =
		textend || ["number", "percentage"].includes(type) ? "text-end" : "";

	return (
		<OutsideClickSub
			className={`form-group ${state === "focused" ? "focused" : state === "error" ? "error" : ""
				} ${disabled ? "disabled" : ""} ${classes ?? ""}`}
			callback={() => setFocused(false)}
		>
			<>
				<input
					className={`input-login ff-medium ${textendCss}`}
					style={{ transform: idleLable ? "none" : "translateY(5px)" }}
					type={type}
					{...field}
					{...props}
					value={field.value || field.value === 0 ? field.value : ""}
					disabled={disabled}
					onFocus={handleFocus}
					onWheel={event => event.currentTarget.blur()}
					onBlur={handleBlur}
					onChange={handleChange}
					step={type === "percentage" ? ".01" : undefined}
					placeholder={placeholder}
					autoComplete={type === "text" && !field.name.includes("email") ? "off" : "on"}
				/>
				<label
					className={`form-group-label unclickable ${!idleLable && "small"} ${state === "error" && "error"
						}`}
				>
					<TranslatableText style={{ color: "var(--dark-gray)" }} translationKey={label} />
				</label>
				{icon ? (
					<button onClick={disabled ? () => { } : handleIconClick} type="button">
						{createElement(icon, {
							size: 24,
							color: "var(--dark-gray)",
							className: "input-icon",
						})}
					</button>
				) : null}
			</>
		</OutsideClickSub>
	);
};

export default CustomInput;
