import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useState, useMemo } from "react";
import GenericTierTwo from "../GenericTierTwo";
import GenericModule from "../GenericModule";

import {
	initialValues,
	validationSchema,
	t2InitialValues,
} from "./coastalWetlands";
import {
	useAppSelector,
	useSyncCommentGroupsWithStore,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import { BEModules } from "../../../../utils/moduleList";
import {
	useGetCoastalWetlandsQuery,
	useUpdateCoastalWetlandsMutation,
	useGetCoastalWetlandsDefaultsQuery,
} from "../../../../app/features/api/modules/coastalWetlandsApiSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import type { ModuleFull } from "./coastalWetlandsTypes";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import useCoastalWetlandsSchema from "./useCoastalWetlandsSchema";

const CoastalWetlands = () => {
	// const newNotes = useAppSelector(selectNewCurrentModuleNotes);
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: coastalData,
		isLoading,
		isError,
		error,
	} = useGetCoastalWetlandsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateCoastalWetlands,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateCoastalWetlandsMutation();
	const { data: defaults, error: defaultsError, isError: isDefaultsError } = useGetCoastalWetlandsDefaultsQuery(
		coastalData?.module.id ?? 0,
		{ skip: !coastalData?.module.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });


	const { moduleSchema, t2Schema } = useCoastalWetlandsSchema();
	const [initValues, setInitValues] = useState<ModuleFull>({
		module: initialValues,
		tiertwo: t2InitialValues,
	});
	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (coastalData)
			setInitValues({
				module: coastalData.module,
				tiertwo: { ...coastalData.tiertwo, ...defaults },
			});
	}, [coastalData, defaults]);

	useSyncCommentGroupsWithStore<ModuleFull>({
		T1Data: { module: coastalData?.module ?? initialValues },
		moduleSchema,
		isT1DataLoading: isLoading,
	});

	const { notes } = useSyncNotesWithStore({
		notes: coastalData?.module.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		try {
			const module = { ...values.module };
			if (values.module.id)
				await updateCoastalWetlands({
					data: { ...values, module },
					activityId: activeActivityId ?? 0,
				}).unwrap();
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			initialValues={initValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			enableReinitialize
		>
			<>
				<GenericModule
					title="Coastal Wetlands"
					t2Schema={t2Schema}
					moduleSchema={moduleSchema}
					isLoading={isLoadingUpdate}
					beModuleType={BEModules.CoastalWetlands}
					footerError={errorMsg}
					blockingError={blockingError}
					isError={isUpdateError}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<GenericTierTwo
						title="Coastal Wetlands"
						t2Schema={t2Schema}
						tabsOpen={tabsOpen}
						warningMessage={defaultsErrorMsg ?? null}
					/>
				) : null}
			</>
		</Formik>
	);
};

export default CoastalWetlands;
