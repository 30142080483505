import type { ReactNode } from "react";
import { useContext } from "react";
import { RxRocket, RxChatBubble, RxSun, RxCardStackPlus } from "react-icons/rx";
import { motion } from "framer-motion";
import Accordion, { AccordionContext } from "../accordion/Accordion";
import TranslatableText from "../translations/TranslatableText";

interface HelpBlock {
	label: string;
	description: string;
	image: ReactNode;
}

const HelpAction = () => {
	const helpBlocks: HelpBlock[] = [
		{
			label: "Getting started",
			description: "explore key features and possibilities",
			image: <RxRocket size={40} />,
		},
		{
			label: "Tutorials",
			description: "Learn the basics in a simple, interactive way",
			image: <RxChatBubble size={40} />,
		},
		{
			label: "Academy",
			description: "...",
			image: <RxSun size={40} />,
		},
		{
			label: "What's new",
			description: "Find out the latest product change",
			image: <RxCardStackPlus size={40} />,
		},
	];

	return (
		<section className="bg-gray w-100 h-100 d-flex f-column p-2">
			<p>
				<TranslatableText translationKey="module.tutorials_not_available"/>
			</p>
			{/* {helpBlocks.map((block, index) => {
				const header = (
					<HelpBlockHeader block={block} key={`${block.label}-${index}`} />
				);
				return (
					<Accordion header={header} key={block.label}>
						<div className="w-100 p-2 bg-white">Content</div>
					</Accordion>
				);
			})} */}
		</section>
	);
};

const HelpBlockHeader = ({ block }: { block: HelpBlock }) => {
	const { toggleOpen } = useContext(AccordionContext);
	return (
		<motion.div
			onClick={() => toggleOpen()}
			className="bg-white d-flex p-2 w-100 clickable"
		>
			{block.image}
			<div className="d-flex f-column ps-2">
				<h3>{block.label}</h3>
				<span>{block.description}</span>
			</div>
		</motion.div>
	);
};

export default HelpAction;
