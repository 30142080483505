import type { FormikHelpers } from "formik";
import type { BuildingPayload, ModuleFull, OtherPayload, RoadPayload } from "./settlementsTypes";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import {
  initialValues,
  validationSchema,
  getSchemas,
  t2InitialValues,
} from "./settlements";
import { Settlements as SettlementsNames } from "./settlementsTypes"
import { useAppSelector, useSyncCommentGroupsWithStore, useSyncNotesWithStore } from "../../../../app/hooks";
import { BEModules } from "../../../../utils/moduleList";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import { useGetBuildingsQuery, useGetOthersQuery, useGetRoadsQuery, useGetSettlementsDefaultsQuery, useGetSettlementsQuery, useUpdateBuildingsMutation, useUpdateOthersMutation, useUpdateRoadsMutation, useUpdateSettlementsMutation } from "../../../../app/features/api/modules/settlementsApislice";
import SettlementTierOne from "./SettlementTierOne";
import GenericTierTwo from "../GenericTierTwo";
import useErrorMessage from "../../../../utils/useErrorMessage";
import useModuleHook from "../useModuleHook";

const Settlements = () => {
  const { activeActivityId } = useAppSelector(selectCurrentProject);

  const { data: settlementData, isLoading, isError, error } = useGetSettlementsQuery({ activityId: activeActivityId ?? 0 });
  const [updateSettlements, { error: updateErrorSettlements, isError: isUpdateErrorSettlements, isLoading: isLoadingUpdateSettlements }] = useUpdateSettlementsMutation()
  const { data: roadsData, isLoading: isLoadingRoads, isError: isErrorRoads, error: errorRoads } = useGetRoadsQuery({ activityId: activeActivityId ?? 0 }, {
    refetchOnMountOrArgChange: true,
  })
  const { data: buildingsData, isLoading: isLoadingBuildings, isError: isErrorBuildings, error: errorBuildings } = useGetBuildingsQuery({ activityId: activeActivityId ?? 0 }, {
    refetchOnMountOrArgChange: true,
  })
  const [updateRoads, { error: updateErrorRoads, isError: isUpdateErrorRoads, isLoading: isLoadingUpdateRoads }] = useUpdateRoadsMutation()
  const [updateBuildings, { error: updateErrorBuildings, isError: isUpdateErrorBuildings, isLoading: isLoadingUpdateBuildings }] = useUpdateBuildingsMutation()

  const { data: othersData, isLoading: isLoadingOthers, isError: isErrorOthers, error: errorOthers } = useGetOthersQuery({ activityId: activeActivityId ?? 0 }, {
    refetchOnMountOrArgChange: true,
  })
  const [updateOthers, { error: updateErrorOthers, isError: isUpdateErrorOthers, isLoading: isLoadingUpdateOthers }] = useUpdateOthersMutation()
  const { data: parentDefaults } = useGetSettlementsDefaultsQuery(settlementData?.module.id ?? 0, { skip: !settlementData?.module.id })

  const { moduleSchema, t2Schema } = useMemo(() => getSchemas(), []);
  const [initValues, setInitValues] = useState<ModuleFull>({
    module: initialValues,
    tiertwo: t2InitialValues,
    roads: [],
    buildings: [],
    others: [],
  });

  const { tabsOpen, errorMsg } = useModuleHook({
    skeletonsLoadingDeps: [isLoading, isLoadingRoads, isLoadingBuildings, isLoadingUpdateBuildings, isLoadingUpdateRoads, isLoadingUpdateSettlements, isLoadingOthers, isLoadingUpdateOthers],
    isMutateError: isUpdateErrorSettlements || isUpdateErrorRoads || isUpdateErrorBuildings || isUpdateErrorOthers,
    mutateError: updateErrorSettlements ?? updateErrorRoads ?? updateErrorBuildings ?? updateErrorOthers,
  });
  const { errorMsg: blockingError } = useErrorMessage({ isError, error });
  const { errorMsg: blockingErrorRoads } = useErrorMessage({ isError: isErrorRoads, error: errorRoads });
  const { errorMsg: blockingErrorBuildings } = useErrorMessage({ isError: isErrorBuildings, error: errorBuildings });
  const { errorMsg: blockingErrorOthers } = useErrorMessage({ isError: isErrorOthers, error: errorOthers });

  useSyncCommentGroupsWithStore<ModuleFull>({
    T1Data: { roads: roadsData ?? [], module: settlementData?.module ?? initialValues, buildings: buildingsData ?? [], others: othersData ?? [] },
    moduleSchema,
    isT1DataLoading: isLoading,
    variableSubmodules: [
      {
        data: roadsData ?? [],
        schema: moduleSchema.optionalSubmodules?.filter((op) => op.name === SettlementsNames.Roads)[0] ?? null,
      },
      {
        data: buildingsData ?? [],
        schema: moduleSchema.optionalSubmodules?.filter((op) => op.name === SettlementsNames.Buildings)[0] ?? null,
      },
      {
        data: othersData ?? [],
        schema: moduleSchema.optionalSubmodules?.filter((op) => op.name === SettlementsNames.Others)[0] ?? null,
      }
    ]
  });

  const { notes } = useSyncNotesWithStore({
    notes: settlementData?.module.note ?? null,
  })

  useEffect(() => {
    if (settlementData) setInitValues((cur) => ({ ...cur, module: settlementData.module, tiertwo: { ...settlementData.tiertwo, ...parentDefaults } }));
  }, [settlementData, parentDefaults]);

  const handleSubmit = async (
    values: ModuleFull,
    { resetForm }: FormikHelpers<ModuleFull>
  ) => {
    try {
      const promises = [];

      // Update settlements
      if (values.module.id) promises.push(updateSettlements({ data: values, activityId: activeActivityId ?? 0 }));


      // Update roads
      for (const dist of values.roads) {
        const roadPayload: RoadPayload = {
          body: dist,
          activityId: activeActivityId ?? 0,
        };

        if (dist.id) promises.push(updateRoads(roadPayload));

      }

      // Update buildings
      for (const dist of values.buildings) {
        const buildingPayload: BuildingPayload = {
          body: dist,
          activityId: activeActivityId ?? 0,
        };

        if (dist.id) promises.push(updateBuildings(buildingPayload));

      }

      // Update others
      for (const dist of values.others) {
        const otherPayload: OtherPayload = {
          body: dist,
          activityId: activeActivityId ?? 0,
        };

        if (dist.id) promises.push(updateOthers(otherPayload));
      }

      await Promise.all(promises);
      resetForm({ values });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <SettlementTierOne
          title="Settlements and infrastructure"
          initValues={initValues}
          t2Schema={t2Schema}
          beModuleType={BEModules.Settlements}
          moduleSchema={moduleSchema}
          footerError={errorMsg}
          blockingError={blockingError ?? blockingErrorRoads ?? blockingErrorBuildings ?? blockingErrorOthers}
          isError={isUpdateErrorBuildings || isUpdateErrorRoads || isUpdateErrorOthers || isUpdateErrorSettlements}
          setInitValues={setInitValues}
          note={notes?.content ?? null}
        />
        <GenericTierTwo
          title="Settlements and infrastructure"
          t2Schema={t2Schema}
          tabsOpen={tabsOpen}
        />
      </>
    </Formik>
  );
};

export default Settlements;
