import { IconType } from "react-icons";
import Button from "../button/Button";
import { BiPlusCircle } from "react-icons/bi";
import { createElement } from "react";
import { TranslationKey } from "../../types/modulesInterfaces";
import { useCustomTranslation } from "../../app/hooks";

const AddAccordionButton = ({
	disabled,
	label,
	clickHandler,
	icon,
}: {
	disabled?: boolean;
	label: string;
	clickHandler: () => void;
	icon?: IconType;
}) => {
	return (
		<div
			className="pt-2 pb-1 mt-1"
			style={{ borderTop: "1px dashed rgba(99, 92, 89, 0.5)" }}
		>
			<Button
				classes={`btn-add-accordion d-flex align-items-center ${
					disabled ? "op-8" : ""
				}`}
				onClick={clickHandler}
				disabled={disabled}
			>
				<div className="h-100 d-flex align-items-center btn-arrow bg-prim-de p-1">
					{icon ? (
						createElement(icon, { size: 20, color: "var(--white)" })
					) : (
						<BiPlusCircle size={20} color="var(--white)" />
					)}
				</div>
				<span className="h-100 fs-11 ff-medium d-flex align-items-center justify-content-center p-1 ">
					{label}
				</span>
			</Button>
		</div>
	);
};

export default AddAccordionButton;

type AddAccordionButtonProps = React.ComponentProps<typeof AddAccordionButton> & {
	label: TranslationKey | "";
};
const withTranslation =(
	Component: typeof AddAccordionButton
) => {
	return (props: AddAccordionButtonProps) => {
    const translatedLabel = useCustomTranslation(props.label);
	return <Component {...(props)} label={`${translatedLabel ?? props.label}`} />;
	};
};

export const GenericAddAccordionButton = withTranslation(AddAccordionButton);

export const TranslatedAddAccordionButton = withTranslation(AddAccordionButton);
