import {
	includedPropList,
	includedT2PropList,
	minorSeasonPropsList,
} from "../../../../pages/activityBuilder/modules/annualCropland/annualCropland";
import type {
	AnnualCroplandPayload,
	AnnualCroplandResponse,
	UpdateAnnualCroplandPayload,
	MinorSeason,
	MinorSeasonT2,
	ModuleFull,
	AnnualCroplandT2,
	AnnualCroplandDefaults,
} from "../../../../pages/activityBuilder/modules/annualCropland/annualCroplandTypes";
import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAnnualCroplands: builder.query<ModuleFull | null, number>({
			query: (activityId) => `annual-croplands/?activity=${activityId}`,
			transformResponse: (response: AnnualCroplandResponse[]) => {
				if (response && response.length > 0) {
					const moduleMinorSeasonData = filterObjectProps(
						response[0],
						minorSeasonPropsList,
					) as MinorSeason;

					const t2MinorSeasonData = filterObjectProps(response[0], [
						"minor_biomass_factor_t2_start",
						"minor_biomass_factor_t2_w",
						"minor_biomass_factor_t2_wo",
					]) as MinorSeasonT2;

					const module = filterObjectProps(
						{ ...response[0], minorSeason: moduleMinorSeasonData },
						includedPropList,
					) as AnnualCroplandPayload;

					const t2 = filterObjectProps(
						{ ...response[0], minorSeason: t2MinorSeasonData },
						includedT2PropList,
					) as AnnualCroplandT2;

					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "AnnualCropland", id: activityId },
			],
		}),
		updateAnnualCroplands: builder.mutation<
			AnnualCroplandResponse,
			UpdateAnnualCroplandPayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module, ...data.module.minorSeason }; //there's max 1 minorSeason
				const t2 = { ...data.tiertwo, ...data.tiertwo.minorSeason }; //there's max 1 minorSeason
				const lucId = data.module.land_use_change?.id;
				const payload = {
					...module,
					...t2,
				} as unknown as AnnualCroplandResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };
				convertEmptyStringsToNull(body);
				return { url: `annual-croplands/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "AnnualCropland", id: results?.activity.id },
				{ type: "ModuleResults", id: results?.id ? `annual-croplands-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `annual-croplands-${results?.id}` : undefined },
				{ type: "AnnualCroplandDefaults", id: results?.id },
				{ type: "ModulesStatuses", id: results?.activity.id }
			],
		}),
		createAnnualCroplands: builder.mutation<
			AnnualCroplandResponse,
			UpdateAnnualCroplandPayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module, ...data.module.minorSeason }; //there's max 1 minorSeason
				const t2 = { ...data.tiertwo, ...data.tiertwo.minorSeason }; //there's max 1 minorSeason
				const lucId = data.module.land_use_change?.id;

				const payload = {
					...module,
					...t2,
				} as unknown as AnnualCroplandResponse;
				const body = {
					...payload,
					activity: activityId,
					land_use_change: lucId,
				};
				return { url: "annual-croplands/", method: "POST", body };
			},
			invalidatesTags: (results, _error, data) => [
				{ type: "AnnualCropland", id: results?.activity.id },
			],
		}),
		getAnnualCroplandsDefaults: builder.query<AnnualCroplandDefaults, number>({
			query: (uniqueId) => `annual-croplands/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [
				{ type: "AnnualCroplandDefaults", id: uniqueId },
			],
		})
	}),
});

export const {
	useGetAnnualCroplandsQuery,
	useUpdateAnnualCroplandsMutation,
	useCreateAnnualCroplandsMutation,
	useGetAnnualCroplandsDefaultsQuery
} = moduleApiSlice;
