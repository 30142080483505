import { apiSlice } from "../api/apiSlice";
import type { BEModules } from "../../../utils/moduleList";
import type { DefinitionEntry, DefinitionResponse, DefinitionsData } from "./definitionsTypes";

const convertDefinitionsToArray = (definitions: { [key: string]: string }): DefinitionEntry[] => {
 return Object.entries(definitions).map(([key, value]) => ({
  name: key,
  definition: value
 }));
};

export const activitiesApiSlice = apiSlice.injectEndpoints({
 endpoints: (builder) => ({
  getModuleDefinitions: builder.query<DefinitionsData | null, BEModules | null>({
   query: (module) => `definitions/?module_type_id=${module}`,
   transformResponse: (response: DefinitionResponse[]) => {
    return response.length > 0 ? {
     ...response[0],
     definitions: convertDefinitionsToArray(response[0].definitions)
    } : null
   }
  })
 }),
})

export const {
 useGetModuleDefinitionsQuery,
} = activitiesApiSlice
