import type {
	TopbarStep,
	ModuleStatus,
} from "../../types/interfaces";
import { RiArrowRightFill } from "react-icons/ri";
import { BiRightArrow } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { FEModules, activityCompleteInfo } from "../../utils/moduleList";
import { GrayCheck } from "../../utils/customIcons";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentProject } from "../../app/features/project/projectSlice";
import { useGetModulesStatuesQuery } from "../../app/features/activities/activityApiSlice";
import Button from "../button/Button";
import TranslatableText from "../translations/TranslatableText";
import BaseLabel from "../label/BaseLabel";

interface TopbarProps {
	projectLabel?: string;
	activityLabel?: string;
	stepsList: TopbarStep[];
}

const BuilderTopbar = (props: TopbarProps) => {
	return (
		<div className="builder-top">
			<TopbarSteps {...props} />
		</div>
	);
};

const TopbarSteps = ({
	projectLabel,
	activityLabel,
	stepsList,
}: TopbarProps) => {
	const pathList = useLocation().pathname.split("/");
	const isInBuilder = pathList[1] === "builder";
	const finalPath = pathList?.pop();

	const { activeActivityId, project } = useAppSelector(selectCurrentProject);
	const { data: moduleStatuses } = useGetModulesStatuesQuery(
		activeActivityId ?? 0,
		{ skip: !activeActivityId },
	);

	return (
		<div className="steps-container fs-13">
			<div className="steps-labels">
				{activityLabel ? (
					<Link
						to={`/project/${project?.id ?? "new"}/activities`}
						className="no-decoration"
					>
						<Button
							whileHoverProps={{ backgroundColor: "var(--gray)" }}
							classes="me-1 p-1 ff-normal-ext fs-12 lh-90 br-8"
						>
							{activityLabel}
						</Button>
					</Link>
				) : null}
				{projectLabel ? (
					<>
						<div className="d-flex align-items-end">
							<span
								className="fs-13 ff-bold-ext project-label">
								{projectLabel}
							</span>
						</div>
						{stepsList.length ? (
							<div className="mx-1">
								<BiRightArrow size={24} />
							</div>
						) : null}
					</>
				) : null}
			</div>
			{(isInBuilder ? [...stepsList, activityCompleteInfo] : stepsList).map(
				(step, index) => {
					const hasNext = isInBuilder
						? index < stepsList.length
						: index < stepsList.length - 1;
					const isActive = finalPath === step.path;
					return (
						<Link to={`../${step.path}`} relative="path" className="no-decoration" key={step.id}>
							<div className="d-flex h-100 align-items-center" key={step.id}>
								<TopbarStepIcon
									stepId={step.id}
									isActive={isActive}
									index={index}
									status={
										moduleStatuses?.find(
											(status) => status.feModuleId === step.id,
										)?.status
									}
								/>
								<BaseLabel label={step.name} className={`capitalize step-label ${isActive ? "ff-medium-ext" : "ff-normal-ext"}`} />
								{hasNext ? (
									<div className="ms-1">
										<RiArrowRightFill size={20} />
									</div>
								) : null}
							</div>
						</Link>
					);
				},
			)}
		</div>
	);
};

const TopbarStepIcon = ({
	stepId,
	isActive,
	index,
	status,
}: {
	stepId: number;
	isActive: boolean;
	index: number;
	status?: ModuleStatus;
}) => {
	const location = useLocation();
	const isInBuilder = location.pathname.startsWith("/builder");

	const isNotFirst = stepId !== 0;
	const buttonClasses = `${isNotFirst && "ms-2"} ${isActive && "step-number-active"
		} ${status?.name === "READY" && isInBuilder
			? "step-number-done"
			: /* : isActive
				? "step-number-touched" */
			""
		} step-number ff-bold`;

	return (
		<div>
			{stepId === FEModules.ActivityComplete ? (
				<GrayCheck className="ms-2 me-1" />
			) : (
				<Button classes={buttonClasses}>{index + 1}</Button>
			)}
		</div>
	);
};

export default BuilderTopbar;