import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
	selectCurrentBuilder,
	setModuleList,
} from "../../app/features/builder/builderSlice";
import {
	selectCurrentProject,
	setActivity,
	setProject,
} from "../../app/features/project/projectSlice";
import { Outlet, useParams } from "react-router-dom";
import { Actions } from "../../types/interfaces";

import BuilderActions from "../../components/builderActions/BuilderActions";
import BuilderActionsTabs from "../../components/builderActions/BuilderActionsTabs";
import Sidebar from "../../components/sidebar/Sidebar";
import BuilderTopbar from "../../components/topbar/BuilderTopbar";
import BuilderMain from "../../components/main/BuilderMain";
import BuilderMainTabs from "../../components/main/BuilderMainTabs";
import { useEffect, useMemo } from "react";
import {
	useGetActivitiesByIdQuery,
	useGetModulesByActivityIdQuery,
} from "../../app/features/activities/activityApiSlice";
import { getFEModuleListFromActivity } from "../../utils/moduleList";

const ActivityBuilder = () => {
	const {
		activeAction: activeTabId,
		moduleList,
		tabsOpen,
	} = useAppSelector(selectCurrentBuilder);
	const { activity, project } = useAppSelector(selectCurrentProject);

	const actionsGridClass = !tabsOpen
		? "actions-close"
		: [
					Actions.Comments,
					Actions.Help,
					Actions.Changes,
					Actions.Definitions,
				].includes(activeTabId)
			? "actions-small"
			: "";

	const dispatch = useAppDispatch();

	const { activityId } = useParams<string>();
	const { data: activityData } = useGetActivitiesByIdQuery(
		Number(activityId) ?? 0,
		{ skip: activityId === activity?.id },
	);

	const id = useMemo(() => Number(activityId), [activityId]);



	useEffect(() => {
		if (id && activityData && id !== activity?.id) {
			const feModuleList = getFEModuleListFromActivity(activityData);
			dispatch(setActivity(activityData));
			dispatch(setModuleList(feModuleList));
		}
	}, [activity, activityData, id, dispatch]);

	useEffect(() => {
		if (activityData?.project && project?.id !== activityData.project.id) {
			dispatch(setProject(activityData.project));
		}
	}, [project, dispatch, activityData]);

	return (
		<motion.div className={`builder-container ${actionsGridClass}`}>
			<Sidebar />
			<BuilderTopbar
				activityLabel={`Activity ${activity?.id}.`}
				projectLabel={activity?.name}
				stepsList={moduleList}
			/>
			<BuilderActionsTabs />
			<BuilderMainTabs />
			<BuilderMain>
				{activity && project ? (
					<Outlet />
				) : (
					<div className="w-100 h-100 d-flex justify-content-center align-items-center">
						<p>Something went wrong</p>
					</div>
				)}
			</BuilderMain>
			<BuilderActions />
		</motion.div>
	);
	/* (
			<Navigate
				to={`/project/${project?.id}/activities`}
				state={{ from: location }}
				replace
			/>
		); */
};

export default ActivityBuilder;
