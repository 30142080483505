import {
	clearProgressiveModules,
	selectCurrentModules,
} from "../../../app/features/setupAssistant/setupAssistantSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import MapIframe from "../../../pages/project/map/MapIframe";
import ClimateIdentifier from "./ClimateIdentifier";
import TranslatableText from "../../translations/TranslatableText";

const MapAction = ({ isActivity }: { isActivity: boolean }) => {
	const dispatch = useAppDispatch();
	const modules = useAppSelector(selectCurrentModules); //these are the progressive modules
	const location = useLocation();

	useEffect(() => {
		if (!location.pathname.endsWith("new-activity") && modules.length)
			dispatch(clearProgressiveModules());
	}, [location.pathname]);

	return (
		<section className="w-100 h-100 py-2 px-3 bg-gray">
			<div className="pos-relative mb-2 pb-1">
				<h2 className="fs-14 ff-medium-ext module-header w-fit">
					<TranslatableText translationKey="main.project_map"/>
				</h2>
			</div>

			<div style={{ position: "relative", zIndex: 1 }}>
				<MapIframe />
				<div
					style={{
						backgroundColor: "#e8e8e8",
						color: "#e8e8e8",
						width: "100%",
						paddingTop: 10,
						border: "none",
						position: "absolute",
						bottom: -9,
						zIndex: 10000,
					}}
				>
					Bye leaflet
				</div>
			</div>

			<div className="w-50">
				<ClimateIdentifier />
			</div>
		</section>
	);
};

export default MapAction;
