import type {
	StartWithWithoutGeneric,
	DropdownInputGroup,
	Submodule,
	StartWithWithoutTierTwo,
	DropdownInputGroupTierTwo,
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import * as Yup from "yup";
import type { EnergiesPayload, EnergiesT2Payload, ModuleFull } from "./energyTypes";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import { validatePercentage, validatePositiveNumber } from "../moduleUtils";


export const includedPropList = [
	"id",
	"note",
	"activity",
];
export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
]
export const validationSchema = Yup.object({
	electricities: Yup.array().of(
		Yup.object().shape({
			country: validatePositiveNumber
				.when(["mwh_start", "mwh_w", "mwh_wo"], {
					is: (mwh_start: number, mwh_w: number, mwh_wo: number) =>
						!!mwh_start || !!mwh_w || !!mwh_wo,
					// biome-ignore lint/suspicious/noThenProperty: <explanation>
					then: validatePositiveNumber
						.required('validations.power_country_required'),
				}),
			mwh_renewables: validatePositiveNumber,
			transmission_loss_start: validatePercentage,
			transmission_loss_w: validatePercentage,
			transmission_loss_wo: validatePercentage,
		}),
	),
	fuels: Yup.array().of(
		Yup.object().shape({
			/* fuel_macro_type: validatePositiveNumber
				.required("Fuel Macro Type is required"), */
			fuel_type: validatePositiveNumber.required('validations.fuel_type_required'),
			fuel_consumption_start: validatePositiveNumber
				.required('validations.fuel_consumption_required'),
			fuel_consumption_w: validatePositiveNumber
				.required('validations.fuel_consumption_required'),
			fuel_consumption_wo: validatePositiveNumber
				.required('validations.fuel_consumption_required'),
		}),
	),
});


export const energiesInitialValues: EnergiesPayload = {
	id: null,
	note: null,
};
export const energiesT2InitialValues: EnergiesT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
}
export const initialValues: ModuleFull = {
	electricities: [],
	fuels: [],
	parent: energiesInitialValues,
	tiertwo: energiesT2InitialValues,
};

const countries = () => getOptions("country");
const fuelMacroTypes = () => getOptions("macroFuelTypes");
const emissionFactorSources = () => getOptions("emissionFactorSources");

const getElectricitySchema: () => Submodule = () => {
	const powerCountryOfOrigin: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.power_country_of_origin",
		inputName: (index: number) => `electricities.${index}.country`,
		dropdownOptions: countries,
	};
	const electricityGrid: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.electricity_grid",
		unit: "[MWh/yr]",
		inputName: (index: number) => `electricities.${index}.mwh`,
	};
	const electricityRenewable: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.electricity_renewable",
		unit: "[MWh/yr]",
		inputName: (index: number) => `electricities.${index}.mwh_renewables`,
	};

	return {
		name: "module.electricity_optional",
		inputGroups: [powerCountryOfOrigin, electricityGrid, electricityRenewable],
	};
};

const getFuelSchema: () => Submodule = () => {
	const fuelDivisions: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.fuel_divisions",
		inputName: (index: number) => `fuels.${index}.fuel_macro_type`,
		dropdownOptions: fuelMacroTypes,
	};
	const typeOfFuel: DropdownInputGroup = {
		type: FieldType.SELECT,
		label: "module.type_of_fuel_required",
		inputName: (index: number) => `fuels.${index}.fuel_type`,
		dropdownOptions: [],
	};
	const fuelConsumption: StartWithWithoutGeneric = {
		type: FieldType.SWW,
		label: "module.fuel_consumption_required",
		inputName: (index: number) => `fuels.${index}.fuel_consumption`,
	};

	return {
		name: "module.fuel_usage_optional",
		inputGroups: [fuelDivisions, typeOfFuel, fuelConsumption],
	};
};

const getT2ElectricitySchema: () => Submodule = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `electricities.${index}.ef_t2`,
			label: "module.ef_selected_country",
			unit: "[tCO2/MWh]",
			links: ["Power Country of Origin"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: (index: number) => `electricities.${index}.transmission_loss`,
			label: "module.transmission_loss",
			unit: "[%]",
			links: [],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	const scopeOfEmissionFactor: DropdownInputGroupTierTwo = {
		type: FieldType.SELECT_T2,
		inputName: (index: number) => `electricities.${index}.ef_source`,
		label: "module.scope_of_emission_factor",
		links: [],
		unit: "",
		defaultValue: "value",
		dropdownOptions: emissionFactorSources,
	};

	return {
		name: "module.electricity_optional",
		inputGroups: [scopeOfEmissionFactor, ...rows],
	};
};

export const getSchemas = () => {
	const electricitySchema = getElectricitySchema();
	const fuelSchema = getFuelSchema();

	const electricityT2Schema = getT2ElectricitySchema();

	return {
		electricitySchema,
		fuelSchema,
		electricityT2Schema,
	};
};