import type { ReactNode } from "react";
import { useMemo } from "react";
import { useState } from "react";
import Button from "../../button/Button";
import NoteTextEditor from "./NoteTextEditor";

import {
	BiPlusCircle,
	BiEditAlt,
	BiTrash,
	BiXCircle,
	BiSave,
} from "react-icons/bi";
import { NoteGrayIcon } from "../../../utils/customIcons";
import type { IconType } from "react-icons";
import { useAppDispatch, useAppSelector, usePermissions } from "../../../app/hooks";
import {
	selectCurrentBuilder,
	selectCurrentModuleNotes,
	setCurrentModuleNotes,
	setNewCurrentModuleNotes,
} from "../../../app/features/builder/builderSlice";
import NoteTextViewer from "./NoteTextViewer";
import { ClickableIcon } from "../comments/CommentsAction";
import KebabMenu from "../../kebabMenu/KebabMenu";
import { useCreateModuleNotesMutation, useDeleteModuleNotesMutation, useUpdateModuleNotesMutation } from "../../../app/features/api/modules/genericOperationsApiSlice";
import { useLocation } from "react-router-dom";
import useErrorMessage from "../../../utils/useErrorMessage";
import { FEModulesObj, getModulefromModulePathName } from "../../../utils/moduleList";
import { TranslationKey } from "../../../types/modulesInterfaces";
import TranslatableText from "../../translations/TranslatableText";

interface Line {
	id: number;
	label: TranslationKey;
	icon: IconType;
	clickHandler: () => void;
}

const extractIdAndPathName = (pathname: string) => {
	const pathParts = pathname.split("/").filter((part) => part); // Split by '/' and filter out empty parts
	if (pathParts.length >= 3 && pathParts[0] === "builder") {
		const activityId = +pathParts[1];
		const pathName = pathParts[2];
		return { activityId, pathName };
	}
	return { activityId: null, pathName: null };
};

// const notes: Note[] = [
//  {
//   id: "17",
//   user: "Bozhidara Dimitar",
//   content:
//    "efficitur in dui vitae, luctus efficitur mauris. Vivamus interdum pulvinar diam ut eleifend. Aenean rhoncus rhoncus dui ut molestie. ",
//  },
// ];

const NotesAction = () => {
	const [editMode, setEditMode] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const notes = useAppSelector(selectCurrentModuleNotes);
	const { moduleList, isFetchingData } = useAppSelector(selectCurrentBuilder);

	const [updateNotes, { isLoading, isError, error }] =
		useUpdateModuleNotesMutation();
	
	const [createNotes, { isLoading: isCreateLoading, isError: isCreateError, error: createError }] = useCreateModuleNotesMutation();	
	const [deleteNotes, { isLoading: isDeleteLoading, isError: isDeleteError, error: deleteError }] = useDeleteModuleNotesMutation();

	const { errorMsg } = useErrorMessage({ isError, error });

	const location = useLocation();
	const { canMutateNote } = usePermissions();
	
	const { activityId, pathName } = useMemo(
		() => extractIdAndPathName(location.pathname),
		[location],
	);
	const moduleId = useMemo(() => {
		const currentModule = moduleList.find((step) => step.path === pathName);
		return currentModule?.uniqueId ?? undefined;
	}, [moduleList, pathName]);
	
	//TODO: add error handling

	const handleSaveNotes = async (newNote: string) => {
		if (notes?.id && newNote) {
			const res = await updateNotes({
				id: notes.id,
				content: newNote,
			}).unwrap();
			if (res) {
				dispatch(setCurrentModuleNotes(res));
				dispatch(setNewCurrentModuleNotes(res.content));
				setEditMode(false);
			}
		} else {
			if (!pathName) return;
			const module_type_id = getModulefromModulePathName(pathName)?.beModuleId;
			if (moduleId && module_type_id) {
				const res = await createNotes({
					content: newNote ?? "",
					module_id: moduleId,
					module_type_id
				}).unwrap();
				if (res) {
					dispatch(setCurrentModuleNotes(res));
					dispatch(setNewCurrentModuleNotes(res.content));
					setEditMode(false);
				}
			} 			
		}
	};

	const handleDeleteNote = async () => {
		if (notes?.id) {
			deleteNotes({
				id: notes?.id
			})
			dispatch(setNewCurrentModuleNotes(null));
			dispatch(setCurrentModuleNotes(null));
			setEditMode(false);
		}
	};

	return (
		<section className="notes-action-container">
			{isFetchingData || isLoading ? (
				<div>
					<p>Loading Data...</p>
				</div>
			) : (
				<div className="selected-note-block">
					{!notes && canMutateNote && (
						<Button
							classes="standard-action-button"
							onClick={() => setEditMode(true)}
						>
							<BiPlusCircle size={20} color="" />
							<TranslatableText className="fs-12 ps-1 ff-normal-cond" translationKey="module.add_note"/>
						</Button>
					)}
					{!notes && !canMutateNote && (
						<TranslatableText className="fs-12 text-darker-gray" translationKey="module.not_allowed_add_notes"/>
					)}
					{!editMode && notes ? (
						<>
							<NoteDisplayBlock>
								{
									canMutateNote ? (
									<div className="pos-relative">
										<Modal
											handleEdit={() => setEditMode(true)}
											handleDelete={handleDeleteNote}
										/>
									</div>
									) : null
								}
							</NoteDisplayBlock>
							<div className="note-text-editor-container">
								<span className="fs-12 text-darker-gray">
									<NoteTextViewer note={notes.content} />
								</span>
							</div>
						</>
					) : null}
					{editMode && (
						<>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									alignSelf: "flex-end",
								}}
							>
								<ClickableIcon clickHandler={() => setEditMode(!editMode)}>
									<BiXCircle size={20} color="" />
								</ClickableIcon>
							</div>
							<NoteTextEditor notes={notes?.content ?? null } isLoading={isLoading} onSave={handleSaveNotes} errorMessage={isError && errorMsg ? errorMsg : ""} />
						</>
					)}
				</div>
			)}
		</section>
	);
};
const NoteDisplayBlock = ({
	children,
}: {
	children: ReactNode;
}) => {
	return (
		<div className="d-flex justify-content-between pb-2">
			<div className="d-flex align-items-end" style={{ paddingLeft: "3px" }}>
				<NoteGrayIcon />
				<strong className="ff-bold fs-13 ps-1 text-darker-gray">
					<TranslatableText translationKey="module.note"/>
				</strong>
			</div>
			{children}
		</div>
	);
};

const Modal = ({
	handleEdit,
	handleDelete,
	//  note,
}: {
	handleEdit: () => void;
	handleDelete: () => void;
	//  note: Note;
}) => {
	const lines: Line[] = [
		{
			id: 0,
			label: "main.edit",
			icon: BiEditAlt,
			clickHandler: handleEdit,
		},
		/* {
			id: 1,
			label: "Resolve",
			icon: BiCheckCircle,
			clickHandler: () => console.log("resolve clicked"),
		}, */
		{
			id: 2,
			label: "main.delete",
			icon: BiTrash,
			clickHandler: handleDelete,
		},
	];

	return (
		<div
			style={{
				zIndex: 10,
				borderRadius: 5,
			}}
		>
			<KebabMenu
				lines={lines}
				activeColor="var(--tertiary-deep)"
				buttonClasses={""}
				position="left"
			/>
		</div>
	);
};

export default NotesAction;
