import type {
	StartWithWithoutGeneric,
	InputGroup,
	DropdownInputGroup,
	T2Schema,
	ModuleSchema,
	StartWithWithoutTierTwo,
	Submodule,
	HectaresInputGroup
} from "../../../../types/modulesInterfaces";
import { FieldType } from "../../../../types/modulesInterfaces";
import type { WaterbodiesPayload, WaterbodiesT2Payload } from "./waterbodiesTypes";
import * as Yup from "yup";
import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";

export const includedPropList = [
	"id",
	"area",
	"waterbody_type",
	"trophic_type_start",
	"trophic_type_w",
	"trophic_type_wo",
	"land_use_change",
	"note",
];
export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"ch4_ef_t2_start",
	"ch4_ef_t2_w",
	"ch4_ef_t2_wo",
	"alpha_t2_start",
	"alpha_t2_w",
	"alpha_t2_wo",
	"mean_annual_t2_start",
	"mean_annual_t2_w",
	"mean_annual_t2_wo",
];
export const validationSchema = Yup.object({});

const waterbodiesTypes = () => getOptions("waterbodiesTypes");
const trophicTypes = () => getOptions("trophicTypes");

export const initialValues: WaterbodiesPayload = {
	area: null,
	waterbody_type: null,
	trophic_type_start: null,
	trophic_type_w: null,
	trophic_type_wo: null,

	id: null,
	land_use_change: null,
	note: null,
};

const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "module.area",
		disabled: true,
	};
	const typeOfWaterbody: DropdownInputGroup = {
		type: FieldType.SELECT,
		inputName: "module.waterbody_type",
		label: "module.type_of_waterbody",
		dropdownOptions: waterbodiesTypes,
	};
	const trophicClass: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		inputName: "module.trophic_type",
		label: "module.trophic_class",
		startProps: { dropdownOptions: trophicTypes },
		withProps: { dropdownOptions: trophicTypes },
		withoutProps: { dropdownOptions: trophicTypes },
	};

	return [hectaresInputGroup, typeOfWaterbody, trophicClass];
};

/* T2 */
export const t2InitialValues: WaterbodiesT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	ch4_ef_t2_start: null,
	ch4_ef_t2_w: null,
	ch4_ef_t2_wo: null,
	alpha_t2_start: null,
	alpha_t2_w: null,
	alpha_t2_wo: null,
	mean_annual_t2_start: null,
	mean_annual_t2_w: null,
	mean_annual_t2_wo: null,
};

const getT2Submodules: () => Submodule[] = () => {
	const rows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.ch4_ef_t2",
			label: "module.ch4_ef",
			unit: "[kgC/ha/yr]",
			links: ["Type of Waterbody"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.alpha_t2",
			label: "α",
			unit: "",
			links: ["Throphic class through Chlo.a"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.mean_annual_t2",
			label: "module.mean_annual_chlo_a",
			unit: "[μg/L]",
			links: ["Throphic class"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	return [
		{
			name: "",
			inputGroups: [...rows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: [],
		optionalSubmodules: [],
	};

	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
