import type {
	IrrigationPhasePayload,
	IrrigationSystemPayload,
	ModuleFull,
} from "./irrigationTypes";
import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { initialValues, validationSchema, getSchemas } from "./irrigation";
import {
	useAppSelector,
	useSyncCommentGroupsWithStore,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import {
	useUpdateIrrigationSystemMutation,
	useUpdateIrrigationPhaseMutation,
	useGetIrrigationPhaseQuery,
	useGetIrrigationSystemQuery,
	useGetIrrigationsQuery,
	useUpdateIrrigationsMutation,
} from "../../../../app/features/api/modules/irrigationApiSlice";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import useModuleHook from "../useModuleHook";
import useErrorMessage from "../../../../utils/useErrorMessage";
import IrrigationTierOne from "./IrrigationTierOne";
import IrrigationTierTwo from "./IrrigationTierTwo";
import { BEModules } from "../../../../utils/moduleList";

const Irrigation = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: irrigationsData,
		isLoading: isIrrigationsLoading,
		error: isIrrigationsError,
	} = useGetIrrigationsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	const [
		updateIrrigations,
		{
			error: updateIrrigationsError,
			isError: isIrrigationsErros,
			isLoading: isLoadingIrrigations,
		},
	] = useUpdateIrrigationsMutation();

	const {
		data: irrigationSysData,
		isLoading: isSysLoading,
		isError: isSysError,
		error: sysError,
	} = useGetIrrigationSystemQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const {
		data: operationPhasesData,
		isLoading: isOpLoading,
		isError: isOpError,
		error: opError,
	} = useGetIrrigationPhaseQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	const [
		updateIrrigationSystem,
		{
			isLoading: isLoadingUpdateSystem,
			isError: isUpdateSystemError,
			error: updateSystemError,
		},
	] = useUpdateIrrigationSystemMutation();
	const [
		updateIrrigationPhase,
		{
			isLoading: isLoadingUpdatePhase,
			isError: isUpdatePhaseError,
			error: updatePhaseError,
		},
	] = useUpdateIrrigationPhaseMutation();

	const {
		irrigationSchema,
		operationPhaseSchema,
		t2IrrigationSchema,
		t2OperationPhaseSchema,
	} = useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>(initialValues);

	const { errorMsg, tabsOpen } = useModuleHook({
		skeletonsLoadingDeps: [isLoadingUpdateSystem, isLoadingUpdatePhase],
		isMutateError: isUpdateSystemError || isUpdatePhaseError,
		mutateError: updateSystemError ?? updatePhaseError,
	});
	const { errorMsg: blockingSystemError } = useErrorMessage({
		isError: isSysError,
		error: sysError,
	});
	const { errorMsg: blockingPhaseError } = useErrorMessage({
		isError: isOpError,
		error: opError,
	});

	useEffect(() => {
		if (irrigationsData)
			setInitValues((cur) => ({
				...cur,
				parent: irrigationsData.parent,
				tiertwo: irrigationsData.tiertwo,
			}));
	}, [irrigationsData]);

	useSyncCommentGroupsWithStore<ModuleFull>({
		T1Data: {
			irrigations: irrigationSysData ?? [],
			irrigationPhases: operationPhasesData ?? [],
			parent: irrigationsData?.parent ?? initValues.parent,
		},
		moduleSchema: null,
		isT1DataLoading: isSysLoading || isOpLoading,
		variableSubmodules: [
			{
				data: irrigationSysData ?? [],
				schema: irrigationSchema,
			},
			{
				data: operationPhasesData ?? [],
				schema: operationPhaseSchema,
			},
		],
	});
	const { notes } = useSyncNotesWithStore({
		notes: initValues?.parent?.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		//update parent
		const parent = { ...values.parent, notes };
		const payload = {
			body: { ...values.tiertwo, ...parent },
			activityId: activeActivityId ?? 0,
		};
		const parentPromise = values.parent.id
			? updateIrrigations(payload)
			: Promise.resolve();

		try {
			const irrigationPromises = values.irrigations.map((irrigation) => {
				const irrigationPayload: IrrigationSystemPayload = {
					body: irrigation,
					activityId: activeActivityId ?? 0,
				};

				if (irrigation.id) {
					return updateIrrigationSystem(irrigationPayload).unwrap();
				}

				return Promise.resolve(); // Per gestire il caso in cui `irrigation.id` sia falsy
			});

			const phasePromises = values.irrigationPhases.map((phase) => {
				const phasePayload: IrrigationPhasePayload = {
					body: phase,
					activityId: activeActivityId ?? 0,
				};

				if (phase.id) {
					return updateIrrigationPhase(phasePayload);
				}

				return Promise.resolve(); // Per gestire il caso in cui `phase.id` sia falsy
			});

			await Promise.all([
				...irrigationPromises,
				...phasePromises,
				parentPromise,
			]);
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<IrrigationTierOne
					title="Irrigation"
					irrigationSystemSchema={irrigationSchema}
					operationPhaseSchema={operationPhaseSchema}
					isSystemLoading={isSysLoading || isLoadingUpdateSystem}
					isOperationLoading={isOpLoading || isLoadingUpdatePhase}
					blockingError={blockingSystemError ?? blockingPhaseError}
					footerError={errorMsg}
					beModuleType={BEModules.Irrigation}
					isError={isUpdateSystemError || isUpdatePhaseError}
					setInitValues={setInitValues}
					note={notes?.content ?? null}
				/>
				<IrrigationTierTwo
					irrigationSystemSchema={t2IrrigationSchema}
					operationPhaseSchema={t2OperationPhaseSchema}
					tabsOpen={tabsOpen}
					setInitValues={setInitValues}
				/>
			</>
		</Formik>
	);
};

export default Irrigation;
