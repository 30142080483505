import React, { useContext } from 'react'
import { Change } from './changesTypes'
import ForestChangeBlock, { ForestParentChanges } from './modules/forest/ForestChangeBlock'
import { ChangeBlock, ChangesContext } from './ChangesAction'

const GenericChangeBlock = ({ change }: { change: Change}) => {
    const path = useContext(ChangesContext).currentModule?.path
    switch (path) {
        case "forest-management":
            if (!change) return null
            return <ForestChangeBlock change={change as ForestParentChanges} />
        default:
        return <ChangeBlock change={change} />
    }
}

export default GenericChangeBlock