import { useSelector } from 'react-redux'
import { selectCurrentProject, setActivity } from '../../app/features/project/projectSlice'
import { BaseNumerableList, NavigationItem, RedirectableList } from './sideBarTypes'
import type { Activity, } from '../../types/interfaces'
import { ProjectNavigation } from '../../types/interfaces'
import { getFEModuleListFromActivity, getModuleNameFromBEId } from '../../utils/moduleList'
import { useAppDispatch } from '../../app/hooks'
import { setModuleList } from '../../app/features/builder/builderSlice'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { SideBarContext } from './provider'
import { useGetActivitiesByProjectQuery } from '../../app/features/activities/activityApiSlice'

const useGetMenuContent = () => {
	const { project } = useSelector(selectCurrentProject)
	const {
		data: activitiesRes,
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = useGetActivitiesByProjectQuery(`/activities/?page=1&page_size=100&project_id=${project?.id}`, {
		skip: !project?.id,
		refetchOnMountOrArgChange: true,
	});
	const { toggleSidebar } = useContext(SideBarContext)
	const dispatch = useAppDispatch()
	const navigate = useNavigate();

	const enumerableProject = new BaseNumerableList(
		"project.project",
		[
			new NavigationItem(ProjectNavigation.Description, () => navigate(`/project/${project?.id}/description`)),
			new NavigationItem(ProjectNavigation.Activities, () => navigate(`/project/${project?.id}/activities`)),
			new NavigationItem(ProjectNavigation.Results, () => navigate(`/project/${project?.id}/results`))
		]
	)
	const enumerableActivities = new BaseNumerableList(
		"activity.activities",
		activitiesRes?.results?.map(
			activity => new RedirectableList(
				activity.name,
				activity.modules.map(
					module => new NavigationItem(getModuleNameFromBEId(module.module_type.id), () => handleActivityClick(activity, module.module_type.name))
				),
				() => handleActivityClick(activity)
			)
		),
	)
	const handleActivityClick = (activity: Activity, subModule?: string) => {
		const feModuleList = getFEModuleListFromActivity(activity);
		const subModulePath = subModule ? feModuleList.filter(el => el.name === subModule)[0]?.path : null;
		dispatch(setActivity(activity));
		dispatch(setModuleList(feModuleList));
		const path = subModulePath ? `/builder/${activity.id}/${subModulePath}` : `/builder/${activity.id}/${feModuleList[0].path}`
		navigate(feModuleList.length > 0 ? path : `/project/${activity.id}/complete`);
		toggleSidebar()
	};

	return {
		enumerableProject,
		enumerableActivities,
		isLoading,
	}
}

export default useGetMenuContent