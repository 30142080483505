import type { InputEntryData, InputType, ModuleFull } from "./inputsTypes";
import { useFormikContext, Form, FieldArray, Field } from "formik";
import {
	useGetInputEntriesDefaultsQuery,
	useGetInputTypesQuery,
} from "../../../../app/features/api/modules/inputsApiSlice";
import { motion } from "framer-motion";
import { TierTwoBlock } from "../GenericTierTwo";
import { TierTwoIcon } from "../../../../utils/customIcons";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector, usePermissions, useWarningMessageT2 } from "../../../../app/hooks";
import { selectIsFetchingData } from "../../../../app/features/builder/builderSlice";
import SubModuleInputGroup from "../../../../components/input/SubModuleInputGroup";
import FormRow from "../../../../components/input/formRow/FormRow";
import CustomInput from "../../../../components/input/CustomInput";
import FormSubmodule from "../../../../components/formSubmodule/FormSubmodule";
import useErrorMessage from "../../../../utils/useErrorMessage";
import MessageNote from "../../../../components/messageNote/MessageNote";
import { preventFormSubmit } from "./utils";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};

const InputsTierTwo = ({
	tabsOpen,
	setInitValues,
}: {
	tabsOpen: boolean;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}) => {
	const { values } = useFormikContext<ModuleFull>();
	const { data: inputTypes, isLoading: isLoadingInputTypes } =
		useGetInputTypesQuery(null);
	const {warningMessage,handleDefaultsError} = useWarningMessageT2();
	const { isReadOnly } = usePermissions();

	if (tabsOpen) return null;
	return (
		<motion.div initial={false} variants={variants}>
			<Form onKeyDown={preventFormSubmit} className="py-2">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-13 ff-normal-ext module-header w-fit">
						Inputs - Tier 2
					</h2>
					{
						warningMessage && <MessageNote noteMessage={warningMessage} noteTitle="Unable to get default values"/>
					}
				</div>

				<FormSubmodule submoduleName="">
					<FieldArray name="entries">
						{({ remove }) => (
							<>
								{values.entries.length > 0 ? (
									<TierTwoBlock>
										{values.entries.map((entry, index) => {
											const type = inputTypes?.find(
												(t) => t.id === entry.input_type,
											);
											return (
												<CustomTierTwoSWWGroup
													key={entry.id}
													entry={entry}
													index={index}
													inputType={type}
													setInitValues={setInitValues}
													onDefaultsError={handleDefaultsError}
													disabled={isReadOnly}
												/>
											);
										})}
									</TierTwoBlock>
								) : null}
							</>
						)}
					</FieldArray>
				</FormSubmodule>
			</Form>
		</motion.div>
	);
};

const CustomTierTwoSWWGroup = ({
	inputType,
	entry,
	index,
	setInitValues,
	onDefaultsError,
	disabled
}: {
	inputType?: InputType;
	entry: InputEntryData;
	index: number;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	onDefaultsError: (message: string) => void
	disabled?: boolean
}) => {
	const [error, setError] = useState<string | undefined>(undefined); /* TODO */
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);

	const { values } = useFormikContext<ModuleFull>();

	const rowChanged = useMemo<boolean>(
		() =>
			!!entry.co2_e_emissions_t2 ||
			!!entry.n2o_emissions_t2 ||
			!!entry.co2_emissions_t2,
		[entry],
	);

	const { data: defaults, isError, error: defaultsError } = useGetInputEntriesDefaultsQuery(entry?.id ?? 0, {
		skip: !entry.id,
	});
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error: defaultsError });
	
	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				entries: prev.entries.map((e) =>
					e.id === entry.id ? { ...entry, ...defaults } : e,
				),
			}));
		if (isError && defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);

	}, [entry, defaults, setInitValues, defaultsErrorMsg, isError, onDefaultsError]);

	return (
		<div className="tiertwo-row">
			<TierTwoIcon />
			<div className="d-flex f-column align-items-start pos-relative ps-1 text-start">
				{rowChanged ? <div className="has-changed-dot" /> : null}
				<span className="fs-12">{inputType?.name}</span>
			</div>
			<SubModuleInputGroup
				error={error}
				name=""
				gridTemplateColumns="1fr 1fr 1fr"
				gap="8px"
			>
				<FormRow
					style={{ gridColumn: "1" }}
					topLabel={[
						`Default = ${values.entries[index].co2_emissions_t2_default ?? ""}`,
						"CO2 Emissions",
					]}
					isFetching={isFetchingData}
				>
					<Field
						name={`entries.${index}.co2_emissions_t2`}
						id={`entries.${index}.co2_emissions_t2`}
						component={CustomInput}
						label={inputType?.has_co2_emissions ? "[unit]" : "/"}
						disabled={!inputType?.has_co2_emissions || disabled}
						type="number"
						textend
						classes={entry.co2_emissions_t2 ? "tiertwo-input-changed" : ""}
					/>
				</FormRow>
				<FormRow
					style={{ gridColumn: "2" }}
					topLabel={[
						`Default = ${values.entries[index].n2o_emissions_t2_default ?? ""}`,
						"N2O Emissions",
					]}
					isFetching={isFetchingData}
				>
					<Field
						name={`entries.${index}.n2o_emissions_t2`}
						id={`entries.${index}.n2o_emissions_t2`}
						component={CustomInput}
						label={inputType?.has_n2o_emissions ? "[unit]" : "/"}
						disabled={!inputType?.has_n2o_emissions || disabled}
						type="number"
						textend
						classes={entry.n2o_emissions_t2 ? "tiertwo-input-changed" : ""}
					/>
				</FormRow>
				<FormRow
					style={{ gridColumn: "3" }}
					topLabel={[
						`Default = ${values.entries[index].co2_e_emissions_t2_default ?? ""}`,
						"CO2-e Emissions",
					]}
					isFetching={isFetchingData}
				>
					<Field
						name={`entries.${index}.co2_e_emissions_t2`}
						id={`entries.${index}.co2_e_emissions_t2`}
						component={CustomInput}
						label={inputType?.has_co2_e_emissions ? "[unit]" : "/"}
						disabled={!inputType?.has_co2_e_emissions || disabled}
						type="number"
						textend
						classes={entry.co2_e_emissions_t2 ? "tiertwo-input-changed" : ""}
					/>
				</FormRow>
			</SubModuleInputGroup>
		</div>
	);
};

export default InputsTierTwo;
