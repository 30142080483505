import {
	ProjectGasResults,
	ProjectTotalResults,
	ResultsErrors,
} from "../../../app/features/project/projectTypes";
import {
	Emission,
	EmissionsByGas,
	EmissionsTotal,
	GroupingGas,
} from "./resultTypes";


const getStatusResults = (statusEmissions: GroupingGas[]): Emission => {
	return statusEmissions.reduce(
		(acc, { gas_type, emissions }) => {
			const totalEmissions = emissions.reduce(
				(sum, cur) => sum + cur.value,
				0,
			);
			return {
				...acc,
				[gas_type.name]: acc[gas_type.name] + totalEmissions,
			};
		},
		{ CH4: 0, CO2: 0, N2O: 0, OTHER: 0 },
	);
};
const getModuleRes = (module: {
	results: EmissionsByGas | ResultsErrors;
}): Emission => {
	if (module.results.balance && typeof module.results.balance !== "string") {
		return getStatusResults(module.results.balance);
	} else {
		return { CH4: 0, CO2: 0, N2O: 0, OTHER: 0 };
	}
};
export const transformCBDataByActivity = (
	data: ProjectGasResults | undefined,
) => {
	return data?.activities.map(
		({ modules, name, cost }) => {
			const moduleRes = modules.map((mod) => getModuleRes(mod));
			const totalModuleRes = moduleRes.reduce(
				(actAcc, currModRes) => ({
					CH4: actAcc.CH4 + currModRes.CH4,
					CO2: actAcc.CO2 + currModRes.CO2,
					N2O: actAcc.N2O + currModRes.N2O,
					OTHER: (actAcc.OTHER || 0) + (currModRes.OTHER || 0),
				}),
				{ CH4: 0, CO2: 0, N2O: 0, OTHER: 0 },
			);

			return {
				activityName: name,
				cost,
				RATIO: cost ? parseInt(((cost ?? 0)/(totalModuleRes.CH4 + totalModuleRes.CO2 + totalModuleRes.N2O + (totalModuleRes.OTHER || 0))).toFixed(2)): 0,
				BALANCE: parseInt(totalModuleRes.CH4 + totalModuleRes.CO2 + totalModuleRes.N2O + (totalModuleRes.OTHER || 0).toFixed(2)),
				CH4: parseInt(totalModuleRes.CH4.toFixed(2)),
				CO2: parseInt(totalModuleRes.CO2.toFixed(2)),
				N2O: parseInt(totalModuleRes.N2O.toFixed(2)),
				OTHER: parseInt((totalModuleRes.OTHER || 0).toFixed(2)),
			};
		},
	);
}
export const transformCBData = (data: ProjectGasResults | undefined) => {

	return data?.activities.reduce(
		(acc, { modules }) => {
			const moduleRes = modules.map((mod) => getModuleRes(mod));
			const totalModuleRes = moduleRes.reduce(
				(actAcc, currModRes) => ({
					CH4: actAcc.CH4 + currModRes.CH4,
					CO2: actAcc.CO2 + currModRes.CO2,
					N2O: actAcc.N2O + currModRes.N2O,
					OTHER: (actAcc.OTHER || 0) + (currModRes.OTHER || 0),
				}),
				{ CH4: 0, CO2: 0, N2O: 0, OTHER: 0 },
			);

			return {
				CH4: totalModuleRes.CH4 + acc.CH4,
				CO2: totalModuleRes.CO2 + acc.CO2,
				N2O: totalModuleRes.N2O + acc.N2O,
				OTHER: (acc.OTHER || 0) + (totalModuleRes.OTHER || 0),
			};
		},
		{ CH4: 0, CO2: 0, N2O: 0, OTHER: 0 } as Emission,
	);
};

export const transformTotalCo2Generated = (
	data: ProjectTotalResults | undefined,
) => {
	if (!data || !data.activities) {
		return { total_w: 0, total_wo: 0, balance: 0 };
	}

	return data.activities.reduce(
		(acc, { modules }) => {
			return modules.reduce((moduleAcc, { results }) => {
				return {
					total_w:
						moduleAcc.total_w +
						(results?.total_w && typeof results?.total_w === "number"
							? results?.total_w
							: 0),
					total_wo:
						moduleAcc.total_wo +
						(results?.total_wo && typeof results?.total_wo === "number"
							? results?.total_wo
							: 0),
					balance:
						moduleAcc.balance +
						(results?.balance && typeof results?.balance === "number"
							? results?.balance
							: 0),
				};
			}, acc);
		},
		{ total_w: 0, total_wo: 0, balance: 0 },
	);
};

export const transformCO2ByActivity = (
	data: ProjectTotalResults | undefined,
) => {
	const getModuleRes = (results: ResultsErrors | EmissionsTotal) => {
		return results.balance && typeof results.balance !== "string"
			? +results.balance.toFixed(2)
			: 0;
	};

	return data?.activities.reduce(
		(acc, { name, modules }) => [
			...acc,
			{
				name: name,
				value: modules.reduce(
					(sum, { results }) => sum + getModuleRes(results),
					0,
				),
			},
		],
		[] as { name: string; value: number }[],
	);
};

export const transformCBByStatus = (data: ProjectGasResults | undefined) => {
	const getStatusResults = (statusEmissions: GroupingGas[]): Emission => {
		return statusEmissions.reduce(
			(acc, { gas_type, emissions }) => {
				const totalEmissions = emissions.reduce(
					(sum, cur) => sum + cur.value,
					0,
				);
				return {
					...acc,
					[gas_type.name]: parseFloat(totalEmissions.toFixed(2)),
				};
			},
			{ CH4: 0, CO2: 0, N2O: 0 },
		);
	};

	const getModuleRes = (module: { results: EmissionsByGas | ResultsErrors }): {
		total_w: Emission;
		total_wo: Emission;
		balance: Emission;
	} => {
		const emptyEm = { CH4: 0, CO2: 0, N2O: 0 };

		return {
			total_w:
				module.results.total_w && typeof module.results.total_w !== "string"
					? getStatusResults(module.results.total_w)
					: emptyEm,
			total_wo:
				module.results.total_wo && typeof module.results.total_wo !== "string"
					? getStatusResults(module.results.total_wo)
					: emptyEm,
			balance:
				module.results.balance && typeof module.results.balance !== "string"
					? getStatusResults(module.results.balance)
					: emptyEm,
		};
	};

	const mergeStatuses = (
		accEmission: Emission,
		currEmission: Emission,
	): Emission => ({
		CH4: accEmission.CH4 + currEmission.CH4,
		CO2: accEmission.CO2 + currEmission.CO2,
		N2O: accEmission.N2O + currEmission.N2O,
	});

	return data?.activities.reduce(
		(acc, { modules }) => {
			const { total_w, total_wo, balance } = modules
				.map((mod) => getModuleRes(mod))
				.reduce(
					(actAcc, currModRes) => ({
						total_w: mergeStatuses(actAcc.total_w, currModRes.total_w),
						total_wo: mergeStatuses(actAcc.total_wo, currModRes.total_wo),
						balance: mergeStatuses(actAcc.balance, currModRes.balance),
					}),
					{
						total_w: { CH4: 0, CO2: 0, N2O: 0 },
						total_wo: { CH4: 0, CO2: 0, N2O: 0 },
						balance: { CH4: 0, CO2: 0, N2O: 0 },
					},
				);

			return {
				total_w: mergeStatuses(acc.total_w, total_w),
				total_wo: mergeStatuses(acc.total_wo, total_wo),
				balance: mergeStatuses(acc.balance, balance),
			};
		},
		{
			total_w: { CH4: 0, CO2: 0, N2O: 0 },
			total_wo: { CH4: 0, CO2: 0, N2O: 0 },
			balance: { CH4: 0, CO2: 0, N2O: 0 },
		},
	);
};

export const transformCBByYear = (data: ProjectGasResults | undefined) => {
	const getYearlyResults = (yearlyModEmissions: GroupingGas[]): Emission[] => {
		return yearlyModEmissions.reduce((acc, { gas_type, emissions }) => {
			emissions.forEach((gasEmissions, i) => {
				const emission = {
					CH4: gas_type.name === "CH4" ? gasEmissions.value : 0,
					CO2: gas_type.name === "CO2" ? gasEmissions.value : 0,
					N2O: gas_type.name === "N2O" ? gasEmissions.value : 0,
					OTHER: gas_type.name === "OTHER" ? gasEmissions.value : 0,
				};

				const isAcc = acc[i] ? true : false;
				acc[i] = isAcc ? mergeStatuses(acc[i], emission) : emission;
			});
			return acc;
		}, [] as Emission[]);
	};

	const getModuleRes = (module: {
		results: EmissionsByGas | ResultsErrors;
	}): Emission[] => {
		return module.results.balance && typeof module.results.balance !== "string"
			? getYearlyResults(module.results.balance)
			: [];
	};

	const mergeStatuses = (
		accEmission: Emission,
		currEmission: Emission,
	): Emission => ({
		CH4: +(accEmission.CH4 + currEmission.CH4).toFixed(2),
		CO2: +(accEmission.CO2 + currEmission.CO2).toFixed(2),
		N2O: +(accEmission.N2O + currEmission.N2O).toFixed(2),
		OTHER: +(accEmission.OTHER ?? 0 + (currEmission.OTHER ?? 0)).toFixed(2),
	});

	return data?.activities.reduce((acc, { modules }) => {
		const activityEmissionsByYear = modules
			.map((mod) => getModuleRes(mod))
			.reduce((acc, currModYrlEmissions) => {
				currModYrlEmissions.forEach((em, i) => {
					const isAcc = acc[i] ? true : false;
					acc[i] = isAcc ? mergeStatuses(acc[i], em) : em;
				});
				return acc;
			}, [] as Emission[]);

		activityEmissionsByYear.forEach((em, i) => {
			const isAcc = acc[i] ? true : false;
			acc[i] = isAcc ? mergeStatuses(acc[i], em) : em;
		});
		return acc;
	}, [] as Emission[]);
};

export const transformModuleCBData = (data: EmissionsByGas | undefined) => {
	const getStatusResults = (statusEmissions: GroupingGas[]): Emission => {
		return statusEmissions.reduce(
			(acc, { gas_type, emissions }) => {
				const totalEmissions = emissions.reduce(
					(sum, cur) => sum + cur.value,
					0,
				);
				return {
					...acc,
					[gas_type.name]: acc[gas_type.name] + totalEmissions,
				};
			},
			{ CH4: 0, CO2: 0, N2O: 0, OTHER: 0 },
		);
	};

	const getModuleRes = (data: EmissionsByGas | undefined): Emission => {
		if (data?.balance && typeof data.balance !== "string") {
			return getStatusResults(data.balance);
		} else {
			return { CH4: 0, CO2: 0, N2O: 0, OTHER: 0 };
		}
	};

	return getModuleRes(data);
};
