import type { FormikHelpers } from "formik";
import { Formik } from "formik";
import { useEffect, useState, useMemo, useContext } from "react";

import { initialValues, validationSchema, getSchemas } from "./floodedRice";
import { useAppSelector, useSyncNotesWithStore } from "../../../../app/hooks";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetFloodedRiceDefaultsQuery,
	useGetFloodedRiceMinorSeasonsQuery,
	useGetFloodedRiceQuery,
	useUpdateFloodedRiceMinorSeasonMutation,
	useUpdateFloodedRiceMutation,
} from "../../../../app/features/api/modules/floodedRiceApiSlice";
import type { ModuleFull } from "./floodedRiceTypes";
import useModuleHook from "../useModuleHook";
import FloodedRiceTierOne from "./FloodedRiceTierOne";
import FloodedRiceTierTwo from "./FloodedRiceTierTwo";
import useErrorMessage from "../../../../utils/useErrorMessage";
import ModuleContext from "../../ModuleContext";
import { BEModules } from "../../../../utils/moduleList";

const FloodedRice = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);
	const {
		data: floodedRiceData,
		isLoading,
		isError,
		error,
	} = useGetFloodedRiceQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const {
		isLoading: isSeasonsLoading,
		isError: isSeasonsError,
		error: seasonsError,
	} = useGetFloodedRiceMinorSeasonsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});


	const { belongsToLuc } = useContext(ModuleContext)
	const [
		updateFloodedRice,
		{ isLoading: isLoadingUpdate, isError: isUpdateError, error: updateError },
	] = useUpdateFloodedRiceMutation();
	const [
		updateSeason,
		{
			error: seasonError,
			isError: isUpdateSeasonError,
			isLoading: isUpdateSeasonsLoading,
		},
	] = useUpdateFloodedRiceMinorSeasonMutation();
	const { data: parentDefaults, isError: isDefaultsError, error: defaultsError } = useGetFloodedRiceDefaultsQuery(
		floodedRiceData?.id ?? 0,
		{ skip: !floodedRiceData?.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError: isDefaultsError, error: defaultsError });


	const { moduleSchema, seasonSchema, seasonT2Schema, parentT2Schema } =
		useMemo(() => getSchemas(), []);
	const [initValues, setInitValues] = useState<ModuleFull>({
		parent: initialValues,
		seasons: [],
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading, isUpdateSeasonsLoading],
		isMutateError: isUpdateSeasonError || isUpdateError,
		mutateError: seasonError ?? updateError,
	});
	const { errorMsg: blockingSeasonError } = useErrorMessage({
		isError: isSeasonsError,
		error: seasonsError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (floodedRiceData)
			setInitValues((cur) => ({
				...cur,
				parent: { ...floodedRiceData, ...parentDefaults },
			}));
	}, [floodedRiceData, parentDefaults]);

	const { notes } = useSyncNotesWithStore({
		notes: floodedRiceData?.note ?? null,
	});

	const handleSubmit = async (
		values: ModuleFull,
		{ resetForm }: FormikHelpers<ModuleFull>,
	) => {
		const parent = { ...values.parent };
		const parentPayload = {
			data: parent,
			activityId: activeActivityId ?? 0,
		};

		try {
			const promises = [];

			// Update parent
			if (values.parent.id) promises.push(updateFloodedRice(parentPayload));

			// Update seasons
			for (const season of values.seasons) {
				const seasonPayload = {
					body: season,
					activityId: activeActivityId ?? 0,
				};

				if (season.id) promises.push(updateSeason(seasonPayload));
			}

			await Promise.all(promises);
			resetForm({ values });
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<FloodedRiceTierOne
					title="Flooded Rice"
					seasonSchema={seasonSchema}
					parentSchema={moduleSchema}
					isSeasonsLoading={isSeasonsLoading || isLoadingUpdate}
					isError={isUpdateError || isUpdateSeasonError}
					blockingError={blockingSeasonError ?? blockingError}
					footerError={errorMsg}
					beModuleType={BEModules.FloodedRice}
					belongsToLuc={belongsToLuc}
					setInitValues={setInitValues}
					note={notes?.content ?? null}
				/>
				<FloodedRiceTierTwo
					tabsOpen={tabsOpen}
					seasonSchema={seasonT2Schema}
					parentSchema={parentT2Schema}
					setInitValues={setInitValues}
					warningMessage={defaultsErrorMsg || null}
				/>
			</>
		</Formik>
	);
};

export default FloodedRice;
