import { includedPropList, includedT2PropList } from "../../../../pages/activityBuilder/modules/organicSoil/organicSoil";
import { filterObjectProps, convertIncomingPercentage, convertOutgoingPercentage, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import type { ModuleFull, OrganicSoilDefaults, OrganicSoilPayload, OrganicSoilResponse, OrganicSoilT2, OrganicSoilUpdatePayload, } from "../../../../pages/activityBuilder/modules/organicSoil/organicSoilTypes"
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
 endpoints: (builder) => ({
  getOrganicSoil: builder.query<ModuleFull | null, number>({
   query: (activityId) => `organic-soils/?activity=${activityId}`,
   transformResponse: (response: OrganicSoilResponse[]) => {
    if (response && response.length > 0) {
     const module = filterObjectProps(
      { ...response[0] },
      includedPropList,
     ) as OrganicSoilPayload;

     const t2 = filterObjectProps(
      { ...response[0] },
      includedT2PropList,
     ) as OrganicSoilT2;

     convertIncomingPercentage(module, ["ditches_area", "soil_fire_impact_percentage", "peat_ditches_area"])

     return {
      module: module,
      tiertwo: t2,
     };
    }
    return null;
   },
   providesTags: (_result, _error, activityId) => [
    {
     type: "OrganicSoil",
     id: activityId,
    },
   ],
  }),
  updateOrganicSoil: builder.mutation<
   OrganicSoilResponse,
   OrganicSoilUpdatePayload
  >({
   query: ({ data, activityId }) => {
    const module = { ...data.module };
    const tiertwo = { ...data.tiertwo };
    const lucId = data.module.land_use_change?.id;

    const payload = {
     ...module,
     ...tiertwo,
    } as unknown as OrganicSoilResponse;

    convertOutgoingPercentage(payload, ["ditches_area", "soil_fire_impact_percentage", "peat_ditches_area"]);

    const { id, ...rest } = payload;
    const body = { ...rest, activity: activityId, land_use_change: lucId };

    convertEmptyStringsToNull(body);
    return { url: `organic-soils/${id}/`, method: "PUT", body };
   },
   invalidatesTags: (results, _error, _data) => [
    { type: "OrganicSoil", id: results?.activity.id },
    { type: "ModuleResults", id: results?.id ? `organic-soils-${results?.id}` : undefined },
    { type: "ModuleHistory", id: results?.id ? `organic-soils-${results?.id}` : undefined },
    { type: "OrganicSoilDefaults", id: results?.id },
    { type: "ModulesStatuses", id: results?.activity.id }
   ],
  }),
  getOrganicSoilDefaults: builder.query<OrganicSoilDefaults, number>({
   query: (uniqueId) => `organic-soils/${uniqueId}/defaults/`,
   providesTags: (_result, _error, uniqueId) => [{ type: "OrganicSoilDefaults", id: uniqueId }],
  })
 }),
});

export const { useGetOrganicSoilQuery, useUpdateOrganicSoilMutation, useGetOrganicSoilDefaultsQuery } = moduleApiSlice;
