import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import {
	includedPropList,
	includedT2PropList,
} from "../../../../pages/activityBuilder/modules/setAside/setAside";
import type {
	ModuleFull,
	SetAsideDefaults,
	SetAsidePayload,
	SetAsideResponse,
	SetAsideT2,
	SetAsideUpdatePayload,
} from "../../../../pages/activityBuilder/modules/setAside/setAsideTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSetAside: builder.query<ModuleFull | null, number>({
			query: (activityId) => `set-asides/?activity=${activityId}`,
			transformResponse: (response: SetAsideResponse[]) => {
				if (response && response.length > 0) {
					const module = filterObjectProps(
						response[0],
						includedPropList,
					) as SetAsidePayload;
					const t2 = filterObjectProps(
						response[0],
						includedT2PropList,
					) as SetAsideT2;
					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "SetAside", id: activityId },
			],
		}),
		updateSetAside: builder.mutation<SetAsideResponse, SetAsideUpdatePayload>({
			query({ data, activityId }) {
				const module = { ...data.module };
				const tiertwo = { ...data.tiertwo };
				const lucId = data.module.land_use_change?.id;

				const payload = {
					...module,
					...tiertwo,
				} as unknown as SetAsideResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };

				convertEmptyStringsToNull(body)
				return { url: `set-asides/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "SetAside", id: results?.activity.id },
				{ type: "ModuleResults", id: results?.id ? `set-asides-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `set-asides-${results?.id}` : undefined },
				{ type: "SetAsideDefaults", id: results?.id },
				{ type: "ModulesStatuses", id: results?.activity.id }
			],
		}),
		getSetAsideDefautls: builder.query<SetAsideDefaults, number>({
			query: (uniqueId) => `set-asides/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [{ type: "SetAsideDefaults", id: uniqueId }],
		})
	}),
});

export const { useUpdateSetAsideMutation, useGetSetAsideQuery, useGetSetAsideDefautlsQuery } =
	moduleApiSlice;
