import { useGetActivitiesByProjectQuery } from "../../../app/features/activities/activityApiSlice";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import ActivityCard, { CreateNewActivityCard } from "./ActivityCard";
import Skeleton from "react-loading-skeleton";
import Masonry from "react-responsive-masonry";
import useErrorMessage from "../../../utils/useErrorMessage";
import { useDispatch } from "react-redux";
import { setActivities } from "../../../app/features/project/projectSlice";
import { useDeleteActivity } from "./useDeleteActivity";
import ConfirmationModal from "../../../components/modal/ConfirmationModal";
import Pagination from "../../../components/pagination/Pagination";
import { usePaginate } from "./usePaginate";
import { getActivitiesPageUrl } from "./utils";
import { usePermissions } from "../../../app/hooks";
import TranslatableText from "../../../components/translations/TranslatableText";

const ProjectActivitiesContainer = () => {
	const { projectId } = useParams<string>();
	const location = useLocation();
	const dispatch = useDispatch();
	const isProjecIdValid = useMemo<boolean>(
		() => projectId !== undefined && projectId !== "new",
		[projectId],
	);
	const { isReadOnly } = usePermissions();
	//full initial path for vitest
	const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
	const [url, setUrl] = useState<string>(`${baseUrl}/activities/?page=1&project_id=${projectId}`);

	useEffect(() => {
		setUrl(`${baseUrl}/activities/?page=1&project_id=${projectId}`);
	}, [projectId, baseUrl])

	// 7-10 ms faster if we go with lazy fetching
	const {
		data: activitiesRes,
		isLoading,
		isFetching,
		refetch,
		isError,
		error,
	} = useGetActivitiesByProjectQuery(url, {
		skip: !isProjecIdValid,
		refetchOnMountOrArgChange: true,
	});
	const {
		results: activities,
		count: activitiesCount,
		next,
		previous,
	} = activitiesRes || {};

	const nextUrl = useMemo(() => getActivitiesPageUrl(next, projectId), [next, projectId])
	const previousUrl = useMemo(() => getActivitiesPageUrl(previous, projectId), [previous, projectId])

	const { handleNext, handlePrev, handlePageClick } = usePaginate({
		next: nextUrl,
		previous: previousUrl,
		dynamicNext: (page: number) => `/activities/?page=${page}&project_id=${projectId}`,
		fetch: refetch,
		setUrl
	})
	const {
		isDeleteDialogOpen,
		handleCancel,
		handleDelete,
		showDeleteModal,
		isDeleteLoading,
		deleteErrorMsg,
		isDeleteError,
	} = useDeleteActivity(refetch);

	useEffect(() => {
		if (activities) dispatch(setActivities(activities));
	}, [activities, dispatch]);

	const { errorMsg } = useErrorMessage({ error, isError });

	return isProjecIdValid ? (
		<section style={{ paddingBottom: 20 }}>
			<ConfirmationModal
				open={isDeleteDialogOpen}
				onCancel={handleCancel}
				onConfirm={handleDelete}
				message={<TranslatableText translationKey="activity.delete_confirmation"/>}
			/>
			<div className="module-group">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-14 ff-medium-ext module-header w-fit">
						<TranslatableText translationKey="project.project_activities"/>
					</h2>
					<div className="module-number header-number ff-light-ext">
						{projectId}
					</div>
				</div>
			</div>

			<div className="activity-cards-container">
				{isError ? (
					<>
						<span className="error fs-13 pb-2">
							{errorMsg
								? errorMsg
								: `An error occurred while fetching activities for project ${projectId}`}
						</span>
					</>
				) : isDeleteError ? (
					<>
						<span className="error fs-13 pb-2">
							{deleteErrorMsg
								? deleteErrorMsg
								: "An error occurred while deleting an activity"}
						</span>
					</>
				) : (
					<Masonry columnsCount={2} gutter="1rem">
						<CreateNewActivityCard />
						{isLoading || isFetching
							? Array(3)
								.fill(0)
								.map((_, i) => (
									<Skeleton
										height={108}
										borderRadius={8}
										key={`cart-skeleton-${i}`}
									/>
								))
							: isDeleteLoading
								? activities?.map((act) => (
									<Skeleton height={108} borderRadius={8} key={act.id} />
								))
								: activities?.length
									? activities
										//  .sort((a, b) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime())
										.map((act, i) => (
											<ActivityCard
												activity={act}
												key={act.id}
												onDelete={() => showDeleteModal(act.id)}
												canEdit={!isReadOnly}
											/>
										))
									: null}
					</Masonry>
				)}
			</div>

			{activities?.length === 0 ? (
				<footer className="module-group-footer">
					<div className="module-group">
						<span className="ff-light fs-12 pb-2">
							* No activities have been added yet. Please, add activities to the
							project to start the assessment.
						</span>
					</div>
				</footer>
			) : null}
			{
				activities && activities?.length > 0 && (
					<Pagination totalItems={activitiesCount ?? 0} onNext={handleNext} onPrevious={handlePrev} onPageClick={handlePageClick} size="md" />
				)
			}
		</section>
	) : (
		<Navigate
			to={"/project/new/description"}
			state={{ from: location }}
			replace
		/>
	);
};

export default ProjectActivitiesContainer;
