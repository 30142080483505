import type { Change, ChangesResponseType } from "./changesTypes";
import { FaUserEdit } from "react-icons/fa";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector, useSearch } from "../../../app/hooks";
import { selectCurrentBuilder } from "../../../app/features/builder/builderSlice";
import { useGetModuleHistoryQuery } from "../../../app/features/api/modules/genericOperationsApiSlice";
import GenericChangeBlock from "./GenericChangeBlock";
import { selectCurrentActiveActivity, selectCurrentProject } from "../../../app/features/project/projectSlice";
import { Project } from "../../../types/interfaces";
import DisturbancesChanges from "./modules/forest/DisturbanceChanges";
import { FEModules, FEModulesObj, FeActivityModule, getModuleFromUrl } from "../../../utils/moduleList";
import SettelementsChanges from "./modules/SettelementsChanges";
import EnergyChanges from "./modules/EnergyChanges";
import IrrigationChanges from "./modules/IrrigationChanges";
import InputsChanges from "./modules/InputsChanges";
import Searchbar from "../../searchbar/Searchbar";
import TranslatableText from "../../translations/TranslatableText";
import { TranslationKey } from "../../../types/modulesInterfaces";

type ChangesContextType = {
	currentModule: FeActivityModule | null;
	activityId?: number;
	project?: Project | null;
	searchString: string;
}
export const ChangesContext = createContext<ChangesContextType>({ currentModule: null, searchString: "" });

const ChangesAction = () => {
	const { moduleList } = useAppSelector(selectCurrentBuilder);
	const activityId = useAppSelector(selectCurrentActiveActivity)
	const project = useAppSelector(selectCurrentProject).project
	const location = useLocation();
	const currentModule = useMemo(() => {
		const FEModule = getModuleFromUrl(location.pathname);
		return moduleList?.find((mod) => mod.path === FEModule?.path);
	}, [location, moduleList]);

	const [search, setSearch] = useState("")
	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value)
	}

	return (
		<ChangesContext.Provider value={{ currentModule: currentModule ?? null, activityId: activityId ?? -1, project, searchString: search }}>
			<div className="w-100 pt-2 px-2 bg-gray">
				<Searchbar label="Activity" handleChange={handleSearchChange} />
			</div>
			<ChangesParentAction />
			<GenericChild />
		</ChangesContext.Provider>

	)
}

const ChangesParentAction = () => {
	const { currentModule, searchString } = useContext(ChangesContext)
	const { data: moduleHistory, isLoading } = useGetModuleHistoryQuery(
		{
			path: currentModule?.path ?? "",
			uniqueId: currentModule?.uniqueId ?? -1,
		},
		{ skip: !currentModule },
	);

	const { searchResults } = useSearch({ list: moduleHistory ?? [], searchString })

	return (
		<section className="w-100 px-2 pb-2 bg-gray d-flex f-column">
			{isLoading ? (
				<div className="pt-2">
					<TranslatableText translationKey="module.loading_history"/>
				</div>
			) : searchResults?.length === 0 ? (
				<div className="pt-2">
					<TranslatableText translationKey="module.no_history"/>
				</div>
			) : (
				searchResults?.map((userChanges) => (
					<UserChanges
						key={`${userChanges.user}${userChanges.date}`}
						changes={userChanges}
					/>
				))
			)}
		</section>
	);
};

const GenericChild = () => {
	const path = useContext(ChangesContext).currentModule?.path
	switch (path) {
		case FEModulesObj[FEModules.ForestManagement].path:
			return <DisturbancesChanges />
		case FEModulesObj[FEModules.Energy].path:
			return <EnergyChanges />
		case FEModulesObj[FEModules.Settlements].path:
			return <SettelementsChanges />
		case FEModulesObj[FEModules.Irrigation].path:
			return <IrrigationChanges />
		case FEModulesObj[FEModules.Inputs].path:
			return <InputsChanges />
		default:
			return null
	}
}


export const UserChanges = ({ changes }: { changes: ChangesResponseType }) => {
	const reasonString = useMemo((): TranslationKey => {
		switch (changes.reason) {
			case "update":
				return "main.updated";
			case "delete":
				return "main.deleted";
			case "create":
				return "main.created";
			case "add":
				return "main.added";
			default:
				return "main.unknown";
		}
	}, [changes.reason]);

	const formattedDate = useMemo(() => {
		/* const hours = changes.date.getHours().toString().padStart(2, "0");
		const minutes = changes.date.getMinutes().toString().padStart(2, "0");
		const month = changes.date.toLocaleString(undefined, { month: "long" });
		const day = changes.date.getDate();
		const year = changes.date.getFullYear();

		return `${hours}:${minutes} ${month} ${day} ${year}`; */
		const date = new Date(changes.date);
		return date.toLocaleString(undefined, {
			hour: "2-digit",
			minute: "2-digit",
			month: "long",
			day: "numeric",
			year: "numeric",
			hour12: false,
		});
	}, [changes.date]);

	return (
		<div
			className="w-100 d-flex f-column align-items-start pb-1"
			style={{ borderBottom: "1px solid var(--gray)" }}
		>
			<div className="d-flex align-items-center pb-1 pt-2">
				<FaUserEdit />
				<span className="ps-1 ff-bold fs-14">{changes.user}</span>
			</div>
			<span className="ff-normal-cond fs-14">{formattedDate}</span>
			<TranslatableText className="ff-normal-cond fs-14" translationKey={reasonString}/>
			<div className="pt-1">
				{changes.changes.map((change) => (
					// <ChangeBlock change={change} key={change.field} />
					<GenericChangeBlock change={change} key={change.field} />
				))}
			</div>
		</div>
	);
};

export const ChangeBlock = ({ change }: { change: Change }) => {
	return (
		<div className="d-flex">
			<span className="fs-13">
				<span className="ff-medium">{change.field}: </span>
				{change.old ? `${change.old} → ${change.new}` : change.new}
			</span>
		</div>
	);
};

export default ChangesAction;
