import Button from "./Button";
import { ReactNode, createElement } from "react";
import { IconType } from "react-icons";
import { MdArrowForward } from "react-icons/md";
import Spinner from "../spinner/Spinner";
import { useCustomTranslation } from "../../app/hooks";
import { TranslationKey } from "../../types/modulesInterfaces";

const ModuleButton = ({
	handleClick,
	labelKey,
	buttonType,
	icon,
	disabled,
	isLoading,
	outlined,
}: {
	handleClick?: () => void;
	labelKey: TranslationKey;
	buttonType?: "reset" | "button" | "submit";
	icon?: IconType;
	disabled?: boolean;
	isLoading?: boolean;
	outlined?: boolean;
}) => {
	const translatedLabel = useCustomTranslation(labelKey);
	//const disabled = errors && Object.keys(errors).length > 0;
	return (
		<Button
			type={buttonType ? buttonType : "submit"}
			classes={`btn-save w-fit ${outlined ? "outlined-button" : ""} ${
				disabled ? "op-8" : ""
			}`}
			onClick={handleClick}
			disabled={disabled || isLoading}
		>
			<div className="bg-quat-br save-icon">
				{isLoading ? (
					<Spinner size={36} padding="0.5rem" />
				) : icon ? (
					createElement(icon, { size: 20, color: "var(--white)" })
				) : (
					<MdArrowForward size={20} color="var(--white)" />
				)}
			</div>
			<span
				className="label ff-medium"
				style={{
					backgroundColor: outlined ? "transparent" : "var(--quaternary-deep)",
					color: outlined ? "var(--quaternary-deep)" : "var(--white)",
				}}
			>
				{translatedLabel}
			</span>
		</Button>
	);
};

export default ModuleButton;
