import { useContext } from 'react'
import { SideBarContext } from './provider'
import ListItem from './ListItem'
import useGetMenuContent from './useGetMenuContent'
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../app/features/project/projectSlice'
import Button from '../button/Button'
import { IoArrowForward } from 'react-icons/io5'
import TranslatableText from '../translations/TranslatableText';
import Skeleton from 'react-loading-skeleton';

const MenuContent = () => {
	const { isSideBarOpen } = useContext(SideBarContext)
	const { project } = useSelector(selectCurrentProject)
	const { enumerableProject, enumerableActivities,isLoading } = useGetMenuContent()
	const navigate = useNavigate();

	return (
		<div>
			{
				isSideBarOpen && (
					<div>
						{[enumerableProject, enumerableActivities].map((item, index) => (
							<ListItem key={item.name} item={item} prefix={index + 1} />
						))}

						{isLoading && (
							 Array(3)
							 .fill(0)
							 .map((_, i) => (
								<div style={{ padding: 5 }}>
								 <Skeleton
									 height={20}
									 borderRadius={8}
									 key={`cart-skeleton-${i}`}
									 baseColor='var(--primary-dark)'
								 />
								</div>
							 ))
						)}
						<Button onClick={() => navigate(`/project/${project?.id}/new-activity`)} classes="p-2 mt-1 d-flex align-items-center justify-content-between text-white w-100" style={{ height: 24 }} >
							<div className='d-flex align-items-center'>
								<IoMdAddCircleOutline color='white' size={17} />
								<TranslatableText className='fs-12 px-1' translationKey="main.add_activity"/>
							</div>
							<IoArrowForward color="white" />
						</Button>
					</div>
				)
			}
		</div>
	)
}

export default MenuContent