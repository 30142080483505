import { apiSlice } from "../api/apiSlice";
import type { Project, User } from "../../../types/interfaces";
import type {
	EmissionsByActivity,
	EmissionsByGasActivity,
} from "../../../pages/project/results/resultTypes";
import type {
	ProjectGasResults,
	ProjectTotalResults,
	ProjectPayload,
	ProjectsResponse,
} from "./projectTypes";
import type { Group } from "../permissions/permissionTypes";

interface SOCRefProps {
	climate?: number | null;
	moisture?: number | null;
	soil_type?: number | null;
}

interface SOCRefResponse {
	id: number;
	value: number;
	climate: number;
	moisture: number;
	soil_type: number;
}

interface UpdateProjectPayload {
	id: number;
	body: ProjectPayload;
}

interface ProjectUser {
	id: number;
	user: User;
	group: Group;
	project: number;
}

export const projectApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getProjectById: builder.query<Project, string>({
			query: (id) => `projects/${id}/`,
			providesTags: ["Projects"],
		}),
		getProjects: builder.query<ProjectsResponse, string | undefined>({
			query: (url) => url ?? "projects/?page_size=100",
			providesTags: ["Projects"],
		}),
		createProject: builder.mutation<Project, ProjectPayload>({
			query: (data) => {
				return {
					url: "projects/",
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["Projects"],
		}),
		updateProject: builder.mutation<Project, UpdateProjectPayload>({
			query: ({ id, body }) => {
				return {
					url: `projects/${id}/`,
					method: "PATCH",
					body: { ...body },
				};
			},
			invalidatesTags: ["Projects"],
		}),
		deleteProject: builder.mutation<Project, number>({
			query: (id) => ({
				url: `projects/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["Projects"],
		}),
		getProjectResults: builder.query<ProjectTotalResults, number>({
			query: (id) => `projects/${id}/results/`,
		}),
		getProjectResultsByActivity: builder.query<
			{
				results: EmissionsByActivity;
			},
			number
		>({
			query: (id) => `projects/${id}/results/?aggregate=activity`,
		}),
		getProjectResultsByGas: builder.query<ProjectGasResults, number>({
			query: (id) => `projects/${id}/results/?aggregate=gas`,
		}),
		getProjectResultsByActivityGas: builder.query<
			{
				results: EmissionsByGasActivity;
			},
			number
		>({
			query: (id) => `projects/${id}/results/?aggregate=activity_gas`,
		}),
		// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
		getSOCref: builder.query<SOCRefResponse | null, void | SOCRefProps>({
			query: (props) => {
				if (!props) return "ipcc/soil-organic-carbons/";
				const { climate, moisture, soil_type } = props;
				const climateStr = climate ? `climate=${climate}&` : "";
				const moistureStr = moisture ? `moisture=${moisture}&` : "";
				const soil_typeStr = soil_type ? `soil_type=${soil_type}` : "";
				return `ipcc/soil-organic-carbons/?${climateStr}${moistureStr}${soil_typeStr}`;
			}, //TODO DA TESTARE
			transformResponse: (response: SOCRefResponse[]) =>
				response?.length ? response[0] : null,
		}),
		createProjectCopy: builder.mutation<Project, number>({
			query: (id) => ({
				url: `projects/${id}/copy/`,
				method: "POST",
			}),
			invalidatesTags: ["Projects"],
		}),
		getProjectMemberships: builder.query<ProjectUser[], number>({
			query: (id) => `projects/${id}/memberships/`,
			// transformResponse: (response: User[]) => response
			providesTags: ["ProjectUsers"],
		}),
		deleteMembership: builder.mutation<void, number>({
			query: (id) => ({
				url: `project-memberships/${id}/`,
				method: "DELETE"
			}),
			invalidatesTags: ["ProjectUsers"],
		})
	}),
});

export const {
	useGetProjectByIdQuery,
	useGetProjectsQuery,
	useGetProjectResultsQuery,
	useGetProjectResultsByActivityGasQuery,
	useGetProjectResultsByActivityQuery,
	useGetProjectResultsByGasQuery,
	useGetSOCrefQuery,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useDeleteProjectMutation,
	useCreateProjectCopyMutation,
	useGetProjectMembershipsQuery,
	useDeleteMembershipMutation,
	endpoints,
} = projectApiSlice;
