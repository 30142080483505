import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import type { CoastalWetlandsPayload, CoastalWetlandsResponse, CoastalWetlandsUpdatePayload, CoastalWetlandsDefaults, CoastalWetlandsT2, ModuleFull } from "../../../../pages/activityBuilder/modules/coastalWetlands/coastalWetlandsTypes";
import { apiSlice } from "../apiSlice";
import { includedPropList, includedT2PropList } from "../../../../pages/activityBuilder/modules/coastalWetlands/coastalWetlands";

export const moduleApiSlice = apiSlice.injectEndpoints({
 endpoints: builder => ({
  getCoastalWetlands: builder.query<ModuleFull | null, number>({
   query: (activityId) => `coastal-wetlands/?activity=${activityId}`,
   transformResponse: (response: CoastalWetlandsResponse[]) => {
    if (response && response.length > 0) {

     const module = filterObjectProps(
      { ...response[0] }, includedPropList
     ) as CoastalWetlandsPayload;

     const t2 = filterObjectProps(
      { ...response[0] },
      includedT2PropList
     ) as CoastalWetlandsT2;

     return {
      module: module,
      tiertwo: t2,
     }
    }
    return null
   },
   providesTags: (_results, _error, activityId) => [
    { type: 'CoastalWetlands', id: activityId }
   ]
  }),
  updateCoastalWetlands: builder.mutation<CoastalWetlandsResponse, CoastalWetlandsUpdatePayload>({
   query({ data, activityId }) {
    const module = { ...data.module };
    const tiertwo = { ...data.tiertwo };

    const payload = { ...module, ...tiertwo } as unknown as CoastalWetlandsResponse
    const { id, ...rest } = payload
    const body = { ...rest, activity: activityId }
    convertEmptyStringsToNull(body);
    return { url: `coastal-wetlands/${id}/`, method: 'PUT', body }
   },
   invalidatesTags: (results, _error, _data) => [
    { type: 'CoastalWetlands', id: results?.activity.id },
    { type: 'ModuleResults', id: results?.id ? `coastal-wetlands-${results?.id}` : undefined },
    { type: "ModuleHistory", id: results?.id ? `coastal-wetlands-${results?.id}` : undefined },
    { type: "CoastalWetlandsDefaults", id: results?.id ?? 0 },
    { type: "ModulesStatuses", id: results?.activity.id }
   ]
  }),
  getCoastalWetlandsDefaults: builder.query<CoastalWetlandsDefaults, number>({
   query: (uniqueId) => `coastal-wetlands/${uniqueId}/defaults/`,
   providesTags: (_results, _error, uniqueId) => [{ type: 'CoastalWetlandsDefaults', id: uniqueId }]
  }),
 })
})

export const { useGetCoastalWetlandsQuery, useUpdateCoastalWetlandsMutation, useGetCoastalWetlandsDefaultsQuery } = moduleApiSlice