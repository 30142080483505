import { Form, useFormikContext, getIn, FieldArray } from "formik";
import type { ReactNode } from "react";
import type React from "react";
import { createContext, useContext, useEffect } from "react";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import FormSubmodule from "../../../components/formSubmodule/FormSubmodule";
import GenericInputGroup from "../../../components/input/inputGroup/GenericInputGroup";
import type {
	OptionalSubmodule,
	T2Schema,
} from "../../../types/modulesInterfaces";
import { motion } from "framer-motion";
import { useGetSubmoduleDefaultsQuery } from "../../../app/features/api/modules/genericOperationsApiSlice";
import MessageNote from "../../../components/messageNote/MessageNote";
import DefaultsErrorNote from "../../../components/messageNote/DefaultsErrorNote";
import { usePermissions } from "../../../app/hooks";
import { handleDisableSww } from "./moduleUtils";
import { preventFormSubmit } from "./inputs/utils";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};
type GenericT2Context = {
	isReadOnly: boolean;
}
const GenericTwoContext = createContext<GenericT2Context>({
	isReadOnly: false
});

const GenericTierTwo = <M,>({
	title,
	t2Schema,
	tabsOpen,
	removeTitleNumber,
	setInitValues,
	warningMessage,
}: {
	title: string;
	t2Schema: T2Schema;
	tabsOpen: boolean;
	removeTitleNumber?: boolean;
	setInitValues?: React.Dispatch<React.SetStateAction<M>>;
	warningMessage?: string | null
}) => {
	const { isReadOnly } = usePermissions();
	if (tabsOpen) return null;
	return (
		<GenericTwoContext.Provider value={{isReadOnly}}>
		<motion.div initial={false} variants={variants}>
			<Form onKeyDown={preventFormSubmit} className="py-2">
				<div className="pos-relative mb-2 pb-1">
					<h2 className="fs-13 ff-normal-ext module-header w-fit">
						{title} - Tier 2
					</h2>
					{
						warningMessage && <DefaultsErrorNote noteMessage={warningMessage}/>
					}
				</div>
				{t2Schema.submodules.map((submodule) => (
					<FormSubmodule submoduleName={submodule.name} key={submodule.name}>
						<TierTwoBlock>
							{submodule.inputGroups.map((input, index) => {
								const curatedInput = handleDisableSww({input, isReadOnly, index});
								return (
									<GenericInputGroup
										key={`submodule-${
											// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
											index
										}`}
										inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
									/>
								)
							})}
						</TierTwoBlock>
					</FormSubmodule>
				))}
				{t2Schema.optionalSubmodules?.map((submodule, index) => (
					<FormOptionalSubmodules
						submodule={submodule}
						key={`optsubmodule-${
							// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
							index
						}`}
						removeTitleNumber={removeTitleNumber}
						setInitValues={setInitValues}
					/>
				))}
			</Form>
		</motion.div>
		</GenericTwoContext.Provider>
	);
};

const FormOptionalSubmodules = <T, M>({
	submodule,
	removeTitleNumber,
	setInitValues,
}: {
	submodule: OptionalSubmodule<T>;
	removeTitleNumber?: boolean;
	setInitValues?: React.Dispatch<React.SetStateAction<M>>;
}) => {
	const { values } = useFormikContext();
	const submoduleValues = getIn(values, submodule.inputName);
	const { isReadOnly } = useContext(GenericTwoContext);
	return Array.isArray(submoduleValues) ? (
		<>
			<FieldArray name={submodule.inputName}>
				{() => (
					<>
						{submoduleValues.map((el, index) => {
							const submoduleName = removeTitleNumber
								? submodule.name
								: `${index + 1}. ${submodule.name}`;

							return (
								<FormOptionalSubmoduleELement
									submodule={submodule}
									index={index}
									submoduleName={submoduleName}
									key={`${index + 1}. ${submodule.name}`}
									id={el.id ?? -1}
									setInitValues={setInitValues}
								/>
							);
						})}
					</>
				)}
			</FieldArray>
		</>
	) : (
		<FormSubmodule submoduleName={submodule.name}>
			<TierTwoBlock>
				{submodule.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({input, isReadOnly, index: i});
					return  (
						<GenericInputGroup
							key={`${submodule.name}-${i}`}
							inputGroup={{ ...curatedInput, disabled: isReadOnly }} //, autosubmit: true
						/>
					)
				})}
			</TierTwoBlock>
		</FormSubmodule>
	);
};

interface Id {
	id: number;
}
interface Sub extends Id {}

const FormOptionalSubmoduleELement = <T, M>({
	submodule,
	index,
	submoduleName,
	id,
	setInitValues,
}: {
	submodule: OptionalSubmodule<T>;
	index: number;
	submoduleName: string;
	id: number;
	setInitValues?: React.Dispatch<React.SetStateAction<M>>;
}) => {
	const { data: defaults } = useGetSubmoduleDefaultsQuery(
		{
			uniqueId: id,
			route: submodule.route ?? "",
			cacheTag: submodule.cacheTag ?? "",
		},
		{ skip: !id || !submodule.route || !submodule.cacheTag },
	);
	const { isReadOnly } = useContext(GenericTwoContext);

	useEffect(() => {
		if (defaults && setInitValues && submodule.route) {
			const route = submodule.route as keyof M;
			setInitValues((prev) => {
				const submoduleValues = prev[route] as Sub[];
				return {
					...prev,
					[route]: submoduleValues.map((el) =>
						el.id === id ? { ...el, ...defaults } : el,
					),
				};
			});
		}
	}, [defaults, setInitValues, submodule, id]);

	return (
		<FormSubmodule submoduleName={submoduleName}>
			<TierTwoBlock>
				{submodule.inputGroups.map((input, i) => {
					const curatedInput = handleDisableSww({input, isReadOnly, index});
					return (
						<GenericInputGroup
							key={`${submodule.name}-${i}`}
							inputGroup={{ ...curatedInput, index, disabled: isReadOnly }} //, autosubmit: true
						/>
					)
				})}
			</TierTwoBlock>
		</FormSubmodule>
	);
};

export const TierTwoBlock = ({ children }: { children: ReactNode }) => {
	return <div className="tiertwo-block">{children}</div>;
};

export default GenericTierTwo;
