import { getOptions } from "../../../../app/features/dropdownOptions/fetchOptions";
import type {
	Submodule,
	StartWithWithoutGeneric,
	T2Schema,
	ModuleSchema,
	DropdownInputGroupTierTwo,
	StartWithWithoutTierTwo,
	InputGroup,
	HectaresInputGroup,
} from "../../../../types/modulesInterfaces";
import type { OrganicSoilPayload, OrganicSoilT2Payload } from "./organicSoilTypes"
import { FieldType } from "../../../../types/modulesInterfaces";
import { validatePositiveNumber, validatePercentage } from "../moduleUtils";
import { landUseOptions } from "../landUseChanges/landUseChange";
import * as Yup from "yup";
import { translate } from "../../../../utils/helperFunctions";
import { AnyObject } from "yup/lib/types";

export const includedPropList = [
	"id",
	"land_use_change",
	"note",
	"activity",
	"status",

	"parent_land_use_type_start",
	"parent_land_use_type_w",
	"parent_land_use_type_wo",

	"area",
	"drainage_area_start",
	"drainage_area_w",
	"drainage_area_wo",
	"area_not_drained_start",
	"area_not_drained_w",
	"area_not_drained_wo",
	"ditches_area_start",
	"ditches_area_w",
	"ditches_area_wo",
	"soil_fire_periodicity_start",
	"soil_fire_periodicity_w",
	"soil_fire_periodicity_wo",
	"soil_fire_impact_percentage_start",
	"soil_fire_impact_percentage_w",
	"soil_fire_impact_percentage_wo",
	"peat_area_start",
	"peat_area_w",
	"peat_area_wo",
	"peat_ditches_area_start",
	"peat_ditches_area_w",
	"peat_ditches_area_wo",
	"peat_extraction_height_start",
	"peat_extraction_height_w",
	"peat_extraction_height_wo",
	"is_peat_for_energy_start",
	"is_peat_for_energy_w",
	"is_peat_for_energy_wo",
	"land_use_type_start",
	"land_use_type_w",
	"land_use_type_wo",
	"fire_type_start",
	"fire_type_w",
	"fire_type_wo",
	"peat_type",
];
export const includedT2PropList = [
	"soc_t2_start",
	"soc_t2_w",
	"soc_t2_wo",
	"flu_t2_start",
	"flu_t2_w",
	"flu_t2_wo",
	"fi_t2_start",
	"fi_t2_w",
	"fi_t2_wo",
	"fmg_t2_start",
	"fmg_t2_w",
	"fmg_t2_wo",
	"onsite_co2_drainge_t2_start",
	"onsite_co2_drainge_t2_w",
	"onsite_co2_drainge_t2_wo",
	"onsite_ch4_drainge_t2_start",
	"onsite_ch4_drainge_t2_w",
	"onsite_ch4_drainge_t2_wo",
	"onsite_n2o_drainge_t2_start",
	"onsite_n2o_drainge_t2_w",
	"onsite_n2o_drainge_t2_wo",
	"offsite_doc_drainge_t2_start",
	"offsite_doc_drainge_t2_w",
	"offsite_doc_drainge_t2_wo",
	"offsite_ch4_drainge_t2_start",
	"offsite_ch4_drainge_t2_w",
	"offsite_ch4_drainge_t2_wo",
	"onsite_co2_rewetting_t2_start",
	"onsite_co2_rewetting_t2_w",
	"onsite_co2_rewetting_t2_wo",
	"onsite_ch4_rewetting_t2_start",
	"onsite_ch4_rewetting_t2_w",
	"onsite_ch4_rewetting_t2_wo",
	"onsite_n2o_rewetting_t2_start",
	"onsite_n2o_rewetting_t2_w",
	"onsite_n2o_rewetting_t2_wo",
	"offsite_doc_rewetting_t2_start",
	"offsite_doc_rewetting_t2_w",
	"offsite_doc_rewetting_t2_wo",
	"mean_dry_matter_t2_start",
	"mean_dry_matter_t2_w",
	"mean_dry_matter_t2_wo",
	"fire_on_soil_co2_t2_start",
	"fire_on_soil_co2_t2_w",
	"fire_on_soil_co2_t2_wo",
	"fire_on_soil_co_t2_start",
	"fire_on_soil_co_t2_w",
	"fire_on_soil_co_t2_wo",
	"fire_on_soil_ch4_t2_start",
	"fire_on_soil_ch4_t2_w",
	"fire_on_soil_ch4_t2_wo",

	"onsite_co2_peat_t2_start",
	"onsite_co2_peat_t2_w",
	"onsite_co2_peat_t2_wo",
	"onsite_ch4_peat_t2_start",
	"onsite_ch4_peat_t2_w",
	"onsite_ch4_peat_t2_wo",
	"onsite_n2o_peat_t2_start",
	"onsite_n2o_peat_t2_w",
	"onsite_n2o_peat_t2_wo",
	"offsite_doc_peat_t2_start",
	"offsite_doc_peat_t2_w",
	"offsite_doc_peat_t2_wo",
	"offsite_ch4_peat_t2_start",
	"offsite_ch4_peat_t2_w",
	"offsite_ch4_peat_t2_wo",
	"peat_density_t2_start",
	"peat_density_t2_w",
	"peat_density_t2_wo",
];
const createAreaValidation = (relatedField: string, requiredMessageKey: string) =>
	validatePositiveNumber.when(relatedField, {
	  is: (value: number | null) => !!value,
	  then: validatePositiveNumber.required(requiredMessageKey),
	}).test(
	  "not_exceed_area",
	  translate("module.area_field_not_greater_than_module_area"),
	  function (value) {
		if (!value) return true;
		const area = this.parent.area;
		return value <= area;
	  }
	);

const createBasicAreaValidation = (validation: Yup.NumberSchema<number | null | undefined, AnyObject, number | null | undefined>) =>	
	validation.test(
		"not_exceed_area",
		translate("module.area_field_not_greater_than_module_area"),
		function (value) {
		  if (!value) return true;
		  const area = this.parent.area;
		  return value <= area;
		}  
	)
  
export const validationSchema = Yup.object().shape({
	module: Yup.object().shape({
		drainage_area_start: createBasicAreaValidation(validatePositiveNumber.required('validations.drainage_area_required')),
		drainage_area_w: createBasicAreaValidation(validatePositiveNumber.required('validations.drainage_area_required')),
		drainage_area_wo: createBasicAreaValidation(validatePositiveNumber.required('validations.drainage_area_required')),
		area_not_drained_start: createAreaValidation("drainage_area_start", "validations.area_not_drained_required_if_drainage_area_filled"),
		area_not_drained_w: createAreaValidation("drainage_area_w", "validations.area_not_drained_required_if_drainage_area_filled"),
		area_not_drained_wo: createAreaValidation("drainage_area_wo", "validations.area_not_drained_required_if_drainage_area_filled"),
		/* if fire on soil -> 
		- fire periodicity, 
		- % soil fire impact */
		soil_fire_periodicity_start: validatePositiveNumber.when("fire_type_start", {
			is: (fire_type_start: number | null) => !!fire_type_start,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required('validations.fire_periodicity_required_if_fire_type'),
		}),
		soil_fire_periodicity_w: validatePositiveNumber.when("fire_type_w", {
			is: (fire_type_w: number | null) => !!fire_type_w,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required('validations.fire_periodicity_required_if_fire_type'),
		}),
		soil_fire_periodicity_wo: validatePositiveNumber.when("fire_type_wo", {
			is: (fire_type_wo: number | null) => !!fire_type_wo,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required('validations.fire_periodicity_required_if_fire_type'),
		}),
		soil_fire_impact_percentage_start: validatePercentage.when("fire_type_start", {
			is: (fire_type_start: number | null) => !!fire_type_start,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePercentage.required('validations.fire_impact_percentage_required_if_fire_type'),
		}),
		soil_fire_impact_percentage_w: validatePercentage.when("fire_type_w", {
			is: (fire_type_w: number | null) => !!fire_type_w,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePercentage.required('validations.fire_impact_percentage_required_if_fire_type'),
		}),
		soil_fire_impact_percentage_wo: validatePercentage.when("fire_type_wo", {
			is: (fire_type_wo: number | null) => !!fire_type_wo,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePercentage.required('validations.fire_impact_percentage_required_if_fire_type'),
		}),
		/* if peat extraction -> 
		- area of ditch,
		- height of extraction */
		peat_area_start: createBasicAreaValidation(validatePositiveNumber),
		peat_area_w: createBasicAreaValidation(validatePositiveNumber),
		peat_area_wo: createBasicAreaValidation(validatePositiveNumber),	
		peat_ditches_area_start: validatePercentage.when("peat_area_start", {
			is: (peat_area_start: number | null) => !!peat_area_start,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePercentage.required('validations.peat_ditches_area_required_if_peat_area'),
		}),
		peat_ditches_area_w: validatePercentage.when("peat_area_w", {
			is: (peat_area_w: number | null) => !!peat_area_w,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePercentage.required('validations.peat_ditches_area_required_if_peat_area'),
		}),
		peat_ditches_area_wo: validatePercentage.when("peat_area_wo", {
			is: (peat_area_wo: number | null) => !!peat_area_wo,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePercentage.required('validations.peat_ditches_area_required_if_peat_area'),
		}),
		peat_extraction_height_start: validatePositiveNumber.when("peat_area_start", {
			is: (peat_area_start: number | null) => !!peat_area_start,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required('validations.peat_extraction_height_required_if_peat_area'),
		}),
		peat_extraction_height_w: validatePositiveNumber.when("peat_area_w", {
			is: (peat_area_w: number | null) => !!peat_area_w,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required('validations.peat_extraction_height_required_if_peat_area'),
		}),
		peat_extraction_height_wo: validatePositiveNumber.when("peat_area_wo", {
			is: (peat_area_wo: number | null) => !!peat_area_wo,
			// biome-ignore lint/suspicious/noThenProperty: <explanation>
			then: validatePositiveNumber.required('validations.peat_extraction_height_required_if_peat_area'),
		}),
		ditches_area_start: validatePercentage,
		ditches_area_w: validatePercentage,
		ditches_area_wo: validatePercentage,
	}),
});
export const initialValues: OrganicSoilPayload = {
	id: null,
	land_use_change: null,
	area: null,
	note: null,
	land_use_type_start: null,
	land_use_type_w: null,
	land_use_type_wo: null,
	status: null,

	parent_land_use_type_start: null,
	parent_land_use_type_w: null,
	parent_land_use_type_wo: null,

	drainage_area_start: null,
	drainage_area_w: null,
	drainage_area_wo: null,
	area_not_drained_start: null,
	area_not_drained_w: null,
	area_not_drained_wo: null,
	ditches_area_start: null, /* % */
	ditches_area_w: null,
	ditches_area_wo: null,
	fire_type_start: null,
	fire_type_w: null,
	fire_type_wo: null,
	soil_fire_periodicity_start: null,
	soil_fire_periodicity_w: null,
	soil_fire_periodicity_wo: null,
	soil_fire_impact_percentage_start: null, /* % */
	soil_fire_impact_percentage_w: null,
	soil_fire_impact_percentage_wo: null,

	peat_area_start: null,
	peat_area_w: null,
	peat_area_wo: null,
	peat_ditches_area_start: null, /* % */
	peat_ditches_area_w: null,
	peat_ditches_area_wo: null,
	peat_extraction_height_start: null,
	peat_extraction_height_w: null,
	peat_extraction_height_wo: null,
	is_peat_for_energy_start: false,
	is_peat_for_energy_w: false,
	is_peat_for_energy_wo: false,

	peat_type: null, /* in T2 We have peat type that is scenario dependent but only one input in the ui */
};

const fireTypes = () => getOptions("fireTypes");
const peatTypes = () => getOptions("peatTypes");
// const landUseTypes = () => getOptions('landUseType')

const getInitInputGroups: () => InputGroup[] = () => {
	const hectaresInputGroup: HectaresInputGroup = {
		type: FieldType.HECTARES,
		label: "module.hectares",
		unit: "[ha]",
		inputName: "module.area",
		disabled: true,
	};
	return [hectaresInputGroup];
};
/* T2 */
export const t2InitialValues: OrganicSoilT2Payload = {
	soc_t2_start: null,
	soc_t2_w: null,
	soc_t2_wo: null,
	flu_t2_start: null,
	flu_t2_w: null,
	flu_t2_wo: null,
	fi_t2_start: null,
	fi_t2_w: null,
	fi_t2_wo: null,
	fmg_t2_start: null,
	fmg_t2_w: null,
	fmg_t2_wo: null,

	/* Common */
	peat_type: null,
	mean_dry_matter_t2_start: null,
	mean_dry_matter_t2_w: null,
	mean_dry_matter_t2_wo: null,
	fire_on_soil_co2_t2_start: null,
	fire_on_soil_co2_t2_w: null,
	fire_on_soil_co2_t2_wo: null,
	fire_on_soil_co_t2_start: null,
	fire_on_soil_co_t2_w: null,
	fire_on_soil_co_t2_wo: null,
	fire_on_soil_ch4_t2_start: null,
	fire_on_soil_ch4_t2_w: null,
	fire_on_soil_ch4_t2_wo: null,

	/* Org soil */
	onsite_co2_drainge_t2_start: null,
	onsite_co2_drainge_t2_w: null,
	onsite_co2_drainge_t2_wo: null,

	onsite_ch4_drainge_t2_start: null,
	onsite_ch4_drainge_t2_w: null,
	onsite_ch4_drainge_t2_wo: null,

	onsite_n2o_drainge_t2_start: null,
	onsite_n2o_drainge_t2_w: null,
	onsite_n2o_drainge_t2_wo: null,

	offsite_doc_drainge_t2_start: null,
	offsite_doc_drainge_t2_w: null,
	offsite_doc_drainge_t2_wo: null,

	offsite_ch4_drainge_t2_start: null,
	offsite_ch4_drainge_t2_w: null,
	offsite_ch4_drainge_t2_wo: null,

	onsite_co2_rewetting_t2_start: null,
	onsite_co2_rewetting_t2_w: null,
	onsite_co2_rewetting_t2_wo: null,

	onsite_ch4_rewetting_t2_start: null,
	onsite_ch4_rewetting_t2_w: null,
	onsite_ch4_rewetting_t2_wo: null,

	onsite_n2o_rewetting_t2_start: null,
	onsite_n2o_rewetting_t2_w: null,
	onsite_n2o_rewetting_t2_wo: null,

	offsite_doc_rewetting_t2_start: null,
	offsite_doc_rewetting_t2_w: null,
	offsite_doc_rewetting_t2_wo: null,
	/* PEAT */
	onsite_co2_peat_t2_start: null,
	onsite_co2_peat_t2_w: null,
	onsite_co2_peat_t2_wo: null,
	onsite_ch4_peat_t2_start: null,
	onsite_ch4_peat_t2_w: null,
	onsite_ch4_peat_t2_wo: null,
	onsite_n2o_peat_t2_start: null,
	onsite_n2o_peat_t2_w: null,
	onsite_n2o_peat_t2_wo: null,
	offsite_doc_peat_t2_start: null,
	offsite_doc_peat_t2_w: null,
	offsite_doc_peat_t2_wo: null,
	offsite_ch4_peat_t2_start: null,
	offsite_ch4_peat_t2_w: null,
	offsite_ch4_peat_t2_wo: null,
	peat_density_t2_start: null,
	peat_density_t2_w: null,
	peat_density_t2_wo: null,
};
const getMandatorySubmodules: () => Submodule[] = () => {
	const landUse: StartWithWithoutGeneric = {
		type: FieldType.SWW_SELECT,
		inputName: "module.parent_land_use_type",
		label: "module.type_of_land_use",
		startProps: { dropdownOptions: landUseOptions, disabled: true },
		withProps: { dropdownOptions: landUseOptions, disabled: true },
		withoutProps: { dropdownOptions: landUseOptions, disabled: true },
	};

	const orgSoilRows: StartWithWithoutGeneric[] = [
		{
			type: FieldType.SWW,
			label: "module.area_under_drainage",
			unit: "[ha]",
			inputName: "module.drainage_area",
		},
		{
			type: FieldType.SWW,
			label: "module.area_not_drained_or_rewetted",
			unit: "[ha]",
			inputName: "module.area_not_drained",
		},
		{
			type: FieldType.SWW,
			label: "module.percentage_area_of_ditches",
			unit: "[%]",
			inputName: "module.ditches_area",
		},
		{
			type: FieldType.SWW_SELECT,
			label: "module.fire_on_soil",
			inputName: "module.fire_type",
			startProps: { dropdownOptions: fireTypes },
			withProps: { dropdownOptions: fireTypes },
			withoutProps: { dropdownOptions: fireTypes },
		},
		{
			type: FieldType.SWW,
			label: "module.soil_fire_periodicity",
			inputName: "module.soil_fire_periodicity",
		},
		{
			type: FieldType.SWW,
			label: "module.soil_fire_impact_percentage",
			unit: "[%]",
			inputName: "module.soil_fire_impact_percentage",
		},
	];

	const peatExtractionRows: StartWithWithoutGeneric[] = [
		{
			type: FieldType.SWW,
			label: "module.area_of_peat_extraction",
			unit: "[ha]",
			inputName: "module.peat_area",
		},
		{
			type: FieldType.SWW,
			label: "module.percentage_area_of_ditches",
			unit: "[%]",
			inputName: "module.peat_ditches_area",
		},
		{
			type: FieldType.SWW,
			label: "module.height_of_extraction",
			unit: "[cm]",
			inputName: "module.peat_extraction_height",
		},
		{
			type: FieldType.SWW_BOOL,
			label: "module.peat_used_for_energy",
			inputName: "module.is_peat_for_energy",
		},
	];

	return [
		{
			name: "",
			inputGroups: [landUse],
		},
		{
			name: "module.organic_soil",
			inputGroups: [...orgSoilRows],
		},
		{
			name: "module.peat_extraction",
			inputGroups: [...peatExtractionRows],
		},
	];
};

const getT2Submodules: () => Submodule[] = () => {
	const typeOfPeat: DropdownInputGroupTierTwo = {
		type: FieldType.SELECT_T2,
		label: "module.type_of_peat",
		dropdownOptions: peatTypes,
		inputName: "module.peat_type",
		defaultValue: "",
		unit: "[unit]",
		links: [],
	};
	const meanDryMatter: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: "tiertwo.mean_dry_matter_t2",
		label: "module.mean_dry_matter",
		unit: "[tDM/ha]",
		links: [],
		startProps: { inputType: "number", defaultValue: 0 },
		withoutProps: { inputType: "number", defaultValue: 0 },
		withProps: { inputType: "number", defaultValue: 0 },
	};
	const co2EfFireOnSoil: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: "tiertwo.fire_on_soil_co2_t2",
		label: "module.co2_fire_on_soil_ef",
		unit: "[grC/kg DM]",
		links: [],
		startProps: { inputType: "number", defaultValue: 0 },
		withoutProps: { inputType: "number", defaultValue: 0 },
		withProps: { inputType: "number", defaultValue: 0 },
	};
	const coEfFireOnSoil: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: "tiertwo.fire_on_soil_co_t2",
		label: "module.co_fire_on_soil_ef",
		unit: "[grCO/kg DM]",
		links: [""],
		startProps: { inputType: "number", defaultValue: 0 },
		withoutProps: { inputType: "number", defaultValue: 0 },
		withProps: { inputType: "number", defaultValue: 0 },
	};
	const ch4EfFireOnSoil: StartWithWithoutTierTwo = {
		type: FieldType.SWW_T2,
		inputName: "tiertwo.fire_on_soil_ch4_t2",
		label: "module.ch4_fire_on_soil_ef",
		unit: "[grCH4/kg DM]",
		links: [""],
		startProps: { inputType: "number", defaultValue: 0 },
		withoutProps: { inputType: "number", defaultValue: 0 },
		withProps: { inputType: "number", defaultValue: 0 },
	};

	const orgSoilRows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_co2_drainge_t2",
			label: "module.co2_onsite_ef_drainage",
			unit: "[tC/ha/yr]",
			links: ["Land use", "Area under Drainage"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_ch4_drainge_t2",
			label: "module.ch4_onsite_ef_drainage",
			unit: "[kgCH4/ha/yr]",
			links: ["Land use", "Area under Drainage"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_n2o_drainge_t2",
			label: "module.n2o_onsite_ef_drainage",
			unit: "[tN2O-N/ha/yr]",
			links: ["Land use", "Area under Drainage"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.offsite_doc_drainge_t2",
			label: "module.doc_offsite_ef_drainage",
			unit: "[tC/ha/yr]",
			links: ["Land use", "Area under Drainage"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.offsite_ch4_drainge_t2",
			label: "module.ch4_offsite_ef_drainage",
			unit: "[kgCH4/ha/yr]",
			links: ["Land use", "Area under Drainage"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_co2_rewetting_t2",
			label: "module.co2_onsite_ef_rewetting",
			unit: "[tC/ha/yr]",
			links: ["Land use", "Area not under Drainage or Rewetted"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_ch4_rewetting_t2",
			label: "module.ch4_onsite_ef_rewetting",
			unit: "[kgCH4/ha/yr]",
			links: ["Land use", "Area not under Drainage or Rewetted"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_n2o_rewetting_t2",
			label: "module.n2o_onsite_ef_rewetting",
			unit: "[tN2O-N/ha/yr]",
			links: ["Land use", "Area not under Drainage or Rewetted"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.offsite_doc_rewetting_t2",
			label: "module.doc_offsite_ef_rewetting",
			unit: "[tC/ha/yr]",
			links: ["Land use", "Area not under Drainage or Rewetted"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	const peatExtractionRows: StartWithWithoutTierTwo[] = [
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_co2_peat_t2",
			label: "module.co2_onsite_ef_extraction",
			unit: "[tC/ha/yr]",
			links: ["Area of Peat Extraction"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_ch4_peat_t2",
			label: "module.ch4_onsite_ef_extraction",
			unit: "[kgCH4/ha/yr]",
			links: ["Area of Peat Extraction"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.onsite_n2o_peat_t2",
			label: "module.n2o_onsite_ef_extraction",
			unit: "[tN2O-N/ha/yr]",
			links: ["Area of Peat Extraction"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.offsite_doc_peat_t2",
			label: "module.doc_offsite_ef_extraction",
			unit: "[tC/ha/yr]",
			links: ["Area of Peat Extraction"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.offsite_ch4_peat_t2",
			label: "module.ch4_offsite_ef_extraction",
			unit: "[kgCH4/ha/yr]",
			links: ["Area of Peat Extraction"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
		{
			type: FieldType.SWW_T2,
			inputName: "tiertwo.peat_density_t2",
			label: "module.peat_density",
			unit: "[t/m3]",
			links: ["Area of Peat Extraction"],
			startProps: { inputType: "number", defaultValue: 0 },
			withoutProps: { inputType: "number", defaultValue: 0 },
			withProps: { inputType: "number", defaultValue: 0 },
		},
	];

	return [
		{
			name: "",
			inputGroups: [typeOfPeat, meanDryMatter, co2EfFireOnSoil, coEfFireOnSoil, ch4EfFireOnSoil],
		},
		{
			name: "module.organic_soil",
			inputGroups: [...orgSoilRows],
		},
		{
			name: "module.peat_extraction",
			inputGroups: [...peatExtractionRows],
		},
	];
};

export const getSchemas = () => {
	const moduleSchema: ModuleSchema = {
		initInputGroups: getInitInputGroups(),
		mandatorySubmodules: getMandatorySubmodules(),
		optionalSubmodules: [],
	};
	const t2Schema: T2Schema = {
		submodules: getT2Submodules(),
	};

	return { moduleSchema, t2Schema };
};
