import type { Submodule } from "../../../../types/modulesInterfaces";
import type { IrrigationSystemData, ModuleFull } from "./irrigationTypes";
import { FieldArray, Form, useFormikContext } from "formik";
import { motion } from "framer-motion";
import { TierTwoBlock } from "../GenericTierTwo";
import FormSubmodule from "../../../../components/formSubmodule/FormSubmodule";
import GenericInputGroup from "../../../../components/input/inputGroup/GenericInputGroup";
import { useGetIrrigationPhaseDefaultsQuery, useGetIrrigationSystemDefaultsQuery } from "../../../../app/features/api/modules/irrigationApiSlice";
import { useCallback, useEffect, useState } from "react";
import useErrorMessage from "../../../../utils/useErrorMessage";
import MessageNote from "../../../../components/messageNote/MessageNote";
import DefaultsErrorNote from "../../../../components/messageNote/DefaultsErrorNote";
import { usePermissions, useWarningMessageT2 } from "../../../../app/hooks";
import { handleDisableSww } from "../moduleUtils";
import { preventFormSubmit } from "../inputs/utils";
import { TranslatedFormSubmodule } from "../../../../components/formSubmodule/TranslatedFormSubmodule";

const variants = {
	open: {
		width: 550,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2,
		},
		closed: {
			width: 0,
			transition: {
				delay: 0.5,
				type: "spring",
				stiffness: 400,
				damping: 40,
			},
		},
	},
};

interface IrrigationTierTwoProps {
	tabsOpen: boolean;
	irrigationSystemSchema: Submodule;
	operationPhaseSchema: Submodule;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
}

const IrrigationTierTwo = ({
	irrigationSystemSchema,
	operationPhaseSchema,
	tabsOpen,
	setInitValues,
}: IrrigationTierTwoProps) => {
	const { values } = useFormikContext<ModuleFull>();
	const { warningMessage, handleDefaultsError } = useWarningMessageT2();
	const { isReadOnly } = usePermissions();
	if (tabsOpen) return null;
	return (
		<motion.div initial={false} variants={variants}>
			{
				warningMessage && <DefaultsErrorNote noteMessage={warningMessage} />
			}
			<Form onKeyDown={preventFormSubmit} className="py-2">
				{/* irrigation systems */}
				<FieldArray name="irrigations">
					{() => (
						<>
							{values.irrigations.map((irrigationSys, index) => (
								<IrrigationSystemT2
									irrigationSys={irrigationSys}
									index={index}
									setInitValues={setInitValues}
									key={irrigationSys.id}
									irrigationSystemSchema={irrigationSystemSchema}
									onDefaultsError={handleDefaultsError}
									disabled={isReadOnly}
								/>
							))}
						</>
					)}
				</FieldArray>

				{/* operation phases */}
				<FieldArray name="irrigationPhases">
					{() => (
						<>
							{values.irrigationPhases.map((operationPhase, index) => (
								<IrrigationPhaseT2
									operationPhase={operationPhase}
									index={index}
									setInitValues={setInitValues}
									key={operationPhase.id}
									operationPhaseSchema={operationPhaseSchema}
									onDefaultsError={handleDefaultsError}
									disabled={isReadOnly}
								/>
							))}
						</>
					)}
				</FieldArray>
			</Form>
		</motion.div>
	);
};

const IrrigationSystemT2 = ({
	irrigationSys,
	irrigationSystemSchema,
	index,
	setInitValues,
	onDefaultsError,
	disabled
}: {
	irrigationSys: IrrigationSystemData;
	irrigationSystemSchema: Submodule;
	index: number;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	onDefaultsError: (message: string) => void,
	disabled?: boolean
}) => {
	const { data: defaults, isError, error } = useGetIrrigationSystemDefaultsQuery(
		irrigationSys?.id ?? 0,
		{ skip: !irrigationSys.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				irrigations: prev.irrigations.map((irr) =>
					irr.id === irrigationSys.id ? { ...irr, ...defaults } : irr,
				),
			}));
		if (isError && defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);
	}, [defaults, setInitValues, irrigationSys, defaultsErrorMsg, isError, onDefaultsError]);

	return (
		<TranslatedFormSubmodule
			key={irrigationSys.id}
			submoduleName={irrigationSystemSchema.name}
			itemId={irrigationSys.id as number}
			itemIndex={index + 1}
		>
			<TierTwoBlock>
				{irrigationSystemSchema?.inputGroups.map((input) => {
					const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index });
					return (
						<GenericInputGroup
							key={`${input.label}-${irrigationSys.id}`}
							inputGroup={{ ...curatedInput, index, disabled }}
						/>
					)
				})}
			</TierTwoBlock>
		</TranslatedFormSubmodule>
	);
};

const IrrigationPhaseT2 = ({
	operationPhase,
	operationPhaseSchema,
	index,
	setInitValues,
	onDefaultsError,
	disabled
}: {
	operationPhase: IrrigationSystemData;
	operationPhaseSchema: Submodule;
	index: number;
	setInitValues: React.Dispatch<React.SetStateAction<ModuleFull>>;
	onDefaultsError: (message: string) => void
	disabled?: boolean
}) => {
	const { data: defaults, isError, error } = useGetIrrigationPhaseDefaultsQuery(
		operationPhase?.id ?? 0,
		{ skip: !operationPhase.id },
	);
	const { errorMsg: defaultsErrorMsg } = useErrorMessage({ isError, error });


	useEffect(() => {
		if (defaults)
			setInitValues((prev) => ({
				...prev,
				irrigationPhases: prev.irrigationPhases.map((op) =>
					op.id === operationPhase.id ? { ...op, ...defaults } : op,
				),
			}));
		if (isError && defaultsErrorMsg) onDefaultsError(defaultsErrorMsg);
	}, [defaults, setInitValues, operationPhase, onDefaultsError, isError, defaultsErrorMsg]);

	return (
		<TranslatedFormSubmodule
			key={operationPhase.id}
			submoduleName={operationPhaseSchema.name}
			itemId={operationPhase.id as number}
			itemIndex={index + 1}
		>
			<TierTwoBlock>
				{operationPhaseSchema?.inputGroups.map((input) => {
					const curatedInput = handleDisableSww({ input, isReadOnly: disabled ?? false, index });
					return (
						<GenericInputGroup
							key={`${input.label}-${operationPhase.id}`}
							inputGroup={{ ...curatedInput, index, disabled }}
						/>
					)
				})}
			</TierTwoBlock>
		</TranslatedFormSubmodule>
	);
};

export default IrrigationTierTwo;
