import FormRow from "../formRow/FormRow";
import CustomInput from "../CustomInput";

import { useEffect, useState, FC } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { ClassicInputGroup } from "../../../types/modulesInterfaces";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const ClassicInput: FC<ClassicInputGroup> = ({
	label,
	inputName,
	index,
	inputType,
	unit,
	comment,
	help,
	textend,
	disabled,
	autosubmit,
}) => {
	const [name, setName] = useState<string>("");
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const translatedLabel = useCustomTranslation(label);
	const translatedUnit = useCustomTranslation(unit);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);
	const { errors, touched } = useFormikContext();

	const additionalProps =
		inputType === "password"
			? {
					icon: showPassword ? BsEyeSlash : BsEye,
					handleIconClick: () => setShowPassword((current) => !current),
				}
			: {};

	return (
		<FormRow
			rowComment={comment}
			rowHelp={help}
			isFetching={isFetchingData}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<Field
				name={name}
				id={name}
				label={`${translatedLabel} ${translatedUnit ?? ""}`}
				component={CustomInput}
				type={showPassword ? "text" : inputType}
				comment={comment}
				help={help}
				textend={textend}
				disabled={disabled}
				autosubmit={autosubmit}
				{...additionalProps}
			/>
		</FormRow>
	);
};

export default ClassicInput;
