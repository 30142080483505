import Sidebar from "../../components/sidebar/Sidebar";
import BuilderTopbar from "../../components/topbar/BuilderTopbar";
import BuilderMain from "../../components/main/BuilderMain";
import BuilderActions from "../../components/builderActions/BuilderActions";
import BuilderActionsTabs from "../../components/builderActions/BuilderActionsTabs";

import { motion } from "framer-motion";
import { useParams, Outlet, useNavigate, useLocation } from "react-router-dom";
import type { NavigateFunction } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCurrentBuilder } from "../../app/features/builder/builderSlice";
import {
	selectCurrentProject,
	setProject,
	clearProject,
} from "../../app/features/project/projectSlice";
import { useGetProjectByIdQuery } from "../../app/features/project/projectApiSlice";
import { Actions } from "../../types/interfaces";
import { useEffect } from "react";

const ProjectContainer = () => {
	const navigate: NavigateFunction = useNavigate();
	const location = useLocation();

	const params = useParams<string>();
	const dispatch = useAppDispatch();
	const { projectId } = params;
	const { project } = useAppSelector(selectCurrentProject);
	const {
		activeAction: activeTabId,
		tabsOpen,
		projectList,
	} = useAppSelector(selectCurrentBuilder);

	const { data: projectData } = useGetProjectByIdQuery(projectId || "");

	const actionsGridClass = !tabsOpen
		? "actions-close"
		: [
					Actions.Comments,
					Actions.Help,
					Actions.Changes,
					Actions.Definitions,
				].includes(activeTabId)
			? "actions-small"
			: "";

	useEffect(() => {
		const id = projectId ? Number(projectId) : undefined;
		if (id && project && id !== project?.id && projectData) {
			dispatch(clearProject());
			dispatch(setProject(projectData));
		}
	}, [projectId, project, projectData, dispatch]);

	useEffect(() => {
		if (location) {
			/* check if you are in the correct space */
			const elements = location.pathname.split("/");
			if (elements[1] === "project" && (!elements[3] || elements[3] === "")) {
				elements[2] === "new"
					? navigate("/project/new/description", { replace: true })
					: navigate(`/project/${projectId}/activities`, { replace: true });
			}
		}
	}, [location, navigate, projectId]);

	return (
		<motion.div className={`builder-container ${actionsGridClass}`}>
			<Sidebar />
			<BuilderTopbar
				projectLabel={project ? project.name : "New Project"}
				stepsList={projectList}
			/>
			<BuilderMain className="bt-1-common-dark">
				<Outlet />
			</BuilderMain>
			{/* TODO, check if you are in results -> we will just have the map without tabs */}
			<BuilderActionsTabs />
			<BuilderActions />
		</motion.div>
	);
};

export default ProjectContainer;
