import type { LandUseChangeResponse } from "../pages/activityBuilder/modules/landUseChanges/landUseChangeTypes";
import type { Activity, ModuleStatus } from "../types/interfaces";
import { TranslationKey } from "../types/modulesInterfaces";

export enum FEModules {
	PerennialCropland = 1,
	AnnualCropland = 2,
	LivestockManagement = 3,
	Energy = 4,
	Irrigation = 5,
	Inputs = 6,
	FloodedRice = 7,
	ForestManagement = 8,
	LandUseChange = 9,
	LargeFisheries = 10,
	SmallFisheries = 11,
	Aquaculture = 12,
	OrganicSoil = 13,
	CoastalWetlands = 14,
	Waterbodies = 15,
	SetAside = 16,
	OtherLand = 17,
	Settlements = 18,
	Grassland = 19,
	ActivityComplete = 20,
}

export const MODULES_WITH_LAND_USE = [
	FEModules.PerennialCropland,
	FEModules.AnnualCropland,
	FEModules.FloodedRice,
	FEModules.ForestManagement,
	FEModules.Grassland,
	FEModules.SetAside,
	FEModules.OtherLand,
	FEModules.Settlements,
	// FEModules.CoastalWetlands, 
];

export const isInFEModules = (num: number) =>
	Object.values(FEModules).includes(num);

export enum BEModules {
	PerennialCropland = 5,
	AnnualCropland = 4,
	LivestockManagement = 8,
	Energy = 34,
	Irrigation = 35,
	IrrigationSystem = 36,
	OperationPhaseOfIrrigation = 37,
	Inputs = 44,
	FloodedRice = 6,
	ForestManagement = 24,
	LandUseChange = 40,
	LargeFisheries = 21,
	SmallFisheries = 20,
	Aquaculture = 22,
	OrganicSoil = 38,
	CoastalWetlands = 39,
	Waterbodies = 19,
	SetAside = 33,
	OtherLand = 30,
	Settlements = 23,
	Road = 31,
	Building = 32,
	OtherInfrastructure = 41,
	Grassland = 7,
}

export const isInBEModules = (num: number) =>
	Object.values(BEModules).includes(num);

export type FE2BEModuleObj = {
	[key in FEModules]: {
		name: TranslationKey;
		beModuleId?: BEModules;
		path: string
	};
};

export const FEModulesObj: FE2BEModuleObj = {
	[FEModules.LandUseChange]: {
		name: "project.land_use_change",
		beModuleId: BEModules.LandUseChange,
		path: "land-use-changes",
	},
	[FEModules.AnnualCropland]: {
		name: "project.annual_cropland",
		beModuleId: BEModules.AnnualCropland,
		path: "annual-croplands",
	},
	[FEModules.PerennialCropland]: {
		name: "project.perennial_cropland",
		beModuleId: BEModules.PerennialCropland,
		path: "perennial-croplands",
	},
	[FEModules.FloodedRice]: {
		name: "project.flooded_rice",
		beModuleId: BEModules.FloodedRice,
		path: "flooded-rices",
	},
	[FEModules.SetAside]: {
		name: "project.set_aside",
		beModuleId: BEModules.SetAside,
		path: "set-aside",
	},
	[FEModules.Grassland]: {
		name: "project.grassland",
		beModuleId: BEModules.Grassland,
		path: "grasslands",
	},
	[FEModules.ForestManagement]: {
		name: "project.forest_management",
		beModuleId: BEModules.ForestManagement,
		path: "forest-management",
	},
	[FEModules.Settlements]: {
		name: "project.settlement",
		beModuleId: BEModules.Settlements,
		path: "settlements",
	},
	[FEModules.OtherLand]: {
		name: "project.other_land",
		beModuleId: BEModules.OtherLand,
		path: "other-land",
	},
	[FEModules.OrganicSoil]: {
		name: "project.organic_soil",
		beModuleId: BEModules.OrganicSoil,
		path: "organic-soils",
	},
	[FEModules.CoastalWetlands]: {
		name: "project.coastal_wetlands",
		beModuleId: BEModules.CoastalWetlands,
		path: "coastal-wetlands",
	},
	[FEModules.Waterbodies]: {
		name: "project.waterbodies",
		beModuleId: BEModules.Waterbodies,
		path: "waterbodies",
	},
	[FEModules.LivestockManagement]: {
		name: "project.livestock_management",
		beModuleId: BEModules.LivestockManagement,
		path: "livestocks",
	},
	[FEModules.SmallFisheries]: {
		name: "project.small_fisheries",
		beModuleId: BEModules.SmallFisheries,
		path: "small-fisheries",
	},
	[FEModules.LargeFisheries]: {
		name: "project.large_fisheries",
		beModuleId: BEModules.LargeFisheries,
		path: "large-fisheries",
	},
	[FEModules.Aquaculture]: {
		name: "project.aquaculture",
		beModuleId: BEModules.Aquaculture,
		path: "aquacultures",
	},
	[FEModules.Inputs]: {
		name: "project.inputs",
		beModuleId: BEModules.Inputs,
		path: "inputs",
	},
	[FEModules.Energy]: {
		name: "project.energy",
		beModuleId: BEModules.Energy,
		path: "energies",
	},
	[FEModules.Irrigation]: {
		name: "project.irrigation",
		beModuleId: BEModules.Irrigation,
		path: "irrigations",
	},
	[FEModules.ActivityComplete]: {
		name: "project.activity_complete",
		path: "complete",
	},
  };


export interface FeActivityModule {
	id: FEModules;
	name: string;
	path: string;
	uniqueId?: number; //this id refers to the unique id that the specific module has
}

interface Mod {
	beModuleId: BEModules,
	uniqueId: number,
	status: ModuleStatus,
}

export const generateActivityModuleList = (
	mods: Mod[],
	lucInfo?: {
		start: BEModules | null;
		without: BEModules | null;
		with: BEModules | null;
	},
) => {
	const ret = new Set<FeActivityModule>();
	//temporary fix, when luc is present data is reordered and we need to keep the ids array mapped to the correct type of module
	const originalBEActivityModules = [...mods.map((el) => el.beModuleId)];
	const ids = mods.map((el) => el.uniqueId);
	const mapBeModuletoModuleId = originalBEActivityModules.reduce(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(acc: any, mod, i) => {
			acc[mod] = ids[i];
			return acc;
		},
		{},
	);

	/* IF LUC IS PRESENT ORDER START, LUC, WITHOUT?, WITH */
	if (lucInfo) {
		if (lucInfo.with && originalBEActivityModules.includes(lucInfo.with)) {
			originalBEActivityModules.sort((x, y) =>
				x === lucInfo.with ? -1 : y === lucInfo.with ? 1 : 0,
			);
		}
		if (
			lucInfo.without &&
			originalBEActivityModules.includes(lucInfo.without) &&
			lucInfo.without !== lucInfo.start
		) {
			originalBEActivityModules.sort((x, y) =>
				x === lucInfo.without ? -1 : y === lucInfo.without ? 1 : 0,
			);
		}
		originalBEActivityModules.sort((x, y) =>
			x === BEModules.LandUseChange
				? -1
				: y === BEModules.LandUseChange
					? 1
					: 0,
		);
		if (lucInfo.start && originalBEActivityModules.includes(lucInfo.start)) {
			originalBEActivityModules.sort((x, y) =>
				x === lucInfo.start ? -1 : y === lucInfo.start ? 1 : 0,
			);
		}
	}

	const feMods = originalBEActivityModules.map((beMod, i) => ({
		mod: BEModulesObj[beMod],
		id: lucInfo ? mapBeModuletoModuleId[beMod] : ids[i],
		status: mods[i].status,
	}));

	for (const mod of feMods) {
		ret.add({
			id: mod.mod,
			name: FEModulesObj[mod.mod].name,
			path: FEModulesObj[mod.mod].path ?? "",
			uniqueId: mod.id,
		});
	}
	return [...Array.from(ret)];
};

//in realtà questo sarà il nuovo ModuleStep
export type BE2FEModulesObj = {
	[key in BEModules]: FEModules;
};

export const BEModulesObj: BE2FEModulesObj = {
	[BEModules.AnnualCropland]: FEModules.AnnualCropland,
	[BEModules.SmallFisheries]: FEModules.SmallFisheries,
	[BEModules.LargeFisheries]: FEModules.LargeFisheries,
	[BEModules.PerennialCropland]: FEModules.PerennialCropland,
	[BEModules.LivestockManagement]: FEModules.LivestockManagement,
	[BEModules.Energy]: FEModules.Energy,
	[BEModules.Irrigation]: FEModules.Irrigation,
	[BEModules.IrrigationSystem]: FEModules.Irrigation,
	[BEModules.OperationPhaseOfIrrigation]: FEModules.Irrigation,
	[BEModules.FloodedRice]: FEModules.FloodedRice,
	[BEModules.ForestManagement]: FEModules.ForestManagement,
	[BEModules.LandUseChange]: FEModules.LandUseChange,
	[BEModules.Aquaculture]: FEModules.Aquaculture,
	[BEModules.OrganicSoil]: FEModules.OrganicSoil,
	[BEModules.CoastalWetlands]: FEModules.CoastalWetlands,
	[BEModules.Waterbodies]: FEModules.Waterbodies,
	[BEModules.SetAside]: FEModules.SetAside,
	[BEModules.OtherLand]: FEModules.OtherLand,
	[BEModules.Settlements]: FEModules.Settlements,
	[BEModules.Road]: FEModules.Settlements,
	[BEModules.Building]: FEModules.Settlements,
	[BEModules.OtherInfrastructure]: FEModules.Settlements,
	[BEModules.Grassland]: FEModules.Grassland,
	[BEModules.Inputs]: FEModules.Inputs,
};

export const activityCompleteInfo: FeActivityModule = {
	id: FEModules.ActivityComplete,
	name: FEModulesObj[FEModules.ActivityComplete].name,
	path: FEModulesObj[FEModules.ActivityComplete].path ?? "",
};

type FEModuleDescription = {
	[key in FEModules]: TranslationKey | "";
};
export const moduleDescriptions: FEModuleDescription = {
	[FEModules.PerennialCropland]: "activity.perennialcropland_description",
	[FEModules.AnnualCropland]: "activity.annualcropland_description",
	[FEModules.LivestockManagement]: "activity.livestockmanagement_description",
	[FEModules.Energy]: "activity.energy_description",
	[FEModules.Irrigation]: "activity.irrigation_description",
	[FEModules.FloodedRice]: "activity.floodedrice_description",
	[FEModules.ForestManagement]: "activity.forestmanagement_description",
	[FEModules.LandUseChange]: "activity.landusechange_description",
	[FEModules.LargeFisheries]: "activity.largefisheries_description",
	[FEModules.SmallFisheries]: "activity.smallfisheries_description",
	[FEModules.Aquaculture]: "activity.aquaculture_description",
	[FEModules.OrganicSoil]: "activity.organicsoil_description",
	[FEModules.CoastalWetlands]: "activity.coastalwetlands_description",
	[FEModules.Waterbodies]: "activity.waterbodies_description",
	[FEModules.SetAside]: "activity.setaside_description",
	[FEModules.OtherLand]: "activity.otherland_description",
	[FEModules.Settlements]: "activity.settlements_description",
	[FEModules.Grassland]: "activity.grassland_description",
	[FEModules.Inputs]: "activity.inputs_description",
	[FEModules.ActivityComplete]: ""
  };

export const getFEModuleListFromActivity = (activity: Activity) => {
	const luc = activity.modules?.find(
		(mod) => mod.module_type?.id === BEModules.LandUseChange,
	) as LandUseChangeResponse | undefined;
	// const beModuleIds = activity.module_types.map((mod) => mod.id);
	const beMods: Mod[] = activity.modules?.map((mod) => {
		return {
			uniqueId: mod.id,
			beModuleId: mod.module_type.id,
			status: mod.status,
		};
	});

	const feModuleList = generateActivityModuleList(
		beMods,
		luc
			? {
				start: luc.module_type_start,
				with: luc.module_type_w,
				without: luc.module_type_wo,
			}
			: undefined
	);

	return feModuleList;
}

export function getModuleFromUrl (url: string) {
    const sections = url.split('/');
    const section = sections[sections.length - 1];
    return getModulefromModulePathName(section);
}
export function isLandUseModule (module: BEModules | undefined) {
	if (!module) return false;
	return MODULES_WITH_LAND_USE.includes(BEModulesObj[module]);
}
export function getModulefromModulePathName (modulePathName: string) {
	return Object.values(FEModulesObj).find((module) => module.path === modulePathName)
}

export function getModuleNameFromBEId (beModuleId: number) {
	const frontId = BEModulesObj[beModuleId as keyof typeof BEModulesObj]
	return  FEModulesObj[frontId].name
}