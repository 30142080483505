import FormRow from "../formRow/FormRow";
import CustomSelectInput from "../CustomSelectInput";

import { useEffect, useState, FC, useCallback } from "react";
import { Field, getIn, useFormikContext } from "formik";
import { DropdownInputGroup, TranslationKey } from "../../../types/modulesInterfaces";
import { useAppSelector, useCustomTranslation } from "../../../app/hooks";
import { selectIsFetchingData } from "../../../app/features/builder/builderSlice";
import { translate } from "../../../utils/helperFunctions";
import i18n from "../../../i18n";

const Dropdown: FC<DropdownInputGroup> = ({
	inputName,
	dropdownOptions,
	disabled,
	label,
	index,
	comment,
	help,
	autosubmit,
	translateOptions
}) => {
	const [name, setName] = useState<string>("");
	const isFetchingData: boolean = useAppSelector(selectIsFetchingData);
	const { errors, touched } = useFormikContext();
	const translatedLabel = useCustomTranslation(label);

	useEffect(() => {
		setName(
			typeof inputName === "function" ? inputName(index ?? 0) : inputName,
		);

		return () => {
			setName("");
		};
	}, [inputName, index]);

	const getTranslatedLandUse = useCallback(() => {
		if (typeof dropdownOptions === "function" || !translateOptions) {
			return
		}
		const translatedOptions = dropdownOptions.map((option) => {
			return {
				...option,
				name: translate(option.name as TranslationKey),
			};
		})

		return translatedOptions
	}, [dropdownOptions,translateOptions]);
	return (
		<FormRow
			rowComment={comment}
			rowHelp={help}
			isFetching={isFetchingData}
			error={
				getIn(errors, name) && getIn(touched, name)
					? getIn(errors, name)
					: undefined
			}
		>
			<Field
				label={translatedLabel}
				name={name}
				id={name}
				component={CustomSelectInput}
				options={translateOptions ? getTranslatedLandUse() : dropdownOptions}
				disabled={disabled}
				autosubmit={autosubmit}
			/>
		</FormRow>
	);
};

export default Dropdown;
