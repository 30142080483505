import { useAppSelector } from "../../app/hooks";
import {
	selectCurrentActions,
	selectCurrentBuilder,
} from "../../app/features/builder/builderSlice";
import { Actions } from "../../types/interfaces";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { ActionsComponent } from "../../utils/useTabsHook";

const BuilderActions = () => {
	const actions = useAppSelector(selectCurrentActions);

	return (
		<div className="builder-actions">
			{actions?.map((tab) => (
				<Action key={tab} action={tab} />
			))}
		</div>
	);
};

const Action = ({ action }: { action: Actions }) => {
	const { activeAction, tabsOpen } = useAppSelector(selectCurrentBuilder);
	const showAction = useMemo<boolean>(
		() => action === activeAction && tabsOpen,
		[action, activeAction, tabsOpen],
	);
	const component = useMemo<ReactElement | null>(() => {
		return ActionsComponent[action] ?? null;
	}, [action]);

	return showAction && component ? component : null;
};

export default BuilderActions;
