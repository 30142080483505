export const rightModalVariants = {
	hidden: {
		x: "500px",
		opacity: 0,
	},
	visible: {
		x: "0",
		opacity: 1,
		transition: {
			duration: 0.1,
			type: "spring",
			damping: 25,
			stiffness: 500,
		},
	},
	exit: {
		x: "500px",
		opacity: 0,
	},
};
