import type { Activity, ModuleStatus, ModuleType } from "../../../../types/interfaces";
import { Note } from "../../../../types/modulesInterfaces";
import type { LandUseChangeResponse } from "../landUseChanges/landUseChangeTypes";

export interface SettlementsResponse {
    id: number,
    module_type: ModuleType,
    activity: Activity,
    land_use_change?: LandUseChangeResponse | null,
    status: ModuleStatus,

    note: Note | null,
    start_year: number | null,
    area: number | null,
    ha_start: number | null,
    ha_w: number | null,
    ha_wo: number | null,
    settlement_type_start: number | null,
    settlement_type_w: number | null,
    settlement_type_wo: number | null,
    land_use_type_start: number | null,
    land_use_type_w: number | null,
    land_use_type_wo: number | null,
    land_use_type_thread: number | null,
    ha_thread: number | null,

    soc_t2_start: number | null,
    soc_t2_w: number | null,
    soc_t2_wo: number | null,
    fi_t2_start: number | null,
    fi_t2_w: number | null,
    fi_t2_wo: number | null,
    fmg_t2_start: number | null,
    fmg_t2_w: number | null,
    fmg_t2_wo: number | null,
    soil_carbon_t2_start: number | null,
    soil_carbon_t2_w: number | null,
    soil_carbon_t2_wo: number | null,
    flu_t2_start: number | null,
    flu_t2_w: number | null,
    flu_t2_wo: number | null,
    biomass_t2_start: number | null,
    biomass_t2_w: number | null,
    biomass_t2_wo: number | null,
}

export interface SettlementsPayload {
    id: number | null,
    status: ModuleStatus | null;
    land_use_change?: LandUseChangeResponse | null,

    note: Note | null,
    area: number | null,
    ha_start: number | null,
    ha_w: number | null,
    ha_wo: number | null,
    settlement_type_start: number | null,
    settlement_type_w: number | null,
    settlement_type_wo: number | null,
    land_use_type_start: number | null,
    land_use_type_w: number | null,
    land_use_type_wo: number | null,
    land_use_type_thread: number | null,
    ha_thread: number | null,
}

export interface SettlementsT2Payload {
    soc_t2_start: number | null,
    soc_t2_w: number | null,
    soc_t2_wo: number | null,
    fi_t2_start: number | null,
    fi_t2_w: number | null,
    fi_t2_wo: number | null,
    fmg_t2_start: number | null,
    fmg_t2_w: number | null,
    fmg_t2_wo: number | null,
    soil_carbon_t2_start: number | null,
    soil_carbon_t2_w: number | null,
    soil_carbon_t2_wo: number | null,
    flu_t2_start: number | null,
    flu_t2_w: number | null,
    flu_t2_wo: number | null,
    biomass_t2_start: number | null,
    biomass_t2_w: number | null,
    biomass_t2_wo: number | null,
}

export interface SettlementsDefaults {
    soc_t2_start_default: number | null,
    soc_t2_w_default: number | null,
    soc_t2_wo_default: number | null,
    fi_t2_start_default: number | null,
    fi_t2_w_default: number | null,
    fi_t2_wo_default: number | null,
    fmg_t2_start_default: number | null,
    fmg_t2_w_default: number | null,
    fmg_t2_wo_default: number | null,
    soil_carbon_t2_start_default: number | null,
    soil_carbon_t2_w_default: number | null,
    soil_carbon_t2_wo_default: number | null,
    flu_t2_start_default: number | null,
    flu_t2_w_default: number | null,
    flu_t2_wo_default: number | null,
    biomass_t2_start_default: number | null,
    biomass_t2_w_default: number | null,
    biomass_t2_wo_default: number | null,
}

export interface SettlementsT2 extends SettlementsT2Payload, Partial<SettlementsDefaults> { }

export interface ModuleFull {
    module: SettlementsPayload,
    tiertwo: SettlementsT2,
    roads: RoadResponse[]
    buildings: BuildingResponse[]
    others: OtherResponse[]
}

export interface SettlementsUpdatePayload {
    data: ModuleFull
    activityId: number
}

export interface Road {
    length_km_start: number | null,
    length_km_w: number | null,
    length_km_wo: number | null,
    width_m_start: number | null,
    width_m_w: number | null,
    width_m_wo: number | null,
    settlement: number | null,
    road_type: number | null,
    length_km_thread: number | null,
    width_m_thread: number | null
}

export interface RoadResponse {
    land_use_change?: LandUseChangeResponse | null,
    id: number,
    length_km_start: number | null,
    length_km_w: number | null,
    length_km_wo: number | null,
    width_m_start: number | null,
    width_m_w: number | null,
    width_m_wo: number | null,
    ef_t2_start: number | null,
    ef_t2_w: number | null,
    ef_t2_wo: number | null,
    status: number | null,
    parent: number | null,
    road_type_start: number | null,
    road_type_w: number | null,
    road_type_wo: number | null,
    length_km_thread: number | null,
    width_m_thread: number | null
}
export interface RoadT2 {
    soc_t2_start: number | null,
    soc_t2_w: number | null,
    soc_t2_wo: number | null,
    ef_t2_start: number | null,
    ef_t2_w: number | null,
    ef_t2_wo: number | null,
}

export interface Building {
    notes: string | null,
    start_year: number | null,
    area_m2_start: number | null,
    area_m2_w: number | null,
    area_m2_wo: number | null,
    settlement: number | null,
    building: number | null,
    area_m2_thread: number | null
}

export interface BuildingT2 {
    soc_t2_start: number | null,
    soc_t2_w: number | null,
    soc_t2_wo: number | null,
    biomass_t2_start: number | null,
    biomass_t2_w: number | null,
    biomass_t2_wo: number | null,
    ef_t2_start: number | null,
    ef_t2_w: number | null,
    ef_t2_wo: number | null,
}

export interface BuildingResponse {
    land_use_change?: LandUseChangeResponse | null,
    id: number,
    area_m2_start: number | null,
    area_m2_w: number | null,
    area_m2_wo: number | null,
    parent: number | null,
    building_type_start: number | null,
    building_type_w: number | null,
    building_type_wo: number | null,
    area_m2_thread: number | null,
    soc_t2_start: number | null,
    soc_t2_w: number | null,
    soc_t2_wo: number | null,
    biomass_t2_start: number | null,
    biomass_t2_w: number | null,
    biomass_t2_wo: number | null,
    ef_t2_start: number | null,
    ef_t2_w: number | null,
    ef_t2_wo: number | null,
}
export interface Other {
    area_m2_start: number | null,
    area_m2_w: number | null,
    area_m2_wo: number | null,
    parent: number | null,
    area_m2_thread: number | null
}

export interface OtherT2 {
    ef_t2_start: number | null,
    ef_t2_w: number | null,
    ef_t2_wo: number | null,
}
export interface OtherResponse {
    land_use_change?: LandUseChangeResponse | null,
    id: number,
    area_m2_start: number | null,
    area_m2_w: number | null,
    area_m2_wo: number | null,
    ef_t2_start: number | null,
    ef_t2_w: number | null,
    ef_t2_wo: number | null,
    status: number | null,
    parent: number | null,
    area_m2_thread: number | null
}

export interface OtherPayload {
    body: OtherResponse,
    activityId: number
}

export interface OtherCreatePayload {
    body: {
        parent: number,
    }
    activityId: number
}
export interface OtherDeletePayload {
    id: number
    activityId: number,
    parent: number | null,
}
export interface RoadPayload {
    body: RoadResponse,
    activityId: number
}
export interface BuildingPayload {
    body: BuildingResponse,
    activityId: number
}
export interface RoadCreatePayload {
    body: {
        parent: number,
    }
    activityId: number
}
export interface RoadDeletePayload {
    id: number
    activityId: number,
    parent: number | null,
}

export enum Settlements {
    Roads = 'module.roads',
    Buildings = 'module.buildings',
    Others = 'module.others',
}