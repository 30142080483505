interface LinkElement {
	label: string;
	link: string;
}
const DocumentationAction = () => {
	const links: LinkElement[] = [
		{
			label:
				"EX-ACT website – includes Guidelines and relevant documentation on the suite of tools",
			link: "https://www.fao.org/in-action/epic/ex-act-tool/suite-of-tools/ex-act/en/",
		},
		{
			label: "2006 IPCC Guidelines for National GHG Inventories",
			link: "https://www.ipcc-nggip.iges.or.jp/public/2006gl/",
		},
		{
			label:
				"2013 Supplement to the 2006 IPCC Guidelines for National Greenhouse Gas Inventories: Wetlands",
			link: "https://www.ipcc-nggip.iges.or.jp/public/wetlands/index.html",
		},
		{
			label:
				"2019 Refinement to the 2006 IPCC Guidelines for National Greenhouse Gas Inventories",
			link: "https://www.ipcc-nggip.iges.or.jp/public/2019rf/index.html",
		},
		{
			label: "Common Principles for Climate Mitigation Finance Tracking",
			link: "https://www.eib.org/attachments/documents/mdb_idfc_mitigation_common_principles_en.pdf",
		},
		{
			label: "IFIs - Harmonization of Standards for GHG accounting",
			link: "https://unfccc.int/topics/mitigation/resources/ifis-harmonization-of-standards-for-ghg-accounting",
		},
	];

	return (
		<section className="bg-gray w-100 h-100 d-flex f-column p-2">
			<h3>Documentation:</h3>
			<ul className="ps-2 text-quat-de op-8 ff-normal-cond">
				{links.map(({ label, link }) => (
					<li key={link} className="pt-1">
						<a href={link}>{label}</a>
					</li>
				))}
			</ul>
		</section>
	);
};

export default DocumentationAction;
