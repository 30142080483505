import React, { useMemo, useState } from 'react'
import GraphContainer from './GraphContainer'
import Skeleton from 'react-loading-skeleton'
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	ReferenceLine,
	ResponsiveContainer,
	CartesianGrid,
} from "recharts";
import { useGetProjectResultsByGasQuery } from '../../app/features/project/projectApiSlice';
import { transformCBDataByActivity } from '../../pages/project/results/resultsUtils';
import { getGasColor } from './graphsUtils';
import Pagination from '../pagination/Pagination';
import TranslatableText from '../translations/TranslatableText';

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="custom-tooltip" style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                <p className='pb-2'><strong>{label}</strong></p>
                <p className='pb-1'>RATIO: {data.RATIO.toFixed(2)}</p>
                <p className='pb-1'>BALANCE: {data.BALANCE.toFixed(2)}</p>
                <p className='pb-1'>COST: {data.cost.toFixed(2)}</p>
            </div>
        );
    }
    return null;
};

const BalanceCostRatio = ({ projectId }: { projectId: number }) => {
    const height = 350
    const width = "97%"

    const { data: customRes, isLoading } = useGetProjectResultsByGasQuery(
		projectId,
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: (result) => ({
				...result,
				data: transformCBDataByActivity(result.data),
			}),
		},
	);
	const data = useMemo(() => {
		if (!customRes) return 
		return customRes.map((emission) => {
			return {
                name: emission.activityName,
				...emission,
			}
		})
		},
		[customRes],
	);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
  
    const sortedData = data?.sort((a, b) => b.RATIO - a.RATIO);
  
    const currentData = sortedData?.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    );
    return (
    <>
    <div
        className=" w-fit"
        style={{ borderBottom: "1px solid #000", padding: "4px 0" }}
    >
        <h2 className="ff-bold fs-13 text-black d-flex align-items-center">
            <TranslatableText translationKey="main.mitigation_cost" style={{ marginRight: "5px" }}/>
            <span>[$/tCO2-eq]</span>
        </h2>
    </div>
    <GraphContainer height={height}>
        {isLoading ? (
            <Skeleton width={width ?? "97%"} height={height} />
        ) : (
            <div>
            <Pagination  
                totalItems={data?.length ?? 0} 
                itemsPerPage={itemsPerPage} size='sm' 
                onNext={() => setCurrentPage(currentPage + 1)}
                onPrevious={() => setCurrentPage(currentPage - 1)}
                onPageChange={(page) => setCurrentPage(page - 1)}
            />
            <ResponsiveContainer
                width={width ?? "97%"}
                height={height}
                className='my-3 pe-2'
                style={{ paddingBottom: height }}
            >
                <BarChart
                    data={currentData}
                    stackOffset="sign"
                    margin={{
                        top: 5,
                        right: 10,
                        left: 15,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" width={10} style={{ fontSize: "12px" }} />
                    <YAxis width={25} style={{ fontSize: "8px" }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <ReferenceLine y={0} stroke="#000" />
                    <Bar
                        dataKey="RATIO"
                        fill={getGasColor({ name: "CO2" })}
                        stackId="stack"
                    />
                </BarChart>
            </ResponsiveContainer>
            </div>
        )}
    </GraphContainer>
</>

  )
}

export default BalanceCostRatio