import { motion } from "framer-motion";
import { Formik, Form, getIn, useFormikContext, Field } from "formik";
import type { FormikHelpers } from "formik";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { rightModalVariants } from "./utils";
import { useAppDispatch, useAppSelector, useCustomTranslation } from "../../app/hooks";
import {
	closeManageUsersModal,
	closeUserModal,
	openUserModal,
} from "../../app/features/header/headerSlice";
import {
	clearProject,
	selectCurrentProject,
} from "../../app/features/project/projectSlice";
import { logout, selectCurrentUser } from "../../app/features/auth/authSlice";
import { BiPlay, BiTrash } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { FieldType } from "../../types/modulesInterfaces";
import { RiArrowRightFill } from "react-icons/ri";
import {
	useCreateProjectInvitationMutation,
	useGetGroupsQuery,
} from "../../app/features/permissions/permissionsApiSlice";
import { useGetProjectMembershipsQuery, useDeleteMembershipMutation } from "../../app/features/project/projectApiSlice";
import type {
	InputGroup,
	RadioInputGroup,
} from "../../types/modulesInterfaces";
import type { ProjectInvitation } from "../../app/features/permissions/permissionTypes";
import type { User } from "../../types/interfaces";
import Backdrop from "../modal/Backdrop";
import FormRow from "../input/formRow/FormRow";
import GenericInputGroup from "../input/inputGroup/GenericInputGroup";
import Button from "../button/Button";
import Spinner from "../spinner/Spinner";
import ErrorModal from "../modal/ErrorModal";
import usePreventCloseOnDragOutside from "./usePreventCloseOnDragOutside";
import ConfirmationModal from "../modal/ConfirmationModal";
import TranslatableText from "../translations/TranslatableText";
import useErrorMessage from "../../utils/useErrorMessage";
import KebabMenu, { Line } from "../kebabMenu/KebabMenu";
import useRemovememberFromProject from "./useRemoveMemberFromProject";
import * as Yup from "yup";
import { translate } from "../../utils/helperFunctions";

interface FormValues {
	emails: string;
	group?: number;
}

const initialValues: FormValues = {
	emails: "",
	group: 0,
};

const groupRadio: RadioInputGroup = {
	type: FieldType.RADIO,
	inputName: "group",
	label: "",
	options: [
		{
			label: "main.analyst",
			value: 1,
		},
		{
			label: "main.second_reviewer",
			value: 2,
		},
		{
			label: "main.receiver",
			value: 3,
		},
	],
};

const validationSchema = Yup.object().shape({
	emails: Yup.string()
		.required("Emails are required")
		.test("is-valid-email", translate("validations.invalid_email_format"), (value) => {
			if (!value) return false;
			const emails = value.split(",").map(email => email.trim());
			return emails.every(email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));// emails.every(email => /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(email));
		}),
	group: Yup.number()
		.required("validations.group_required")
		.min(1, "validations.select_valid_group"),
});

interface GroupOption {
	label: string;
	value: number;
}

const ModalContext = createContext<{ showDeleteModal: (memberId: number) => void } | null>(null);

const ManageUsersModal = ({ handleClose }: { handleClose: () => void }) => {
	const [inviteUser, { isLoading, isError, error, isSuccess }] =
		useCreateProjectInvitationMutation();
	const { errorMsg } = useErrorMessage({ isError, error })
	const { project } = useAppSelector(selectCurrentProject);
	const { inputRef, handleMouseDownInside } = usePreventCloseOnDragOutside({
		isError,
		handleClose,
	});


	const { data: groups } = useGetGroupsQuery();
	const [groupOptions, setGroupOptions] = useState<GroupOption[]>([]);

	useEffect(() => {
		if (groups?.length) {
			const options = groups.map((group) => ({
				label: group.name,
				value: group.id,
			}));
			setGroupOptions(options);
		}
	}, [groups]);

	const parseEmails = (input: string) => {
		const emailArray = input.split(",");
		const trimmedEmails = emailArray
			.map((email) => email.trim().toLowerCase())
			.filter((email) => email !== "");
		return trimmedEmails;
	};

	const handleSubmit = (
		values: FormValues,
		actions: FormikHelpers<FormValues>,
	) => {
		const parsedEmails = parseEmails(values.emails);
		for (const email of parsedEmails) {
			try {
				const payload: ProjectInvitation = {
					email,
					group: values.group ?? 0,
					project: project?.id ?? -1,
				};
				inviteUser(payload).unwrap();
			} catch (error) {
				console.error(error);
			}
		}
		actions.setSubmitting(false);
	};

	const { showDeleteModal, isDeleteDialogOpen, handleDelete, handleCancel } =
		useRemovememberFromProject();

	return (
		<ModalContext.Provider value={{ showDeleteModal }}>
			<Backdrop onClick={() => { }}>
				<motion.div
					onClick={(e) => e.stopPropagation()}
					variants={rightModalVariants}
					onMouseDown={handleMouseDownInside}
					className="right-side-modal align-items-start"
					initial="hidden"
					animate="visible"
					exit="exit"
				>
					<Header />
					{!project?.id ? (
						<div className="">
							<h3 className="pt-2">
								<TranslatableText translationKey="main.select_project_first" />
							</h3>
						</div>
					) : (
						<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
							<Form className="d-flex justify-content-center w-100 f-column gap-8">
								<h3 className="pt-2">
									<TranslatableText translationKey="main.invite_users" />
								</h3>
								<EmailsTextArea inputRef={inputRef} />
								<GenericInputGroup
									inputGroup={
										{ ...groupRadio, options: groupOptions ?? [] } as InputGroup
									}
								/>

								<UsersList />
								<Footer isLoading={isLoading} />
							</Form>
						</Formik>
					)}
					{/* <div className="w-100 d-flex f-column p-2">
     <h2 className="ff-medium fs-18 text-new-gray pb-2" style={{ textAlign: "left" }}>Share Project</h2>
     <p className="text-black fs-12" style={{ lineHeight: "18px" }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur hic commodi, a, distinctio corporis dicta culpa velit itaque totam doloremque praesentium, unde dolorem ipsa? Aperiam aliquam quam veritatis quibusdam optio!</p>

     <h3 className="fs-18 ff-medium py-2" style={{ color: "#5D5555" }}>Share with users:</h3>
    </div> */}
				</motion.div>
				{
					isError && <ManageUsersErrorModal isError={isError} errorMsg={errorMsg} />
				}
				{
					isSuccess && <InvitationSuccessModal isSuccess={isSuccess} />
				}
				<ConfirmationModal
					open={isDeleteDialogOpen}
					onCancel={handleCancel}
					onConfirm={handleDelete}
					message={<RemoveUserConfirmMessage />}
					headerText="user.user_delete_header"
					size="md"
				/>
			</Backdrop>
		</ModalContext.Provider>
	);
};

//TODO: normalize prop namings on modals
const ManageUsersErrorModal = ({
	isError,
	errorMsg
}: {
	isError: boolean;
	errorMsg?: string
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(isError);
	return (
		<ErrorModal
			isOpen={isOpen}
			handleClose={() => setIsOpen(false)}
			errorMessage={errorMsg ? errorMsg : "An error occured when inviting user"}
		/>
	);
};
const InvitationSuccessModal = ({
	isSuccess,
}: {
	isSuccess: boolean;
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(isSuccess);
	return (
		<ConfirmationModal
			open={isOpen}
			onCancel={() => setIsOpen(false)}
			message="Invitation sent successfully"
			onConfirm={() => setIsOpen(false)}
		/>
	);
};
const Header = () => {
	const dispatch = useAppDispatch();
	const handleClick = () => {
		dispatch(closeManageUsersModal());
		dispatch(openUserModal());
	};

	const user = useAppSelector(selectCurrentUser);

	return (
		<header className="w-100 d-flex align-items-start">
			<div className="fs-18 d-flex f-column">
				<div className="d-flex">
					<TranslatableText translationKey="main.hello" />,
				</div>
				<span>
					{user?.first_name} {user?.last_name}
				</span>
				<Button
					onClick={handleClick}
					style={{
						height: 36,
						display: "flex",
						alignItems: "center",
						border: "1px solid var(--dark-gray)",
						borderRadius: "8px",
						marginTop: "16px",
					}}
				>
					<RiArrowRightFill
						size={24}
						color="#5D5555"
						style={{ padding: "0 0 0 8px", opacity: "0.75" }}
					/>
					<TranslatableText
						className="ps-1 fs-12"
						style={{ color: "#5D5555", opacity: "0.75", padding: "0 8px" }}
						translationKey="main.manage_your_account"
					/>
				</Button>
			</div>
		</header>
	);
};

const EmailsTextArea = ({
	inputRef,
}: { inputRef?: React.MutableRefObject<HTMLTextAreaElement | null> }) => {
	const { errors, touched, setFieldValue, values } = useFormikContext();
	const name = "emails";
	const isLoading = false;
	const formikVal = values as { [emails: string]: string };

	const showError = useMemo(
		() => !!(getIn(errors, name) && getIn(touched, name)),
		[errors, touched],
	);
	// const hasValue = useMemo(() => getIn(values, name) ? true : false, [values, name]);
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => setIsFocused(true);
	const handleBlur = () => setIsFocused(false);

	if (!formikVal.emails && inputRef) inputRef.current = null;
	const translatedLabel = useCustomTranslation("main.add_user_emails");

	return (
		<FormRow
			isFetching={isLoading}
			error={showError ? getIn(errors, name) : undefined}
			style={{ minHeight: "80px" }}
		>
			<Field
				label={translatedLabel}
				placeholder={translatedLabel}
				component="textarea"
				name={name}
				id={name}
				disabled={isLoading}
				setFieldValue={setFieldValue}
				className={`form-group ${showError ? "error" : isFocused ? "focused" : ""
					} ${isLoading ? "disabled" : ""}`}
				style={{ minHeight: "80px", color: "var(--darker-gray)" }}
				onFocus={handleFocus}
				onBlur={handleBlur}
				rows={5}
				innerRef={formikVal.emails ? inputRef : undefined}
			/>
		</FormRow>
	);
};

type UserPlus = User & {
	membership: number;
};

interface GroupedUser {
	group: { id: number; name: string };
	users: UserPlus[];
}

const UsersList = () => {
	const context = useContext(ModalContext);
	const { project } = useAppSelector(selectCurrentProject);
	const { data: projectUsers, isLoading, isError, error } = useGetProjectMembershipsQuery(project?.id ?? 0, {
		skip: !project?.id,
	});

	const { errorMsg } = useErrorMessage({ isError, error })

	const [groupedUsers, setGroupedUsers] = useState<GroupedUser[]>([]);
	useEffect(() => {
		if (projectUsers?.length) {
			const grouped = projectUsers.reduce((acc: GroupedUser[], projUser) => {
				const group = acc.find((g) => g.group.id === projUser.group.id);
				if (group) group.users.push({ ...projUser.user, membership: projUser.id });
				else
					acc.push({
						group: { id: projUser.group.id, name: projUser.group.name },
						users: [{ ...projUser.user, membership: projUser.id }],
					});
				return acc;
			}, []);
			setGroupedUsers(grouped);
		}
	}, [projectUsers]);

	return (
		<div className="w-100 d-flex f-column pt-2">
			{isError ?
				<span>{errorMsg}</span>
				: isLoading ?
					<Spinner size={20} isBlack />
					: groupedUsers ?
						groupedUsers.map((grouped, key) => (
							<div className="d-flex f-column pb-2 pt-1" key={grouped.group.id}>
								<div
									className="w-100 pb-1 mb-1"
									style={{ borderBottom: "1px solid var(--gray)" }}
								>
									<h3 className="fs-18 ff-medium" style={{ color: "#3E4755" }}>
										{grouped.group.name}
									</h3>
								</div>

								{grouped.users.map((user) => (
									<div className="ps-2 w-100 d-flex justify-content-between align-items-center" style={{ height: "24px" }} key={user.email}>
										<span
											className="fs-13 ff-medium text-darker-gray"
											style={{ color: "#5D5555" }}
										>
											{user.email}
										</span>
										<KebabMenu
											lines={[{
												id: user.membership,
												label: "main.delete",
												icon: BiTrash,
												clickHandler: (id) => context?.showDeleteModal(id ?? -1),
											}]}
											activeColor="var(--error)"
											position="left"
										/>
										{/* TODO:
											- add the modal 
											- check that the member id is passed 
											- test */}

									</div>
								))}
							</div>
						))
						: null
			}
		</div>
	);
};

const Footer = ({ isLoading }: { isLoading: boolean }) => {
	const dispatch = useAppDispatch();
	const handleLogout = () => {
		dispatch(closeUserModal());
		dispatch(closeManageUsersModal());
		dispatch(clearProject());
		dispatch(logout());
	};

	const { isValid } = useFormikContext()

	return (
		<footer className="w-100 d-flex justify-content-between">
			<Button
				type="submit"
				classes="btn-save mt-1 w-fit"
				disabled={isLoading || !isValid}
				style={{ border: "1px solid #F06320" }}
			>
				<div
					className="h-100 d-flex align-items-center p-1"
					style={{ backgroundColor: "#F06320", opacity: isValid ? 1 : 0.8 }}
				>
					{isLoading ? (
						<Spinner size={20} />
					) : (
						<BiPlay size={20} color="var(--white)" />
					)}
				</div>
				<TranslatableText className="label ff-medium" translationKey="main.update_changes" />
			</Button>

			<Button
				style={{ border: "1px solid #999999" }}
				onClick={handleLogout}
				classes="btn-save mt-1 w-fit"
			>
				<div className="h-100 d-flex align-items-center p-1">
					<FiLogOut size={20} color="#5D5555" />
				</div>
				<TranslatableText className="label" style={{ color: "rgba(93, 85, 85, 0.75)" }} translationKey="main.logout" />
			</Button>
		</footer>
	);
};

const RemoveUserConfirmMessage = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
			<TranslatableText translationKey="user.user_remove_confirmation" />
		</div>
	)
}


export default ManageUsersModal;
