import { filterObjectProps, convertEmptyStringsToNull } from "../../../../pages/activityBuilder/modules/inputs/utils";
import {
	includedPropList,
	includedT2PropList,
	minorSeasonPropsList,
} from "../../../../pages/activityBuilder/modules/perennialCropland/perennialCropland";
import type {
	MinorSeason,
	MinorSeasonT2,
	ModuleFull,
	PerennialCroplandPayload,
	PerennialCroplandUpdatePayload,
	PerennialCroplandsResponse,
	PerennialCroplandT2,
	PerennialCroplandDefaults,
} from "../../../../pages/activityBuilder/modules/perennialCropland/perennialCroplandTypes";
import { apiSlice } from "../apiSlice";

export const moduleApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPerennialCroplands: builder.query<ModuleFull | null, number>({
			query: (activityId) => `perennial-croplands/?activity=${activityId}`,
			transformResponse: (response: PerennialCroplandsResponse[]) => {
				if (response && response.length > 0) {
					const moduleMinorSeasonData = filterObjectProps(
						response[0],
						minorSeasonPropsList,
					) as MinorSeason;

					const t2MinorSeasonData = filterObjectProps(response[0], [
						"minor_biomass_factor_t2_start",
						"minor_biomass_factor_t2_w",
						"minor_biomass_factor_t2_wo",
					]) as MinorSeasonT2;

					const module = filterObjectProps(
						{ ...response[0], minorSeason: moduleMinorSeasonData },
						includedPropList,
					) as PerennialCroplandPayload;

					const t2 = filterObjectProps(
						{ ...response[0], minorSeason: t2MinorSeasonData },
						includedT2PropList,
					) as PerennialCroplandT2;
					return {
						module: module,
						tiertwo: t2,
					};
				}
				return null;
			},
			providesTags: (_results, _error, activityId) => [
				{ type: "PerennialCropland", id: activityId },
			],
		}),
		updatePerennialCroplands: builder.mutation<
			PerennialCroplandsResponse,
			PerennialCroplandUpdatePayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module /* ...data.module.minorSeason */ };
				const tiertwo = { ...data.tiertwo /* ...data.tiertwo.minorSeason */ };
				const lucId = data.module.land_use_change?.id;

				const payload = {
					...module,
					...tiertwo,
				} as unknown as PerennialCroplandsResponse;
				const { id, ...rest } = payload;
				const body = { ...rest, activity: activityId, land_use_change: lucId };

				convertEmptyStringsToNull(body);
				return { url: `perennial-croplands/${id}/`, method: "PUT", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "PerennialCropland", id: results?.activity.id },
				{ type: "ModulesStatuses", id: results?.activity.id },
				{ type: "ModuleResults", id: results?.id ? `perennial-croplands-${results?.id}` : undefined },
				{ type: 'ModuleResults', id: "land-use-changes" },
				{ type: "ModuleHistory", id: results?.id ? `perennial-croplands-${results?.id}` : undefined },
				{ type: "PerennialCroplandDefaults", id: results?.id ? `perennial-croplands-${results?.id}` : undefined },
			],
		}),
		createPerennialCroplands: builder.mutation<
			PerennialCroplandsResponse,
			PerennialCroplandUpdatePayload
		>({
			query({ data, activityId }) {
				const module = { ...data.module /* ...data.module.minorSeason */ };
				const tiertwo = { ...data.tiertwo /* ...data.tiertwo.minorSeason */ };
				const lucId = data.module.land_use_change?.id;

				const payload = {
					...module,
					...tiertwo,
				} as unknown as PerennialCroplandUpdatePayload;
				const body = {
					...payload,
					activity: activityId,
					land_use_change: lucId,
				};
				return { url: "perennial-croplands/", method: "POST", body };
			},
			invalidatesTags: (results, _error, _data) => [
				{ type: "PerennialCropland", id: results?.activity.id },
			],
		}),
		getPerennialCroplandsDefaults: builder.query<PerennialCroplandDefaults, number>({
			query: (uniqueId) => `perennial-croplands/${uniqueId}/defaults/`,
			providesTags: (_results, _error, uniqueId) => [
				{ type: "PerennialCroplandDefaults", id: `perennial-croplands-${uniqueId}` },
			],
		}),
	}),
});

export const {
	useGetPerennialCroplandsQuery,
	useUpdatePerennialCroplandsMutation,
	useCreatePerennialCroplandsMutation,
	useGetPerennialCroplandsDefaultsQuery
} = moduleApiSlice;
