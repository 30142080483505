import type { ModuleFull } from "./inputsTypes";
import { Formik } from "formik";
import { useEffect, useState } from "react";

import {
	T2InitialValues,
	initNewEntryFormValues,
	initialValues,
	validationSchema,
} from "./inputs";
import {
	useAppSelector,
	useSyncCommentGroupsWithStore,
	useSyncNotesWithStore,
} from "../../../../app/hooks";
import InputsTierOne from "./InputsTierOne";
import { selectCurrentProject } from "../../../../app/features/project/projectSlice";
import {
	useGetInputEntriesQuery,
	useGetInputsQuery,
	useUpdateInputEntryMutation,
	useUpdateInputsMutation,
} from "../../../../app/features/api/modules/inputsApiSlice";
import useModuleHook from "../useModuleHook";
import InputsTierTwo from "./InputsTierTwo";
import useErrorMessage from "../../../../utils/useErrorMessage";
import { BEModules } from "../../../../utils/moduleList";

const Inputs = () => {
	const { activeActivityId } = useAppSelector(selectCurrentProject);

	const {
		data: inputsData,
		isLoading: isInputsLoading,
		error: isInputsError,
	} = useGetInputsQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});

	const [
		updateInputs,
		{
			error: updateInputsError,
			isError: isInputsErros,
			isLoading: isLoadingInputs,
		},
	] = useUpdateInputsMutation();
	const {
		data: entriesData,
		isLoading,
		isError,
		error,
	} = useGetInputEntriesQuery(activeActivityId ?? 0, {
		refetchOnMountOrArgChange: true,
	});
	const [
		updateInputEntry,
		{ error: updateError, isError: isUpdateError, isLoading: isLoadingUpdate },
	] = useUpdateInputEntryMutation();

	const [initValues, setInitValues] = useState<ModuleFull>({
		entries: [],
		new_entry: initNewEntryFormValues,
		parent: initialValues,
		tiertwo: T2InitialValues,
	});

	const { tabsOpen, errorMsg } = useModuleHook({
		skeletonsLoadingDeps: [isLoading],
		isMutateError: isUpdateError,
		mutateError: updateError,
	});
	const { errorMsg: blockingError } = useErrorMessage({ isError, error });

	useEffect(() => {
		if (inputsData) {
			setInitValues((prev) => ({
				...prev,
				parent: inputsData.parent,
				tiertwo: inputsData.tierTwo,
			}));
		}
	}, [inputsData]);

	const { notes } = useSyncNotesWithStore({
		notes: initValues?.parent?.note ?? null,
	});

	useSyncCommentGroupsWithStore<ModuleFull>({
		T1Data: {
			entries: entriesData ?? [],
			parent: initialValues,
			new_entry: initNewEntryFormValues,
		},
		moduleSchema: null,
		isT1DataLoading: isInputsLoading,
		threads: entriesData
			?.map((input) => input.value_thread)
			.filter((thread) => thread) as number[],
	});

	const handleSubmit = async (values: ModuleFull) => {
		const { entries } = values;

		//update parent
		const parent = { ...values.parent };
		const payload = {
			body: { ...values.tiertwo, ...parent },
			activityId: activeActivityId ?? 0,
		};

		try {
			const promises = [];
			if (values.parent.id) promises.push(updateInputs(payload));

			entries.map((entry) => {
				const payload = { body: entry, activityId: activeActivityId ?? 0 };

				if (entry.id) return updateInputEntry(payload);
				return Promise.resolve();
			});

			await Promise.all(promises);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initValues}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<>
				<InputsTierOne
					title="Inputs"
					isLoading={isLoading || isLoadingUpdate}
					footerError={errorMsg}
					blockingError={blockingError}
					beModuleType={BEModules.Inputs}
					isError={isUpdateError}
					setInitValues={setInitValues}
					note={notes?.content ?? null}
				/>
				{!tabsOpen ? (
					<InputsTierTwo tabsOpen={tabsOpen} setInitValues={setInitValues} />
				) : null}
			</>
		</Formik>
	);
};

export default Inputs;
